
export class TagContract {
  Title: string | null = null;
  Active: boolean = false;
  Link: string | null = null;
  TagType: TagType | null = null;
}

export enum TagType {
  SpecialTag = 1,
  NormalTag = 2,
  OneTag = 3,
  TwoTag = 4,
  Three = 5,
  Five = 6,
  PictureTag = 7,
  PriceTag = 8
}

