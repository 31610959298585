import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
declare var DeviceUUID;

@Injectable({ providedIn: 'root' })
export class UUIDFromStorage {
  /**
   *
   */
  _isBrowser = false;
  constructor(injector: Injector) {
    this._isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
    this._getOrSetFromStorage();
  }

  _getOrSetFromStorage(): string {
    let uuidFromSessionStorage;
    if (this._isBrowser) {
      uuidFromSessionStorage = sessionStorage.getItem('traceid') ?? '';
      if (!uuidFromSessionStorage) {
        const uuid = new DeviceUUID().get();
        sessionStorage.setItem('traceid', uuid);
        uuidFromSessionStorage = uuid;
      }
    } else if (!this._isBrowser) {
      return 'SERVER';
    }
    return uuidFromSessionStorage;
  }

  get(): string {
    return this._getOrSetFromStorage();
  }
}
