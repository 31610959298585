import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

// import { AvailabilityRequestContract } from '../../shared/signalGo/services/Tkt724.Sale.FlightCore.Contracts.Requests/AvailabilityRequestContract';
import { FlightavailabilityService } from '../../../../shared/signalGo/services/flightavailabilityService';
import { FlightstatisticService } from '../../../../shared/signalGo/services/flightstatisticService';
import { SegmentBaseContract } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.Contracts.Common/SegmentBaseContract';
// import { PassengerType } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.Database.DataTypes/PassengerType';
import { MinimumPriceStatisticRequestContract } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.Contracts.Requests/MinimumPriceStatisticRequestContract';
import { AvailabilityType } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.DataTypes/AvailabilityType';
import { FlightairportService } from '../../../../shared/signalGo/services/flightairportService';
// import { FlightService } from '../../../../shared/signalGo/services/flightService';
// import { PurchaseService } from '../../../../shared/signalGo/services/purchaseService';
import { AirTripType } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.DataTypes/AirTripType';
import { FlightorderService } from '../../../../shared/signalGo/services/flightorderService';
import { PaxdiscountService } from '../../../../shared/signalGo/services/paxdiscountService';
import { CheckDiscountRequestContract } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.Contracts.Requests.Products/CheckDiscountRequestContract';
import { FlightAvailabilityRequestContract } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.Contracts.Requests/FlightAvailabilityRequestContract';
import { PassengerSelectorBaseContract } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.Contracts.Common.Travels.Travellers/PassengerSelectorBaseContract';
import { CabinType } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.DataTypes/CabinType';
import { StopQuantityType } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.DataTypes/StopQuantityType';
import { PricingSourceType } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.DataTypes/PricingSourceType';
import { PassengerType } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.DataTypes/PassengerType';
import { FlightBasicInformationRequestContract } from '../../../../shared/signalGo/services/Tkt724.Sale.FlightCore.Contracts.Requests/FlightBasicInformationRequestContract';
import { FlightCalculationDiscountAmountRequestContract } from '../../../../shared/signalGo/services/DiscountGenerationServices.Tkt724.Hub.Discounts.Contracts.Request/FlightCalculationDiscountAmountRequestContract';
import { PurchaseService } from '../../../../shared/signalGo/services/purchaseService';
import { ExternalapisafarmarketflightService } from 'src/shared/signalGo/services/externalapisafarmarketflightService';
@Injectable()
export class FlightLocalServices {

  constructor(private http: HttpClient,
    private flightavailabilityService: FlightavailabilityService,
    private flightstatisticService: FlightstatisticService,
    private flightairportService: FlightairportService,
    private flightService: FlightorderService,
    private purchaseService: PurchaseService,
    private productdiscountService: PaxdiscountService,
    public safar:ExternalapisafarmarketflightService
  ) { }


  validateDiscountCode(dc) {
    return this.productdiscountService.validateDiscount(dc);
  }

  calculationDiscountAmount(data) {
    return this.productdiscountService.flightCalculationDiscountAmount(data)
  }

  purchaseFromBankGetway(data) {
    return this.purchaseService.purchaseFromBankGetway(data)
  }

  addOrder(data) {
    return this.flightService.addFlightOrder(data);
  }

  getFlightRule(id) {
    let input = new FlightBasicInformationRequestContract();
    input.flightId = id;
    return this.flightavailabilityService.getFlightBasicInformation(input);
  }

  getCityToCityAvailability(fsr: any) {
    let reqData = this.setFlightAvailabilityRequestContract(fsr);
    // let data = this.AvailabilityRequest(fsr,0);

    return this.flightavailabilityService.getFlightCityToCityAvailability(reqData);
  }

  PaxcontentService(key: string) {
    return this.http.post('http://192.168.12.112:3791/Pax/content/GetContent', { key: key });
  }

  flightstatistic(fsr: any, availabilityType: number = 0, start: number, end: number) {
    let data = new MinimumPriceStatisticRequestContract;
    data.availabilityRequest = this.AvailabilityRequest(fsr, availabilityType);
    data.minRange = start;
    data.maxRange = end;
    return this.flightstatisticService.flightPriceStatistics(data);
  }

  flightairport(code: string) {
    return this.flightairportService.getCategory(code);
  }

  GetFlightFullDetails(data) {
    return this.flightavailabilityService.getFlightFullDetails(data) ;
  }

  getFlightPriceInquiry(data) {
    return this.flightavailabilityService.getFlightPriceInquiry(data);
  }

  getAvailabalityType(number) {
    switch (number) {
      case 0:
        return AvailabilityType.None;
      case 1:
        return AvailabilityType.Separated;
      case 2:
        return AvailabilityType.Merged;
      default:
        return AvailabilityType.None;
    }
  }

  setFlightAvailabilityRequestContract(fsr): FlightAvailabilityRequestContract {

    let flightAvailabilityRequestContract = new FlightAvailabilityRequestContract();

    flightAvailabilityRequestContract.availabilityType = AvailabilityType.None;

    flightAvailabilityRequestContract.travelDetails.cabinType = CabinType.Y;
    flightAvailabilityRequestContract.travelDetails.airTripType = (fsr.flightType == 2) ? AirTripType.Return : AirTripType.OneWay;
    flightAvailabilityRequestContract.travelDetails.stopQuantityType = StopQuantityType.All;
    flightAvailabilityRequestContract.travelDetails.pricingSourceType = PricingSourceType.All;


    flightAvailabilityRequestContract.passengers.push({
      type: PassengerType.Adult,
      quantity: fsr.adultCount,
      optionalServices:[]
    })

    if (fsr.childCount > 0) {
      flightAvailabilityRequestContract.passengers.push({
        type: PassengerType.Child,
        quantity: fsr.childCount,
        optionalServices:[]
      })
    }

    if (fsr.infantCount > 0) {
      flightAvailabilityRequestContract.passengers.push({
        type: PassengerType.Infant,
        quantity: fsr.infantCount,
        optionalServices:[]
      })
    }


    if (fsr.flightType === 1) {
      // Segment 1
      flightAvailabilityRequestContract.segments.push({
        origin: fsr.origin,
        destination: fsr.destination,
        departureDateTime: fsr.departing.selectedDate,
        // uniqueIndex: null
      })
    } else if (fsr.flightType === 2) {

      // Segment 1
      flightAvailabilityRequestContract.segments.push({
        origin: fsr.origin,
        destination: fsr.destination,
        departureDateTime: fsr.departing.selectedRangeDates.startDate,
        // uniqueIndex: null
      })

      // Segment 2
      flightAvailabilityRequestContract.segments.push({
        origin: fsr.destination,
        destination: fsr.origin,
        departureDateTime: fsr.departing.selectedRangeDates.endDate,
        // uniqueIndex: null
      })
    }

    return flightAvailabilityRequestContract;

  }

  AvailabilityRequest(fsr: any, availabilityType: number = 0) {
    let datasw = new FlightAvailabilityRequestContract;
    let s = new SegmentBaseContract;
    s.origin = fsr.origin;
    s.destination = fsr.destination;
    // debugger
    if (fsr.flightType === 1){
      s.departureDateTime = fsr.departing.selectedDate;
      // s.uniqueIndex = 0;
    }
    datasw.segments.push(s);
    // datasw.segments.map(s => {
    //   s.origin = fsr.origin
    //   s.destination = fsr.destination
    //   s.departureDateTime = fsr.departing.selectedDate
    //   s.uniqueIndex = 0
    // });
    let p = new PassengerSelectorBaseContract;
    p.quantity = fsr.adultCount;
    p.type = 1;
    datasw.passengers.push(p);
    datasw.travelDetails.airTripType = 1;
    datasw.travelDetails.cabinType = 1;
    datasw.travelDetails.stopQuantityType = 3;
    datasw.travelDetails.pricingSourceType = 3;
    // datasw.flightId = null;
    datasw.availabilityType = this.getAvailabalityType(availabilityType);
    // if (fsr.flightId > 0) {
    // datasw.flightId = fsr.flightId;
    // }
    if (fsr.flightType === 2) {
      datasw.segments[0].departureDateTime = fsr.departing.selectedRangeDates.startDate;
      let s = new SegmentBaseContract;
      s.origin = fsr.destination;
      s.destination = fsr.origin;
      s.departureDateTime = fsr.departing.selectedRangeDates.endDate;
      //s.uniqueIndex = 1;
      datasw.segments.push(s);
      datasw.availabilityType = AvailabilityType.Separated;
      datasw.travelDetails.airTripType = AirTripType.Return;
    } else {
      let i = 2;
      while (i <= fsr.segments.length) {
        // debugger
        let s = new SegmentBaseContract;
        s.origin = fsr.segments[i - 1].origin;
        s.destination = fsr.segments[i - 1].destination;
        s.departureDateTime = fsr.segments[i - 1].departureDateTime;
        // s.uniqueIndex = i-1;
        datasw.segments.push(s);
        i = i + 1;
      }
    }
    if (fsr.childCount > 0) {
      let passenger = new PassengerSelectorBaseContract();
      passenger.type = PassengerType.Child;
      passenger.quantity = fsr.childCount;
      datasw.passengers.push(passenger);
    }
    if (fsr.infantCount > 0) {
      let passenger = new PassengerSelectorBaseContract();
      passenger.type = PassengerType.Infant;
      passenger.quantity = fsr.infantCount;
      datasw.passengers.push(passenger);
    }
    return datasw;
  }

}
