export class AppConsts {

    static remoteServiceBaseUrl: string='';
    static remoteServiceBaseUrlSGO: string='';
    static cdnBaseUrls = {
      localhost: 'https://cdn.tkt724.com',
      tkt724Com: 'https://cdn.tkt724.com',
      tkt724Org: 'https://cdn.tkt724.org',
      tkt724Ir: 'https://cdn.tkt724.ir',
      betaTkt724: 'https://cdn-beta.tkt724.com'
    };
    static get cdnBaseUrl(): string{return AppConsts.cdnBaseUrls[this.remoteServiceBaseUrlSGO]}
    static get rs(){
        return this.remoteServiceBaseUrl + '/';
    }
    static authenticateBaseUrl : string;
    static hotelBedCdn: string='https://photos.hotelbeds.com/giata/';

    static readonly localization = {
        defaultLocalizationSourceName: 'Sale'
    };

    static readonly grid = {
        defaultPageSize: 10
    };
}
