import {EventEmitter, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HotelavailabilityseoService } from 'src/shared/signalGo/services/hotelavailabilityseoService';
import { HotelAvailabilitySeoResponse } from 'src/shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Responses/HotelAvailabilitySeoResponse';

@Injectable()
export class HotelRouterServices implements CanActivate {

  _hotelAvailabilitySeoServices: HotelavailabilityseoService;
  platformId: any;
  _router:any;


  HotelAvailabilitySeo: HotelAvailabilitySeoResponse = new HotelAvailabilitySeoResponse();
  HotelAvailabilitySeoEmitter:EventEmitter<HotelAvailabilitySeoResponse> =new EventEmitter<HotelAvailabilitySeoResponse>();

  constructor(public injector: Injector, private router: Router, hotelAvailabilitySeoServices: HotelavailabilityseoService ) {
    this.platformId = injector.get(PLATFORM_ID);
    this._hotelAvailabilitySeoServices = hotelAvailabilitySeoServices;
    this._router =router;
  }
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let prom = new Promise<any>((resolve, reject) => {
      let id = route.params["id"];
      //console.log('Route Name : ' + name);
      this._hotelAvailabilitySeoServices.getHotelName(id).subscribe(res=>{
        let result =res.result;
        if(result==null) {
          this._router.navigate(['/404']);
          return resolve(false);
        }else {
          this._router.navigate(['/hotel/'+res.result]);
        }
        return resolve(true);
      })
    });
    return prom;
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

}
