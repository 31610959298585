// Modules
//import { LottieModule } from 'ngx-lottie';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdsAngularPersianDateTimePickerModule } from 'src/shared/mds-angular-persian-date-time-picker/src/public_api';
import { RouterModule } from '@angular/router';
// Components
import { BannerApplicationSharedComponent } from './components/banner-application-component/application.component';
import { toastLeadSharedComponent } from './components/toast-lead-component/toast-lead.component';
import { PreOrderSharedComponent } from './components/pre-order-component/pre-order-component'
import { PassengerListComponent } from './components/searchbox/passenger-list/passenger-list.component'
import { AlertSharedComponent } from './components/alert-component/alert-component.component';
import { AdvertisingSharedComponent } from './components/advertising-component/advertising.component';
import { PendingPagesSharedComponent } from './components/pending-component/pending-pages.component';
import { InstagramSharedComponent } from './components/instagram-component/cta-instagram.component';
import { SearchboxSharedComponent } from './components/searchbox/serachbox-component.component';
import { OfferDiscountSharedComponent } from './components/OfferDiscount/offerdiscount';
import { VpnAlertComponent } from './components/vpn-alert/vpn-alert.component';
import { JSONLdComponent } from './components/json-id/json-id.component';
import { SelectpassengerSharedComponent } from './components/layout/passenger/passenger.component';
import { ModaldialogComponent } from 'src/app/shared/components/modaldialog/modaldialog.component';
import { PassengerContainerComponent } from './components/searchbox/passenger-container/passenger-container.component';
import { CommentSharedComponent } from 'src/app/shared/components/comment/comment.component';
import { UnfinishedPurchaseComponent } from 'src/app/shared/components/unfinished-purchase/unfinished-purchase.component'
// Directives
import { NumOnlySharedDirective } from './directives/num-only/num-only.directive';
import { AutoActiveClass } from './directives/autoActive/autoActiveDirective.directive';
import { RunAfterInitDirective } from './directives/runAfterInit/runAfterInit.directive';
import { CharNumberOnlyDirective } from './directives/charNumOnly/char-num-only.directive';
import { RunAfterInitTsDirective } from './directives/runAfterInit/runAfterInitTs.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll/infinite-scroll.directive';
import { FarsiTextOnlyDirective } from './directives/farsi-text-validator/farsittextonly.directive';
import { EnglighTextOnlyDirective } from './directives/english-text-validator/englishTextOnly.directive';
import { FluidHeightDirective } from './directives/scroll-offset/scroll-offset.directive';
import { SelectOnFocusSharedDirective } from './directives/select-on-focus/select-on-focus.directive';
import { CloseOnEscapeSharedDirective } from './directives/close-on-escape/close-on-escape.directive';
import { ImageNotfoundDirective } from "./directives/img-notfound/img-notfound.directive";
// Pipe
import { LocalizePipe } from './pipes/localize/localize.pipe';
import { Comma3Pipe } from './pipes/comma3/comma3.pipe';
import { TomanPipe } from './pipes/toman/toman.pipe';
import { LocalizeDigitPipe } from './pipes/localize-digit/localize-digit.pipe';
import { RialPricePipe } from './pipes/rial-price/rial-price.pipe';
import { TomanPricePipe } from './pipes/toman-price/toman-price.pipe';
import { AirLinePersianNamePipe } from './pipes/airline-persian-names/airline-persian-names.pipe';
import { TimeDurationPipe } from './pipes/time-duration/time-duration.pipe';
import { JalaliPipe } from './pipes/jalali/jalali.pipe';
import { JalaliPipeP } from './pipes/jalali-p/jalali-p.pipe';
import { EnumToText } from './pipes/enum-text/enum-text.pipe';
import { MinuteSecondsPipe } from './pipes/min-sec/min-sec.pipe';
import { UTCDatePipe } from './pipes/utc-date/utc-date.pipe';
import { SafePipe } from './pipes/safe-html/safe-html.pipe';
import { PublicAppSignalService } from './../core/services/app/public-app-signal.service';
import player from 'lottie-web';
import { LottieComponent } from 'ngx-lottie';
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    NgxSliderModule,
    FormsModule,
    ReactiveFormsModule,
    MdsAngularPersianDateTimePickerModule,
    //LottieModule.forRoot({ player: playerFactory }),
    RouterModule,
    NgOptimizedImage,
    LottieComponent,
  ],
  declarations: [
    // Directive
    RunAfterInitDirective,
    RunAfterInitTsDirective,
    CharNumberOnlyDirective,
    AutoActiveClass,
    InfiniteScrollDirective,
    NumOnlySharedDirective,
    FarsiTextOnlyDirective,
    EnglighTextOnlyDirective,
    FluidHeightDirective,
    SelectOnFocusSharedDirective,
    CloseOnEscapeSharedDirective,
    ImageNotfoundDirective,
    // Component
    InstagramSharedComponent,
    SearchboxSharedComponent,
    OfferDiscountSharedComponent,
    AdvertisingSharedComponent,
    JSONLdComponent,
    VpnAlertComponent,
    BannerApplicationSharedComponent,
    toastLeadSharedComponent,
    PreOrderSharedComponent,
    PassengerListComponent,
    AlertSharedComponent,
    PendingPagesSharedComponent,
    SelectpassengerSharedComponent,
    ModaldialogComponent,
    PassengerContainerComponent,
    CommentSharedComponent,
    UnfinishedPurchaseComponent,
    // Pipe
    LocalizePipe,
    Comma3Pipe,
    TomanPipe,
    LocalizeDigitPipe,
    RialPricePipe,
    TomanPricePipe,
    TimeDurationPipe,
    JalaliPipe,
    AirLinePersianNamePipe,
    JalaliPipeP,
    EnumToText,
    MinuteSecondsPipe,
    UTCDatePipe,
    SafePipe,
  ],
  providers: [PublicAppSignalService],
  exports: [
    // Module
    CommonModule,
    SwiperModule,
    NgxSliderModule,
    FormsModule,
    ReactiveFormsModule,
    MdsAngularPersianDateTimePickerModule,
    RouterModule,
    NgOptimizedImage,
    // Directive
    RunAfterInitDirective,
    RunAfterInitTsDirective,
    CharNumberOnlyDirective,
    AutoActiveClass,
    InfiniteScrollDirective,
    NumOnlySharedDirective,
    FarsiTextOnlyDirective,
    EnglighTextOnlyDirective,
    FluidHeightDirective,
    SelectOnFocusSharedDirective,
    CloseOnEscapeSharedDirective,
    ImageNotfoundDirective,
    // Component
    SearchboxSharedComponent,
    OfferDiscountSharedComponent,
    InstagramSharedComponent,
    AdvertisingSharedComponent,
    JSONLdComponent,
    VpnAlertComponent,
    BannerApplicationSharedComponent,
    toastLeadSharedComponent,
    PreOrderSharedComponent,
    PassengerListComponent,
    AlertSharedComponent,
    PendingPagesSharedComponent,
    SelectpassengerSharedComponent,
    ModaldialogComponent,
    CommentSharedComponent,
    UnfinishedPurchaseComponent,
    // Pipe
    LocalizePipe,
    Comma3Pipe,
    TomanPipe,
    LocalizeDigitPipe,
    RialPricePipe,
    TomanPricePipe,
    TimeDurationPipe,
    JalaliPipe,
    AirLinePersianNamePipe,
    JalaliPipeP,
    EnumToText,
    MinuteSecondsPipe,
    UTCDatePipe,
    SafePipe
  ],
})
export class UtilityModule { }
