import { isPlatformBrowser } from '@angular/common';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { PaxcontentService } from '../shared/signalGo/services/paxcontentService';

@Injectable()
export class ContentService implements CanActivate{


  platformId:any;
  constructor(public injector: Injector,private paxcontentService: PaxcontentService,private router:Router) {
    this.platformId = injector.get(PLATFORM_ID);
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const contentName = route.params['fromto']?.toString()?? null;
    const contenttype = route.data['contentType']?.toString() ?? '';
    if (contentName && contenttype ) { //&& !isDynamic
      return this.loadContent(contenttype+contentName);
    }
    else
      return Promise.resolve(true);
  }

  contents=[];
  loadContent(key):Promise<boolean>{
    //console.log('loadContent start gurd'+key);
    var promcontent = this.contents.find(o=>o.key==key.toLowerCase());
    if (promcontent) {
              return promcontent.prom;
    }
    var prom= new Promise<any>((resolve,reject)=>{
      this.paxcontentService.getContents(key).subscribe(res=>{
            // console.log(res);
            if (res.success)
             {
                    let output = res.result
                    // this.setTitle([output.pageTitle]).setDescription(output.description);
                    var contentsindex = this.contents.findIndex(o=>o.key==key.toLowerCase());
                    if(contentsindex > -1)
                    {
                      this.contents[contentsindex].content = output;
                      this.contents[contentsindex].status = 'loadok';
                      // console.log('loadContent success');
                      resolve(true);
                    }
                    else
                    {
                      // this.contents.push({
                      //   content:output,
                      //   status:'loadok'
                      // });
                      this.router.navigate(['/404'], { skipLocationChange: false });
                      resolve(false);
                    }
              }
              else{
                  console.error('loadContent fail : ' + key);
                  this.router.navigate(['/404'], { skipLocationChange: false });
                  resolve(false);
                }
      },
      err=>{
        resolve(false);
        console.log('loadpromiseContent ERoR gurd '+key);
        //console.log('loadpromiseContent ERoR',err)
      }
      );
    });
    this.contents.push({key:key.toLowerCase(),content:{},status:'calling',prom:prom});
    return prom;

  }
get isBrowser() {
    return isPlatformBrowser(this.platformId);
}
  getContents(key): Promise<any> {
    // console.log('getContent start-->',key);
      var content = this.contents.find(o=>o.key==key.toLowerCase() && o.status=='loadok');
      if (content!=undefined) {
        // console.log('getContent success');
        return Promise.resolve(content);
      }
    return new Promise<any>((resolve,reject)=>{
      // console.log('getContent restart');
        this.loadContent(key).then(o=>{
          if (o) {
            var content = this.contents.find(o=>o.key==key.toLowerCase() && o.status=='loadok');
            if (content!=undefined) {
              // console.log('getContent resuccess');
               resolve(content);
            }
            reject(false)
          }
        }).catch(e=>{
          //console.error('getContent unsucces');
          console.log('getContent ERoR gurd '+key);
          reject(null);
        })
    });
}
}
