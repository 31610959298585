import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppInitCallGuard } from './../../auth-routing';
import { ContentService } from './../../core/services/seo.service';
import { PolicyContentComponent } from './policy/policy-content/policy-content.component';
import { TooManyRequestsComponent } from "./too-many-requests/too-many-requests.component";

const routes: Routes = [
    // Target Pages Start <= This Shall Be Seprated Routing
    {
        path: '',
        loadChildren: () => import('./target-pages/targetpages.module').then(m => m.TargetPagesModule),
        canActivate: [
            ContentService,
            AppInitCallGuard
        ],
        data: { pagename: 'home-flight', activetab: 'flight' }, pathMatch: 'full'
    },
    // Target Pages End


    // Policy
    {
        path: 'policyss',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
        canActivate: [AppInitCallGuard],
    },

    // Payment Response
    {
        path: 'payment-response/:status/:product/:clientType',
        loadChildren: () => import('./payment-response/payment-response.module').then(m => m.PaymentResponseModule),
        canActivate: [AppInitCallGuard],
        data: {
            preload: true
        }
    },

    // Arbaeen
    // {
    //   path: 'flight/arbaeen',
    //   loadChildren: () => import('./landign-arbaeen/landign-arbaeen.module').then(m => m.LandignArbaeenModule),
    //   canActivate: [AppInitCallGuard],
    //   data: { campaignName: 'Arbaeen 1402' }
    // },
    {
        path: 'openmobileapp/:originId/:destinationId/:departureDate/:flightId',
        loadChildren: () => import('./open-mobile-app/open-mobile-app.module').then(m => m.OpenMobileAppModule),
        data: { contentType: 'openmobileapp/' }
    },
    {
        path: 'openmobileapppage/:page',
        loadChildren: () => import('./open-mobile-app/open-mobile-app.module').then(m => m.OpenMobileAppModule),
        data: { contentType: 'openmobileapppage/' }
    },

   { path: 'too-many-requests', component: TooManyRequestsComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppSharedRoutingModule { }
