import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/app-component-base';
//import { AppComponentBase } from '../../../app/app-component-base';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent extends AppComponentBase implements OnInit {

  
  constructor(injector: Injector) {
    super(injector);
  }
  ngOnInit() {

  }
}
