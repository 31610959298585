import { isPlatformBrowser } from '@angular/common';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { CacheService } from './core/services/app/clearCache/clear-cache.service';

function notifyUser(message: string): void {}

// #docregion sw-unrecoverable-state
@Injectable({ providedIn: 'root' })
export class HandleUnrecoverableStateService  extends CacheService{
  constructor(injector: Injector, updates: SwUpdate) {
    super();
    if (
      environment.production &&
      isPlatformBrowser(injector.get(PLATFORM_ID))
    ) {
      updates.unrecoverable.subscribe((event) => {
        // notifyUser(
        //   'An error occurred that we cannot recover from:\n' +
        //   event.reason +
        //   '\n\nPlease reload the page.'
        // );
        if (
          confirm(
            'خطایی رخ داده است لطفا برای حل مشکل صفحه را دوباره بارگذاری کنید'
          )
        ) {
          console.log('HandleUnrecoverableStateService' + event.reason );

          this.clearCache();
          window.location.reload();
        }
      });
    }
  }
}
// #enddocregion sw-unrecoverable-state
