
import { HTTP_INTERCEPTORS, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable, Injector, NgModule, PLATFORM_ID } from '@angular/core';

import { of, Observable, throwError, Subject, timer } from 'rxjs';
// import { FlightService } from './services/FlightService';
// import { HUserprofileService } from './services/HUserprofileService';


import { PurchasepaymentService } from './services/purchasepaymentService';
import { catchError, concatMap, delay, finalize, mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppSessionService } from 'src/app/core/services/app/app-session.service';
import { AuthenticationService } from './services/authenticationService';
import { HUserprofileService } from './services/huserprofileService';
import { ServicedetailService } from './services/servicedetailService';
//import { marketingAutomatiomHandler } from '../../assets2/js/webengage';
import { isPlatformBrowser } from '@angular/common';
//declare var MarketingAutomatiomHandler: any;
// import { PaymentService } from './services/paymentService';
// import { PassengerserviceService } from './services/PassengerserviceService';
// import { TransactionService } from './services/TransactionService';
// import { ServicedetailService } from './services/ServicedetailService';
@Injectable()
export class CustpmAbpHttpInterceptor implements HttpInterceptor {//AbpHttpInterceptor{//
  platformId: Object;
  constructor(private injector: Injector, private router: Router) {
    this.appSessionService = injector.get(AppSessionService);
    this.platformId = injector.get(PLATFORM_ID);
  }
  // appinitreq='Authentication/AppInit';
  // getcontentreq =  'Pax/content/GetContent';
  // gethotelcontentreq =  'Hotel/availabilityseo/GetHotelAvailabilitySeo';
  // gethotelcontentreq1 =  'Hotel/availabilityseo/GetLandingAvailability';
  AppInitIgnoreUrls = [
    'Authentication/AppInit',
    'Pax/content/GetContent',
    'Pax/content/GetContents',
    'Hotel/availabilityseo/GetHotelAvailabilitySeo',
    'Hotel/availabilityseo/GetLandingAvailability'
  ];
  SkipWebengageUrls = [
    'GetDataTypes',
    'FilterCountries',
    'GetCategory'
  ];

  isauth = false;
  authService: AuthenticationService;
  appSessionService: AppSessionService;
  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  refreshTokenAppInit(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return this.appSessionService.AppInitObs().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((x, y) => {
          this.refreshTokenInProgress = false;
          this.logout();
          return y;
        })
      );
    }
  }
  logout() {
    // abp.utils.deleteCookie('_session');
    // this.authService.logout();
    // this.router.navigate(["login"]);
  }

  retryDelay = 2000;
  retryMaxAttempts = 2;
  retryAfterDelay(): any {
    return retryWhen(errors => {
      return errors.pipe(
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          if (count === this.retryMaxAttempts) {
            return throwError(err);
          }
          return of(err).pipe(
            tap(error => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
    retryWhen(error =>
      error.pipe(
        concatMap((error, count) => {
          if (count <= this.retryMaxAttempts && error.status == 503) {
            return of(error);
          }
          return throwError(error);
        }),
        delay(this.retryDelay)
      )
    )
  }
  // tapproccessResponse(authReq,next){
  //             tap((event: any) => {
  //             if (event.body?.FailedReason==FailedReasonType.SessionAccessDenied || event.body?.FailedReason==FailedReasonType.AccessDenied) {
  //                 return this.refreshTokenAppInit().pipe(tap(x=>
  //                     {
  //                         return next.handle(authReq);
  //                     }
  //                     ));
  //             }
  //             return event;
  //             //  console.log(event);
  //             // let ret= event.body;
  //             // console.log(ret);
  //             // if(ret.success)
  //             // return ret;
  //         },
  //         catchError(error => {
  //             if (error.status === 401) {
  //               return this.refreshTokenAppInit().pipe(
  //                 switchMap(() => next.handle(authReq))
  //               )
  //             }
  //             return throwError(error);
  //           }),
  //         //  this.retryAfterDelay(),
  //           //  error => { if (error instanceof HttpErrorResponse) { console.log(error.message, error.name); } }
  //        )
  //     ]
  // }
  handleResponseError(error, request?, next?) {
    // Business error
    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (error.status === 401) {
      return this.appSessionService.AppInitObs(true).pipe(
        switchMap(() => {
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          } else {
            this.logout();
          }
        })
      );
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      this.logout();
    }

    // Server error
    else if (error.status === 500) {
      // Show message
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
    }

    return throwError(error);
  }
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log(req);

    // // We retrieve the token, if any
    // const token = this.loginService.getAuthToken();
    let newHeaders = req.headers;
    // if (token) {
    //    // If we have a token, we append it to our new headers
    //    newHeaders = newHeaders.append('authtoken', token);
    // }
    // const ses = abp.utils.getCookieValue('_session');
    // if (ses) {
    //     newHeaders = newHeaders.append('_session', ses);
    // }

    // Finally we have to clone our request with our new headers
    // This is required because HttpRequests are immutable
    const authReq = req.clone({ headers: newHeaders });
    // Then we return an Observable that will run the request
    // or pass it to the next interceptor if any
    if (req.url.indexOf('tkt724.com') == -1 && req.url.indexOf('mahdburaq.com') == -1 || !this.isBrowser) {
      return next.handle(req);
    }
    if (this.appSessionService.isInit) {
      if (this.SkipWebengageUrls.findIndex(url => req.url.endsWith(url)) > -1) {
        return next.handle(req);
      }
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        try {
          if (event instanceof HttpResponse) {
            let responseBodyString = JSON.stringify(event.body);
            let reqString = JSON.stringify(req);
            // MarketingAutomatiomHandler(req,event.body);
            // eval(`marketingAutomatiomHandler('`+reqString+`','`+responseBodyString+`');`);
            //marketingAutomatiomHandler(req, event.body);
          }
        } catch (error) {
          //console.log(error);
        }

        return event;
      }));
    }
    if (this.AppInitIgnoreUrls.findIndex(url => req.url.endsWith(url)) > -1) {
      return next.handle(req);
    }
    return this.appSessionService.AppInitObs().pipe(
      switchMap(() => {
        if (this.SkipWebengageUrls.findIndex(url => req.url.endsWith(url)) > -1) {
          return next.handle(req);
        }
        return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
          try {
            if (event instanceof HttpResponse) {
              let responseBodyString = JSON.stringify(event.body);
              let reqString = JSON.stringify(req);
              // MarketingAutomatiomHandler(req,event.body);
              // eval(`marketingAutomatiomHandler('`+reqString+`','`+responseBodyString+`');`)
              //marketingAutomatiomHandler(req, event.body);
            }
          } catch (error) {
            //console.log(error);
          }
          return event;
        }));
        // .pipe(
        //     tap((event: HttpEvent<any>) => {
        //         if (event instanceof HttpResponse) {
        //             debugger
        //             if (event.body?.FailedReason==FailedReasonType.SessionAccessDenied || event.body?.FailedReason==FailedReasonType.AccessDenied) {
        //                 return this.appSessionService.AppInitObs(true).pipe(tap(x=>
        //                     {
        //                         return next.handle(authReq);
        //                     }

        //                 ));

        //             }
        //         }

        //         return event;
        //     },
        //     catchError(error => {
        //         return this.handleResponseError(error,authReq,next);
        //     }),
        //     //  this.retryAfterDelay(),
        //     //  error => { if (error instanceof HttpErrorResponse) { console.log(error.message, error.name); } }
        //     )
        // )
        ;
      })
    );

  }
  //protected handleSuccessResponse(event: HttpEvent<any>){return of<HttpEvent<any>>(event)}// Observable<HttpEvent<any>>;
  //protected handleErrorResponse(error: any): Observable<never>;
}


// export class AuthInterceptor implements HttpInterceptor {

//   appSessionService: AppSessionService;
//   refreshTokenInProgress = false;

//   tokenRefreshedSource = new Subject();
//   tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

//   constructor(private injector: Injector, private router: Router) { }

//   addAuthHeader(request) {
//     // const authHeader = this.authService.getAuthorizationHeader();
//     // if (authHeader) {
//     //     return request.clone({
//     //         setHeaders: {
//     //             "Authorization": authHeader
//     //         }
//     //     });
//     // }
//     return request;
//   }

//   refreshToken(): Observable<any> {
//     if (this.refreshTokenInProgress) {
//       return new Observable(observer => {
//         this.tokenRefreshed$.subscribe(() => {
//           observer.next();
//           observer.complete();
//         });
//       });
//     } else {
//       this.refreshTokenInProgress = true;
//       return this.appSessionService.AppInitObs().pipe(
//         tap(() => {
//           this.refreshTokenInProgress = false;
//           this.tokenRefreshedSource.next();
//         }),
//         catchError((x, y) => {
//           this.refreshTokenInProgress = false;
//           this.logout();
//           return y;
//         }));
//     }
//   }

//   logout() {
//     // abp.utils.deleteCookie('_session');
//     // this.authService.logout();
//     // this.router.navigate(["login"]);
//   }

//   handleResponseError(error, request?, next?) {
//     // Business error
//     if (error.status === 400) {
//       // Show message
//     }

//     // Invalid token error
//     else if (error.status === 401) {
//       return this.refreshToken().pipe(
//         switchMap(() => {
//           request = this.addAuthHeader(request);
//           return next.handle(request);
//         }),
//         catchError(e => {
//           if (e.status !== 401) {
//             return this.handleResponseError(e);
//           } else {
//             this.logout();
//           }
//         }));
//     }

//     // Access denied error
//     else if (error.status === 403) {
//       // Show message
//       // Logout
//       this.logout();
//     }

//     // Server error
//     else if (error.status === 500) {
//       // Show message
//     }

//     // Maintenance error
//     else if (error.status === 503) {
//       // Show message
//       // Redirect to the maintenance page
//     }

//     return throwError(error);
//   }

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
//     this.appSessionService = this.injector.get(AppSessionService);

//     // Handle request
//     request = this.addAuthHeader(request);

//     // Handle response
//     return next.handle(request).pipe(catchError(error => {
//       return this.handleResponseError(error, request, next);
//     }));
//   }
// }

// export const AuthInterceptorProvider = {
//   provide: HTTP_INTERCEPTORS,
//   useClass: AuthInterceptor,
//   multi: true
// };

@NgModule({
  providers: [
    AuthenticationService,
    // FlightLocalServices,
    PurchasepaymentService,
    HUserprofileService,
    //PassengerserviceService,
    //  TransactionServiceProxy,
    ServicedetailService,
    { provide: HTTP_INTERCEPTORS, useClass: CustpmAbpHttpInterceptor, multi: true }
  ]
})
export class SGOServiceProxyModule { }
