import { NgModule } from '@angular/core';
import { TrainRoutingModule } from './train-routing.module'
import { UtilityModule } from 'src/app/shared/utility.module'
import { CommonModule } from '@angular/common';
// Providers
import { ContentService } from './../../seo.service';
import { AppInitCallGuard } from '../../../app/auth-routing';

// interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInitInterceptor } from 'src/app/core/interceptor/train/appInit.interceptor'


@NgModule({
  imports: [
    UtilityModule,
    CommonModule,
    TrainRoutingModule,
  ],
  declarations: [
  ],
  exports: [],
  providers: [
    ContentService,
    AppInitCallGuard,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInitInterceptor,
      multi: true
    },
  ],
})
export class TrainModule { }
