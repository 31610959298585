
export class HotelOrderCheckList {
  Title: string | null = null;
  ShortTitle: string | null = null;
  TotalPriceRoom: number | null = null;
  TotalCountRoom: number | null = null;
  TotalExteraPersonAdultCount: number | null = null;
  TotalExteraPersonAdultPrice: number | null = null;
  TotalExteraPersonChildCount: number | null = null;
  TotalExteraPersonChildPrice: number | null = null;
  TotalPayablePrice: number | null = null;
  RoomId?: number | null = null;
  RoomType?: number | null = null;
}
