// Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilityModule } from './../../shared/utility.module';
import { AppSharedRoutingModule } from './app-shared-routing.module';
// Guard
import { AppInitCallGuard } from '../../auth-routing';
// Services
import { ContentService } from 'src/app/core/services/seo.service';
import { PublicAppService } from 'src/app/core/services/app/app.service';
import { AppSessionService } from 'src/app/core/services/app/app-session.service';
import { TooManyRequestsComponent } from './too-many-requests/too-many-requests.component';

@NgModule({
  imports: [
    CommonModule,
    UtilityModule,
    AppSharedRoutingModule,
  ],
  declarations: [
    TooManyRequestsComponent
  ],
  exports: [],
  providers: [
    ContentService,
    PublicAppService,
    AppSessionService,
    AppInitCallGuard
  ],
})
export class AppSharedModule { }
