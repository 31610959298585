
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerConnectionService } from '../../server-connection.service';
import { TokenContract } from './TokenContract';

@Injectable({
  providedIn: 'root'
})
    export class TokenService {
        constructor(private server: ServerConnectionService) { }
        getToken(): Observable<TokenContract> {
          var result=new TokenContract();
          return this.server.post<TokenContract>('get-token',{},result,"GatewayService");
        }

    }
