import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CSRFFromService } from '../../models/Csrf';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  _isBrowser = false;
  csrUrlList = [
    'AddCredit',
    'AddPassenger',
    'AddPassengers',
    'SaveBankAccountProfile',
    'SaveSimpleProfile',
    'AddFlightOrder',
    'PurchaseFromBankGetway',
    'PurchaseFromCreditByBasketId'
  ];

  constructor(private antiForgeryService: CSRFFromService, injector: Injector) {
    this._isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._isBrowser) return next.handle(req);
    // if (
    //   req.method === 'GET' ||
    //   req.url.includes('token') ||
    //   req.url.includes('AppInit') ||
    //   req.url.includes('Sitemap') ||
    //   req.url.includes('GetContent') ||
    //   req.url.includes('GetContents') ||
    //   req.url.includes('FilterCities') ||
    //   req.url.includes('GetLocations') ||
    //   req.url.includes('GetPoliciesTree') ||
    //   req.url.includes('GetAirportByCityName') ||
    //   req.url.includes('GetInformationForSale') ||
    //   req.url.includes('FilterAirportCategories') ||
    //   req.url.includes('GetLandingAvailability') ||
    //   req.url.includes('GetHotelAvailabilitySeo')
    // )
    let request = req.clone({ setHeaders: { 'mode': 'cors' }, withCredentials: true });
    if (this.csrUrlList.findIndex(x => request.url.toLowerCase().indexOf(x.toLowerCase()) > -1) === -1) {
      return next.handle(request);
    }
    // Convert the promise to an observable
    return from(this.antiForgeryService.get()).pipe(
      switchMap(token => {
        const headers = request.headers.set('RequestVerificationToken', token);
        const clonedRequest = request.clone({ headers });
        return next.handle(clonedRequest);
      })
    );
  }
}
