// Module
import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler, APP_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { MdsAngularPersianDateTimePickerModule } from 'src/shared/mds-angular-persian-date-time-picker/src/public_api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from 'src/shared/utils/utils.module';
import { SGOServiceProxyModule } from '../shared/signalGo/SGOServiceProxy.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

// Components
import { LoginComponent } from './layout/login/login.component';
import { FooterComponent } from './Components/footer-component/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { RouterComponent } from './layout/notfound/RouterComponent';
import { CovidAlertComponent } from './layout/covid-alert/covid-alert.component';
import { VpnAlertComponent } from './layout/vpn-alert/vpn-alert.component';
import { GiftToastComponent } from './layout/gift-toast/gift-toast.component';
import { DialogDiscount } from './layout/dialog-discount/dialog-discount.component';
import { InstagramComponent } from './Components/instagram-component/cta-instagram.component';
import { SelectpassengerComponent } from './layout/selectpassenger/selectpassenger.component';
import { UserNotifyComponent } from './layout/user-notify/user-notify.component';
import { OfferDiscountComponent } from './layout/OfferDiscount/offerdiscount';
import { UmagComponent } from './umag/umag';
import { SitemapGeneratorComponent } from './sitemap/sitemapGenerator';
import { LandingArbaeenComponent } from './landing-arbaeen/landing-arbaeen.component';
import { PendingPagesComponent } from './Components/pending-pages-component/pending-pages-component';

import { ContentService } from './seo.service';
import { FlightLocalServices } from './core/services/app/flight.service';
import { NavigationService } from './core/services/app/navigation.service';
import { PublicAppSignalService } from './core/services/app/public-app-signal.service';
import { PublicAppService } from './core/services/app/app.service';
import { AppSessionService } from './core/services/app/app-session.service';
import { HotelDetailContextService } from './core/services/hotel/hotel-detail-content.service';
import { HotelPurchaseService } from './core/services/hotel/hotel-purchase.service';
import { HotelRouterServices } from './hotel/services/hotel-router.services';
import { GoogleAnalyticsService } from './core/services/app/google-analytics/google-analytics.service';

// Directives
import {
  ImagePreloadDirectiveDirective
} from './modules/hotel/share-component/image-preload-directive/image-preload-directive.directive';

// Guard
import { AppInitCallGuard, AppInitRequiredGuard, LoginRequiredGuard } from '../app/auth-routing';

// Utility
import player from 'lottie-web';
import { environment } from 'src/environments/environment';

// Team Modules
import { AppSharedModule } from './modules/app/app-shared.module';
import { TrainModule } from './modules/train/train.module'
import { HotelModule } from './modules/hotel/hotel.module'
import { FlightModule } from './modules/flight/flight.module';
import { ProfileModule } from './modules/profile/profile.module';
import { UtilityModule } from './shared/utility.module';

// STORE
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LottieComponent } from 'ngx-lottie';
import { allUserReducer } from './core/store/app/user/controllers/user.reducer';
import { HUserprofileService } from 'src/shared/signalGo/services/huserprofileService';
import { AuthenticationService } from 'src/shared/signalGo/services/authenticationService';
import { PRECONNECT_CHECK_BLOCKLIST } from '@angular/common';
import { StandardUrlSerializer } from './standard-url-serializer.guard';
// import * as Sentry from '@sentry/angular-ivy';
import { ErrorInterceptor } from "./core/interceptor/app/error.interceptor";
import { UUIDFromStorage } from './core/models/UuidGenerator';
import { UrlSerializer } from '@angular/router';
import { TracingDeviceInterceptor } from './core/interceptor/global/TracingDevice.interceptor';
import { AuthInterceptor } from './core/interceptor/global/Auth.Interceptor';
import { NgxSliderModule } from '@angular-slider/ngx-slider';


export function playerFactory() {
  return player;
}

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        FooterComponent,
        CovidAlertComponent,
        VpnAlertComponent,
        InstagramComponent,
        SelectpassengerComponent,
        UserNotifyComponent,
        NotfoundComponent,
        OfferDiscountComponent,
        RouterComponent,
        UmagComponent,
        SitemapGeneratorComponent,
        GiftToastComponent,
        DialogDiscount,
        ImagePreloadDirectiveDirective,
        // RecommendedHotelComponent,
        LandingArbaeenComponent,
        // Components
        // FAQComponent,
        PendingPagesComponent,
    ],
    imports: [
      AppSharedModule,
      FormsModule,
      ReactiveFormsModule,
      MdsAngularPersianDateTimePickerModule,
      UtilsModule,
      BrowserModule,
      BrowserAnimationsModule,
      // Lazy Load Modules
      UtilityModule,
      TrainModule,
      HotelModule,
      FlightModule,
      ProfileModule,

      // Lazy Load Modules
      AppRoutingModule,
      SGOServiceProxyModule,
      NgxSliderModule,
      LottieComponent,
      //LottieModule.forRoot({ player: playerFactory }),

      StoreModule.forRoot({ 'user': allUserReducer }),
      EffectsModule.forRoot(),
      StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        logOnly: environment.production, // Restrict extension to log-only mode
        autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      }),

      ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),

    ],
     providers: [
        { provide: APP_ID, useValue: 'tkt724-ssr' },
        ContentService,
        FlightLocalServices,
        PublicAppSignalService,
        PublicAppService,
        NavigationService,
        AppSessionService,
        LoginRequiredGuard,
        AppInitCallGuard,
        AppInitRequiredGuard,
        HotelDetailContextService,
        HotelPurchaseService,
        HotelRouterServices,
        Title,
        GoogleAnalyticsService,
        {
            provide: UrlSerializer,
            useClass: StandardUrlSerializer,
        },
        { provide: HTTP_INTERCEPTORS, useClass: TracingDeviceInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: (appSessionService: AppSessionService) => () => appSessionService.AppInitObs(),
            deps: [AppSessionService, AuthenticationService, HUserprofileService],
            multi: true,
        },
        { provide: PRECONNECT_CHECK_BLOCKLIST, useValue: environment.preconnect },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ],
    bootstrap: [AppComponent],
    exports: [
      MdsAngularPersianDateTimePickerModule,
      NgxSliderModule,
      LottieComponent,
    ]
  })
export class AppModule {
  // constructor(trace: Sentry.TraceService) {
  // }
  constructor(uuidFromStorage: UUIDFromStorage) {}
}
