
import { Injectable} from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class NavigationService {
    constructor(){

    }
    public backClicked=new Subject<void>();

}
