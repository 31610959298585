import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

export const userActions = createActionGroup({
  source: 'user',
  events: {
    // First Defining Of Value // => this is only for ease of finding datas
    'Create Initial': props<{
      mobile: number | string,
      emailAddress: string,
      isBrowser: any,
      isAuthenticated: any,
    }>(),
    // Update All Initial Values
    'Update All': props<{
      mobile: number | string,
      emailAddress: string,
      isBrowser: any,
      isAuthenticated: any,
    }>(),
    // User Mobile
    'Update Authenticated': props<{ isAuthenticated: any }>(),
    'Update Mobile': props<{ mobile: any }>(),
    'Update Email Address': props<{ emailAddress: string }>(),
  },
});

// Name space of module between []
// export const getData = createAction('[Search] Get Data', props<{test: any}>())
