
export class HotelOrderCheckListDetail {
  ChekInDate: Date | null = null;
  CheckOutDate: Date | null = null;
  ChekInTime: string | null = null;
  CheckOutTime: string | null = null;
  CheckInpPrsianDate: string | null = null;
  CheckOutPersianDate: string | null = null;
  TotalRoomCount: number | null = null;
  NightCout: number | null = null;
}
