import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'c3'
})
export class Comma3Pipe implements PipeTransform {
  ld(str: string): string {
      if (str === null) {
          str = '';
      }
      str=str.toString();
      let res = '';

      while (str.length > (3+ (str[0]=='-'?1:0))) {
          res = ',' + str.slice(str.length - 3) + res;
          str = str.slice(0, str.length - 3);
      }
      return str + res;
  }
  transform(key: string | number, ...args: any[]): string {
    key = key.toString();
      return this.ld(key || '');
  }
}
