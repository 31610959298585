import { Component, Inject, Injector, Optional } from '@angular/core';
import { ContentComponent } from '../../core/contents/content.component';
import {Response} from 'express';
import {
  InjectionToken,
} from '@angular/core';
export const RESPONSE = new InjectionToken<Response>('RESPONSE');
@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',

})
export class NotfoundComponent extends ContentComponent {

  constructor(public injector: Injector, @Optional() @Inject(RESPONSE) private res) {
    super(injector);
    //this.injector1=injector;
    this.setTitleDec("404","404","404",this.noIndex);
    if (!this.isBrowser && injector) {
      if(RESPONSE) this.response= this.res;
      this.response?.status(404);
    }
  }

}
