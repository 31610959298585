import { Injector, Pipe, PipeTransform } from '@angular/core';
// import { AppConsts } from 'shared/AppConsts';

import * as moment from 'moment';
import * as jmoment from 'jalali-moment';
// import { AppLocalizationService } from 'shared/common/localization/app-localization.service';
//import { LocalizationService } from 'abp-ng2-module';

@Pipe({
    name: 'localize'
})
export class LocalizePipe implements PipeTransform {

    localizationSourceName = 'fa';//AppConsts.localization.defaultLocalizationSourceName;

    //localization: LocalizationService;

     constructor(injector: Injector) {
    //     this.localization = injector.get(LocalizationService);
     }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = key;//this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return '';//** */ abp.utils.formatString.apply(this, args);
    }
    // ld(str:string):string{
    //     const ide = {'۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9'};
    //     const idp = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    //     if (abp.localization.currentLanguage.name == 'fa')
    //         return str.toString().replace(/[0-9]/g, function (w) {
    //             return idp[+w]
    //         });
    //     return str.toString().replace(/[^0-9.]/g, function (w) {
    //         return ide[w] || w;
    //     });
    // }
    transform(key: string, ...args: any[]): string {
        return this.l(key, args);
    }
}
@Pipe({
    name: 'ld'
})
export class LocalizeDigitPipe implements PipeTransform {

    ld(str: string): string {
        if (str === null || str === '' || str === undefined)
            return '';
        const ide = { '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9' };
        const idp = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

        return str.toString().replace(/[0-9]/g, function (w) {
            return idp[+w];
        });


       //** */  if (abp.localization.currentLanguage.name == 'fa')
            return str.toString().replace(/[0-9]/g, function (w) {
                return idp[+w]
            });
        return str.toString().replace(/[^0-9.]/g, function (w) {
            return ide[w] || w;
        });
    }
    transform(key: string, ...args: any[]): string {
        return this.ld(key);
    }
}
@Pipe({
    name: 'c3'
})
export class Comma3Pipe implements PipeTransform {
    ld(str: string): string {
        if (str === null) {
            str = '';
        }
        str=str.toString();
        let res = '';

        while (str.length > (3+ (str[0]=='-'?1:0))) {
            res = ',' + str.slice(str.length - 3) + res;
            str = str.slice(0, str.length - 3);
        }
        return str + res;
    }
    transform(key: string, ...args: any[]): string {

        return this.ld(key || '');
    }
}
@Pipe({
    name: 'tom'
})
export class TomanPipe implements PipeTransform {
    transform(key: string, ...args: any[]): string {
        key = key || '';
        // const ret = key;
        const ret = key.slice(0, key.length - 1);
        if (ret === '')
            return '0';
        return ret;
    }
}
@Pipe({
    name: 'rial'
})
export class RialPricePipe implements PipeTransform {
    transform(key: string, ...args: any[]): string {
        key = key || '';
        const ret = key;
        // const ret = key.slice(0, key.length - 1);
        if (ret === '')
            return '0 ریال';
        return ret + ' ریال ';
    }
}
@Pipe({
    name: 'toman'
})
export class TomanPricePipe implements PipeTransform {
    transform(key: string, ...args: any[]): string {
        key = key || '';
        const ret = key;
        // const ret = key.slice(0, key.length - 1);
        if (ret === '')
            return '0';
        return ret;
    }
}
@Pipe({
    name: 'alpn'
})
export class AirLinePersianNamePipe implements PipeTransform {
    constructor() { }
    transform(key: string, ...args: any[]): string {
        const idp = { 'ZV': 'زاگرس', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9' };
        return idp[key];
    }
}

@Pipe({
    name: 'timedur'
})
export class TimeDurationPipe implements PipeTransform {
    transform(value: any, ...args: string[]): string {
        if (typeof args === 'undefined' || args.length !== 1) {
            throw new Error('DurationPipe: missing required time unit argument');
        }
        return moment.duration(value, args[0] as moment.unitOfTime.DurationConstructor).humanize();
    }
}

@Pipe({
    name: 'jdate'
}) export class JalaliPipe implements PipeTransform {
        transform(value: any, args?: any): any {
            if (value!=undefined){
               let format = args || 'dddd D MMMM YYYY';
            try {
                return jmoment(value).locale('fa').format(format);
            }
            catch (error) {
                return value.toString();
            }
            }
            return '';
        }
}
@Pipe({
    name: 'jdateP'
}) export class JalaliPipeP implements PipeTransform {
        transform(value: any, args?: any): any {
            if (value!=undefined){
               let format = args || 'dddd D MMMM YYYY';
            try {
                return jmoment(value,'jYYYY-jM-jDTHH:mm:ss').locale('fa').format(format);

            }
            catch (error) {
                return value.toString();
            }
            }
            return '';


        }
}

@Pipe({
    name: 'enumt'
}) export class EnumToText implements PipeTransform {
        transform(value: any, args?: any): any {
            let data = args || [];
            try {
                return data[value];
            } catch {

            }
            return value;
        }
}

@Pipe({
    name: 'mspipe'
})
export class MinuteSecondsPipe implements PipeTransform {

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return minutes + ':' + (value - minutes * 60);
    }

}
