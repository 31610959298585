import { animate,keyframes,style,transition,trigger } from '@angular/animations';

export const inOutPaneAnimation = trigger('inOutPaneAnimation', [
  transition(':enter', [
    animate(
      '0.75s cubic-bezier(0.51, 0.32, 0.2, 1.07)',
      keyframes([
        style({ opacity: 0, transform: 'translateX(120%)', offset: 0 }),
        style({ opacity: 0.8, transform: 'translateX(-5%)', offset: 0.95 }),
        style({ opacity: 1, transform: 'translateX(0)', offset: 1 }),
      ])
    ),
  ]),
  transition(':leave', [
    animate(
      '.5s cubic-bezier(0.51, 0.32, 0.2, 1.07)',
      keyframes([
        style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
        style({ opacity: 0.8, transform: 'translateX(-5%)', offset: 0.95 }),
        style({ opacity: 1, transform: 'translateX(120%)', offset: 1 }),
      ])
    ),
  ]),
]);
