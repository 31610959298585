<!-- How To Use -->
<!--
  // Success
  <button class="btn" (click)="alertService.success('Success!!', options)">Success</button>
  // Error
  <button class="btn" (click)="alertService.error('Error!!', options)">Error</button>
  // Info
  <button class="btn" (click)="alertService.info('info!!', options)">Info</button>
  // warn
  <button class="btn" (click)="alertService.warn('warn!!', options)">warn</button>
  // options autoClose
  <button class="btn" (click)="alertService.warn('warn!!', options.autoClose)">options autoClose</button>
  // options keepAfterRouteChange
  <button class="btn" (click)="alertService.warn('warn!!', options.keepAfterRouteChange)">options keepAfterRouteChange</button>
  // clear
  <button class="btn" (click)="alertService.clear()">clear</button>

  // Multiple
  <button class="btn" (click)="alertService.success('Success on the left!!', { id: 'alert-1' })">Info</button>
  <button class="btn" (click)="alertService.success('Success on the left!!', { id: 'alert-2' })">Info</button>
-->

<div class="alert-container">
  <div *ngFor="let alert of alerts" class="alert" [class]="cssClass(alert)" @inOutPaneAnimation>
    <div class="alert__icon">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m20.859 15.331-3.772 6.155a5.235 5.235 0 0 1 -3.87 2.477 5.315 5.315 0 0 1 -.628.037 5.212 5.212 0 0 1 -3-.955 4.741 4.741 0 0 1 -6.689-6.566l-1.315-1.313a5.264 5.264 0 0 1 .955-8.2l5.767-3.566a8.859 8.859 0 0 1 10.327.551l1.659-1.659a1 1 0 1 1 1.414 1.414l-1.657 1.658a8.951 8.951 0 0 1 .809 9.967zm-12.794 6.316-3.719-3.72a2.721 2.721 0 0 0 .463 3.264 2.827 2.827 0 0 0 3.256.456zm9.921-15.6a6.887 6.887 0 0 0 -8.617-.947l-5.777 3.566a3.265 3.265 0 0 0 -.592 5.086l7.29 7.291a3.265 3.265 0 0 0 5.093-.6l3.755-6.125a6.937 6.937 0 0 0 -1.152-8.276zm1.279 17.953a1 1 0 0 1 -.591-1.808 8.633 8.633 0 0 0 3.315-5.407 1 1 0 1 1 1.953.43 10.7 10.7 0 0 1 -4.088 6.593 1 1 0 0 1 -.589.192zm-18.265-18.261a1 1 0 0 1 -.8-1.594 10.692 10.692 0 0 1 6.713-4.125 1 1 0 1 1 .4 1.96 8.636 8.636 0 0 0 -5.513 3.354 1 1 0 0 1 -.8.405z"/></svg>
    </div>
    <div class="alert__text" [innerHTML]="alert.message"></div>
    <div class="alert__close" (click)="removeAlert(alert)">
      <i class="icon-close"></i>
    </div>
  </div>
</div>
