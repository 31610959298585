import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { ContentService } from './../../seo.service';
import { AppInitCallGuard } from '../../../app/auth-routing';
import { QueryAndStoreGuard } from '../../core/guards/app/query-and-store.guard';

const routes: Routes = [
  {
    path: 'train',
    children: [
      {
        path: 'refund-ticket',
        loadChildren: () => import('./refund/trainRefundPage.module').then(m => m.trainRefundPageModule),
        canActivate: [
          ContentService,
          AppInitCallGuard
        ]
      },
      {
        // :fromtos
        path: 'landings',
        loadChildren: () => import('./landing/trainLanding.module').then(m => m.TrainLandingModule),
        canActivate: [
          ContentService,
          AppInitCallGuard
        ],
        data: { contentType: 'train/' , activetab: 'train' }
      },
      {
        path: ':fromto',
        loadChildren: () => import('./fromto/trainPathComponent.module').then(m => m.trainPathModule),
        canActivate: [
          ContentService,
          AppInitCallGuard
        ],
        data: { contentType: 'train/', activetab: 'train' }
      },

    ]
  },
  {
    path: 'searchTrain/:fromto',
    loadChildren: () => import('./search/searchtrain.module').then(m => m.SearchTrainModule),
    data: { isInside: true }
  },
  {
    path: 'searchTrain/passenger/:fromto',
    loadChildren: () => import('./search/passenger/passenger.module').then(m => m.PassengerModule),
    canActivate: [QueryAndStoreGuard],
    data: { isInside: true }
  },
  {
    path: 'searchTrain/confirm/:fromto',
    loadChildren: () => import('./search/confirm/confirm.module').then(m => m.ConfirmModule),
    canActivate: [QueryAndStoreGuard],
    data: { isInside: true }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrainRoutingModule { }
