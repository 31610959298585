import { isPlatformBrowser } from '@angular/common';
import {
  ApplicationRef,
  Injector,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CacheService } from './core/services/app/clearCache/clear-cache.service';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService extends CacheService{
  constructor(injector: Injector, appRef: ApplicationRef, updates: SwUpdate) {
    super();
    if (
      environment.production &&
      isPlatformBrowser(injector.get(PLATFORM_ID))
    ) {
      // Allow the app to stabilize first, before starting
      // polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everySixHours$
      );

      everySixHoursOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await updates.checkForUpdate();
          if (updateFound) {
           console.log('CheckForUpdateService'+ updateFound);
            this.clearCache();
            window.location.reload();
          }
          console.log(
            updateFound
              ? 'A new version is available.'
              : 'Already on the latest version.'
          );
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }
}
