import {  Component, Injector } from '@angular/core';
import { AppComponentBase } from 'src/app/app-component-base';


@Component({
  selector: 'app-routerComponent',
  template: 'در حال انتقال به صفحه اصلی',//

})
export class RouterComponent extends AppComponentBase {

  constructor(public injector: Injector) {
    super(injector);

    // if (this.isBrowser)
    {
      const validperfix = ['flight', 'train', 'contactus','aboutus','policy','hotel'];///aboutus

      // let url=this.activatedRoute.snapshot.url.join('/').toLowerCase()
      let paths = this.activatedRoute.snapshot.url;
      let found = false;
      //console.log('check for valid url')

      if (paths.length > 0) {
        for (let element of validperfix) {
          // console.log(paths[0].path,element)
          if (paths[0].path.toLowerCase() === element) {
            paths[0].path = element;
            // console.log(paths)
            found = true;
            if (element=='flight' && paths.length ==1 ) {
              this.router.navigateByUrl('/');
              return;
            }
            this.router.navigateByUrl('/'+paths.map(o=>o.path).join('/'));
            return;
          }

        };
        if (!found && this.isBrowser) {
          this.router.navigateByUrl('/404');
          window.location.href ='/404';
        }
      }
    }
  }

}
