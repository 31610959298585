import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { AppInitCallGuard } from 'src/app/auth-routing';

// Yasin Check This
import { HotelRouterServices } from '../../core/services/hotel/hotel-router.service';

const routes: Routes = [
  {
    path: 'hotel/reserve/:id',
    loadChildren: () => import('./reserve/hotel-reserve.module').then(m => m.HotelReserveModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/payment/:id',
    loadChildren: () => import('./payment/hotel-payment.module').then(m => m.HotelPaymentModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/paymentOnline/:id',
    loadChildren: () => import('./payment-online/hotel-payment-online.module').then(m => m.HotelPaymentOnlineModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/paymentedit/:id',
    loadChildren: () => import('./payment-edit/hotel-payment-edit.module').then(m => m.HotelPaymentEditModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/confirm/:id',
    loadChildren: () => import('./confirm/hotel-confirm.module').then(m => m.HotelConfirmModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/cancellation/:id',
    loadChildren: () => import('./cancellation/hotel-cancellation.module').then(m => m.HotelCancellationModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/canellation/:id',
    loadChildren: () => import('./cancellation/hotel-cancellation.module').then(m => m.HotelCancellationModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/paymentfinishtime/:id',
    loadChildren: () => import('./payment-finish-time/hotel-payment-finish-time.module').then(m => m.HotelPaymentFinishTimeModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/disagreement/:id',
    loadChildren: () => import('./disagreement/hotel-disagreement.module').then(m => m.HotelDisagreementModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/await/:id',
    loadChildren: () => import('./await/hotel-await.module').then(m => m.HotelAwaitModule),
    canActivate: [AppInitCallGuard],
    data: { isInside: true }
  },
  {
    path: 'hotel/refund',
    loadChildren: () => import('./refund/hotel-refund.module').then(m => m.HotelRefundModule)
  },
  {
    path: 'searchHotel/detail/:id',
    loadChildren: () => import('./hotelsearch/hotel-search.module').then(m => m.HotelSearchModule),
    canActivate: [HotelRouterServices],
    data: { isInside: true }
  },
  {
    path: 'searchHotel/:name',
    loadChildren: () => import('./hotelsearch/hotel-search.module').then(m => m.HotelSearchModule),
    data: { isInside: true }
  },
  {
    path: 'hotel/landing/:name',
    redirectTo: 'hotel/:name'
  },
  {
    matcher: (url) => {
      if (
        url.length === 2 && url[1].path.toLowerCase().endsWith('-htl') ||
        url.length === 3 && url[1].path === 'landing' && url[2].path.toLowerCase().endsWith('-htl')
      ) {
        return {
          consumed: url,
          posParams: {
            name: new UrlSegment(url[url.length === 2 ? 1 : 2].path, {})
          }
        };
      }
      return null;
    },
    loadChildren: () => import('./landing/landing-main.module').then(m => m.HotelLandingMainModule),
  },
  {
    path: 'hotel/:name',
    loadChildren: () => import('./../hotel/name/hotel-detail.module').then(m => m.hotelDetailModule),
    data: { isInside: true },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class HotelRoutingModule { }
