import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSessionService } from '../../services/app/app-session.service';

@Injectable()
export class AppInitInterceptor implements HttpInterceptor {

  constructor(
    private appSession: AppSessionService
  ) {
  }

  intercept(httpRequest: HttpRequest<any>, httpHandle: HttpHandler): Observable<HttpEvent<any>> {
    const isTrainApi = httpRequest.url.includes('/Train/');

    return httpHandle.handle(httpRequest).pipe(
      map<HttpEvent<any>, any>(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && isTrainApi && event.body.Error) {
            let errorCode: number = event.body.Error.Code;
            if ([35].includes(errorCode)) {
              this.appSession.logout();
              console.log('app init error : ', event.body.Error.Code)
            }
          }

          return event;
        }
      )
    )
  }

}
