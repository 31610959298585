import { TagContract } from './../tag.contract.class';
import { HotelOrderCheckList } from './hotel-order-checklist.class';

export class HotelOrderHeader {
  Title: string | null = null;
  Image: string | null = null;
  Images: any[] = [];
  DefaultImage: string | null = null;
  Address: string | null = null;
  ImageTag: TagContract | null = null;
  PriceTag: TagContract | null = null;
  BodyTag: TagContract[] | null = null;
  TotalPayablePrice: number | null = null;
  StartPrice: number | null = null;
  Point: number | null = null;
  StarCount: number = 0;
  CommnetCount: number | null = null;
  NightCount: number | null = null;
  Checkin: Date | null = null;
  Checkout: Date | null = null;
  HotelOrderRoomList: HotelOrderCheckList[] | null = null;
}
