import { AppStateInterface } from "../../app/appstate.interface";
import { createSelector } from "@ngrx/store";

export const selectSearchTrain = (state: AppStateInterface) => state.searchTrain

export const selectTwoWayDepartureTrain = createSelector(
    selectSearchTrain,
    (state) => state.stateData.twoWayDepartureTrain
);

export const selectTSR = createSelector(
    selectSearchTrain,
    (state) => state.stateData.TSR
)

export const selectTodayDate = createSelector(
    selectSearchTrain,
    (state) => {
        if (state.stateData.todayDate) {
            return state.stateData.todayDate
        } else {
            return 'Nothing Found'
        }
    }
)
