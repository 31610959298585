<!-- Login & Register 0.1v -->
<div class="modal-dialog auth-modal" [ngClass]="{'active':showactive}" (click)="formFocus($event)">
  <div class="modal-dialog_content" style="--max-w:500px">
    <div class="modal-dialog_content_header">
      <span class="modal-dialog_content_header__title" *ngIf="!captchaNeeded && steplogin == 1"> ورود | ثبت‌نام </span>
      <span class="modal-dialog_content_header__title" *ngIf="captchaNeeded"> تایید هویت </span>
      <span class="modal-dialog_content_header__title" *ngIf="!captchaNeeded && steplogin == 2">تایید شماره موبایل</span>
      <span class="modal-dialog_content_header__title" *ngIf="!captchaNeeded && steplogin == 3">ورود با رمز عبور</span>
      <span class="modal-dialog_content_header__title" *ngIf="!captchaNeeded && steplogin == 4">تغییر رمز عبور</span>
      <span class="modal-dialog_content_header__title" *ngIf="!isFromForgetPassword && steplogin == 5">تعیین رمز عبور</span>
      <span class="modal-dialog_content_header__title" *ngIf="isFromForgetPassword && steplogin == 5">فراموشی رمز عبور</span>
      <button type="button" class="modal-dialog_content_header__close" (click)="closeModal()">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-dialog_content_body">
      <!-- <a href="tel:09398633170" class="support-tel">تماس با پشتیبانی : 09398633170</a> -->
        <!-- Tabs Form Authenticate User With Mobile -->
        <div class="auth-phone" *ngIf="!captchaNeeded && steplogin ==1">
          <h4>لطفا شماره موبایل خود را وارد کنید</h4>
          <form class="auth-form" [formGroup]="mobileLoginForm">
            <!-- Input -->
            <div class="input-group" [ngClass]="{'input-group--error': mobileLoginForm.invalid && (mobileLoginForm.controls.mobileNumber.dirty)}">
              <label for="InputNumberPhone">شماره موبایل</label>
              <input type="tel" class="input" placeholder="شماره موبایل خود را وارد کنید" maxlength="11" id="InputNumberPhone" autocomplete="off" formControlName="mobileNumber" name="mobileNumber" autoFocus />
              <span class="input-group-validation">شماره موبایل معتبر نیست</span>
            </div>
            <!-- Input -->
            <!-- Rules -->
            <div class="text-rules">ادامه این فرآیند به منزله پذیرفتن <a> قوانین و مقررات </a> تیکت 724 است.</div>
            <!-- Rules -->
            <div class="auth-actions">
              <!-- Authentication -->
              <button type="submit" class="btn btn--default btn--next-step" (click)="mobileLogin()" [disabled]="mobileLoginForm.invalid || waitForSendSms || isSaving">
                  {{waitForSendSms?'':'تایید و دریافت کد'}}
                  <svg class="circular center-horizontal" *ngIf="waitForSendSms || isSaving">
                    <circle class="path" cx="15" cy="15" r="10" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10"></circle>
                  </svg>
              </button>
              <button type="button" class="btn btn--auth-pass" (click)="switchTab(3)">ورود با رمز عبور</button>
            </div>
          </form>
        </div>
        <!-- Tabs Form Authenticate User With Mobile -->

        <!-- Tabs Form Captcha -->
        <div class="auth-captcha" *ngIf="captchaNeeded">
          <h4>لطفا کد امنیتی زیر را وارد کنید</h4>
          <form class="auth-form" [formGroup]="captchaForm" method="post" (ngSubmit)="verifyCaptcha()">
            <!-- Input Captcha Images -->
            <div class="input-group input-group--captcha">
                <div class="captcha-image">
                    <img [src]="captchaImage" width="100" height="40" loading="lazy" *ngIf="captchaImage != '' "/>
                    <div class="dot-animation" style="--setcolor:#ced3d7" *ngIf="captchaImage == ''"></div>
                </div>
                <div class="captcha-reload" (click)="refreshCaptcha()">
                  <i class="icon-reload"></i>
                </div>
            </div>
            <!-- Input Captcha Images -->
            <!-- Input Captcha -->
            <!-- Edited Content, Newly added -->
            <!-- <div class="input-group" [ngClass]="{'input-group--error': createCaptchaForm.invalid && (createCaptchaForm.controls.Capcha_Code.dirty)}"> -->
            <div class="input-group">
                <input type="text" class="input" type="text" autocomplete="false" formControlName="Capcha_Code"  placeholder="لطفا متن باکس بالا را وارد کنید" #Capcha_Code />
                <!-- <span class="input-group-validation">کد امنیتی وارده شده صحیح نمی باشد</span> -->
            </div>
            <!-- Input Captcha -->
            <div class="auth-actions">
              <button type="submit" class="btn btn--default btn--next-step" [disabled]="isVerifyingCaptcha || captchaForm.invalid">{{isVerifyingCaptcha?'':'تایید و ادامه'}}
                <svg class="circular center-horizontal" *ngIf="isVerifyingCaptcha">
                    <circle class="path" cx="15" cy="15" r="10" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10"></circle>
                </svg>
              </button>
            </div>
          </form>
        </div>
        <!-- Tabs Form Captcha -->

        <!-- Tabs Form Verify Code -->
        <div class="auth-code" *ngIf="!captchaNeeded && steplogin == 2">
          <h4>کد 5 رقمی ارسال شده به شماره زیر را وارد کنید </h4>
          <div class="auth-user-input">
            <span>{{mobileNumberInput}}</span>
            <button type="button" class="btn btn--edit" (click)="switchTab(1)">ویرایش</button>
          </div>
          <form class="auth-form" [formGroup]="mobileLoginCodeForm" method="post" (ngSubmit)="mobileLogin()">
              <div class="inline-code-quick-arrival">
                <div class="timer">{{getResnedTimeout}}</div>
                <div class="input-codes">
                  <div class="input-group" [ngClass]="{'input-group--error': mobileLoginCodeForm.controls.Number_One.invalid && (mobileLoginCodeForm.controls.Number_One.dirty || mobileLoginCodeForm.controls.Number_One.touched)}">
                    <input (paste)="onPaste($event,false)" type="tel" class="input"
                        (focus)="selectall($event)" placeholder="" autocomplete="one-time-code" inputmode="numeric"
                        formControlName="Number_One" maxlength="1" tabindex="104" #Number_One
                        (keydown)="verify_code_input_keydown($event,Number_Two,Number_One)"
                        (keyup)="verify_code_input_keyup($event,Number_One, true)">
                  </div>
                  <div class="input-group" [ngClass]="{'input-group--error': mobileLoginCodeForm.controls.Number_Two.invalid && (mobileLoginCodeForm.controls.Number_Two.dirty || mobileLoginCodeForm.controls.Number_Two.touched)}">
                    <input (paste)="onPaste($event,false)" type="tel" class="input"
                        (focus)="selectall($event)" placeholder="" autocomplete="off" inputmode="numeric"
                        formControlName="Number_Two" maxlength="1" tabindex="103" #Number_Two
                        (keydown)="verify_code_input_keydown($event,Number_Three,Number_One)"
                        (keyup)="verify_code_input_keyup($event,Number_One, false)">
                  </div>
                  <div class="input-group" [ngClass]="{'input-group--error': mobileLoginCodeForm.controls.Number_Three.invalid && (mobileLoginCodeForm.controls.Number_Three.dirty || mobileLoginCodeForm.controls.Number_Three.touched)}">
                    <input (paste)="onPaste($event,false)" type="tel" class="input"
                        (focus)="selectall($event)" placeholder="" autocomplete="off" inputmode="numeric"
                        formControlName="Number_Three" maxlength="1" tabindex="102" #Number_Three
                        (keydown)="verify_code_input_keydown($event,Number_Four,Number_Two)"
                        (keyup)="verify_code_input_keyup($event,Number_Two, false)">
                  </div>
                  <div class="input-group" [ngClass]="{'input-group--error': mobileLoginCodeForm.controls.Number_Four.invalid && (mobileLoginCodeForm.controls.Number_Four.dirty || mobileLoginCodeForm.controls.Number_Four.touched)}">
                    <input (paste)="onPaste($event,false)" type="tel" class="input"
                        (focus)="selectall($event)" placeholder="" autocomplete="off" inputmode="numeric"
                        formControlName="Number_Four" maxlength="1" tabindex="101" #Number_Four
                        (keydown)="verify_code_input_keydown($event,Number_Five,Number_Three)"
                        (keyup)="verify_code_input_keyup($event,Number_Three, false)">
                  </div>
                  <div class="input-group" [ngClass]="{'input-group--error': mobileLoginCodeForm.controls.Number_Five.invalid && (mobileLoginCodeForm.controls.Number_Five.dirty || mobileLoginCodeForm.controls.Number_Five.touched)}">
                    <input (paste)="onPaste($event,true)" type="tel" class="input focusinput"
                        (focus)="selectall($event)" placeholder="" autocomplete="off" inputmode="numeric"
                        formControlName="Number_Five" maxlength="1" tabindex="100" #Number_Five
                        (keydown)="verify_code_input_keydown($event,Number_Five,Number_Four)"
                        (keyup)="verify_code_input_keyup($event,Number_Four, false)" autoFocus>
                  </div>
                </div>
              </div>
              <span *ngIf="invalidVerifyCode" class="input-group-validation">کد وارد شده نامعتبر است لطفا مجددا تلاش کنید</span>
              <div class="timer-mobile" *ngIf="this.remaining>0">
                زمان باقی مانده: {{getResnedTimeout}}
              </div>
              <div class="auth-user-option" *ngIf="this.remaining<=0">
                <button type="submit" class="btn btn--repeat-again" (click)="sendSms(1);" [disabled]="isVerifying">ارسال مجدد کد پیامکی</button>
                <button type="submit" class="btn btn--voice-code" (click)="sendSms(2)">دریافت تلفنی کد تایید</button>
              </div>
              <div class="auth-actions">
                <button type="submit" class="btn btn--default btn--next-step" [disabled]="isVerifying || mobileLoginCodeForm.invalid">{{isVerifying?'':'تایید و ادامه'}}
                    <svg class="circular center-horizontal" *ngIf="isVerifying">
                      <circle class="path" cx="15" cy="15" r="10" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10"></circle>
                    </svg>
                </button>
                <button type="button" class="btn btn--auth-pass" (click)="switchTab(3)">ورود با رمز عبور</button>
              </div>
          </form>
        </div>
        <!-- Tabs Form Verify Code -->

        <!-- Tabs Form Authenticate User With Mobile Password -->
        <div class="auth-user" *ngIf="!captchaNeeded && steplogin == 3">
          <h4>شماره موبایل و رمز عبور خود را وارد کنید</h4>
          <form class="auth-form" (ngSubmit)="authenticateUserWithMobilePassword()" [formGroup]="authWithUsernameAndPasswordFormGroup">
            <div class="input-group" [ngClass]="{'input-group--error': submitted && u?.username?.errors}">
              <label for="InputNumberPhoneUser">شماره موبایل</label>
              <input type="tel" class="input" placeholder="شماره موبایل خود را وارد کنید" formControlName="username" maxlength="11" id="InputNumberPhoneUser" autocomplete="off" autoFocus />
              <span class="input-group-validation">شماره موبایل معتبر نیست</span>
            </div>
            <div class="input-group" [ngClass]="{'input-group--error': submitted && u?.password?.errors}">
              <label for="InputPasswordUser">رمز عبور</label>
              <input [type]="(userPasswords === false) ? 'password' : 'text'" class="input" placeholder="رمز عبور خود را وارد کنید" formControlName="password" maxlength="20" id="InputPasswordUser" />
              <i [ngClass]="userPasswords ? 'icon-visible' : 'icon-unvisible' " (click)="toggleShowPassword('userPasswords')"></i>
              <span class="input-group-validation" *ngIf="u?.password?.errors?.required">وارد کردن رمز عبور الزامی است</span>
            </div>
            <div class="auth-user-option">
              <button type="button" class="btn btn--forget-pass" (click)="switchTab(4)">فراموشی رمز عبور</button>
              <button type="button" class="btn btn--onetime-pass" (click)="switchTab(1)">ورود با رمز یک‌بار مصرف</button>
            </div>
            <div class="auth-actions">
              <button type="submit" class="btn btn--default btn--next-step" [disabled]="isVerifying || u?.username?.errors?.required">
                {{isVerifying?'':'ورود'}}
                  <svg class="circular center-horizontal" *ngIf="isVerifying">
                    <circle class="path" cx="15" cy="15" r="10" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10"></circle>
                  </svg>
              </button>
            </div>
          </form>
        </div>
        <!-- Tabs Form Authenticate User With Mobile Password -->

        <!-- Tabs Form Change Password With Mobile -->
        <div class="auth-change-pass" *ngIf="!captchaNeeded && steplogin == 4">
          <div class="auth__heading">
            <h4>تغییر رمز عبور</h4>
            <h3>برای تغییر رمز عبور شماره موبایل خود را وارد کنید</h3>
          </div>
          <form class="auth-form" [formGroup]="mobileLoginForm">
            <div class="input-group" [ngClass]="{'input-group--error': mobileLoginForm.invalid && (mobileLoginForm.controls.mobileNumber.dirty)}">
              <label for="InputNumberPhone">شماره موبایل</label>
              <input type="tel" class="input" placeholder="شماره موبایل خود را وارد کنید" maxlength="11" autocomplete="off" id="InputNumberPhone" formControlName="mobileNumber" name="mobileNumber"  autoFocus/>
              <span class="input-group-validation">شماره موبایل معتبر نیست</span>
            </div>
            <div class="auth-actions">
              <button type="submit" class="btn btn--default btn--next-step" (click)="forgetPassword()" [disabled]="mobileLoginForm.invalid || waitForSendSms || isSaving">
                {{waitForSendSms?'':'تایید و دریافت کد'}}
                <svg class="circular center-horizontal" *ngIf="waitForSendSms || isSaving">
                  <circle class="path" cx="15" cy="15" r="10" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10"></circle>
                </svg>
            </button>
            </div>
          </form>
        </div>
        <!-- Tabs Form Change Password With Mobile -->

        <!-- Tabs Form Form Set Password User -->
        <div class="auth-set-pass" *ngIf="steplogin == 5">
          <div class="auth__heading" *ngIf="!isFromForgetPassword">
            <h4>تعیین رمز عبور</h4>
            <h3>برای حساب کاربری خود رمز عبور تعیین کنید، تا مجددا نیازی به کد یکبار مصرف نداشته باشید.</h3>
          </div>
          <h4 *ngIf="isFromForgetPassword">رمز عبور جدید مورد نظر خود را وارد کنید</h4>
          <form class="auth-form" (ngSubmit)="formSetPasswordUser()" [formGroup]="formPassword">
            <div class="input-group" [ngClass]="{'input-group--error': submitted && f?.setPassword?.errors}">
              <label for="InputPasswordUser">رمز عبور</label>
              <input [type]="(setPasswords === false) ? 'password' : 'text'" class="input" formControlName="setPassword" autocomplete="new-password" placeholder="رمز عبور خود را وارد کنید" maxlength="20" minlength="6" id="InputPasswordUser" />
              <i [ngClass]="setPasswords ? 'icon-visible' : 'icon-unvisible' " (click)="toggleShowPassword('setPasswords')"></i>
              <span class="input-group-validation" *ngIf="f?.setPassword?.errors && f?.setPassword?.errors?.minlength">رمز عبور باید حداقل دارای 6 کاراکتر باشد.</span>
              <!-- <span class="input-group-validation" *ngIf="submitted && f?.setPassword?.errors && f?.setPassword?.errors?.required">فیلد اجباری</span> -->
            </div>
            <div class="auth-actions">
              <button type="submit" class="btn btn--default btn--next-step" [disabled]="isVerifying || f?.setPassword?.errors?.required">
                {{isVerifying?'':'ثبت نام'}}
                <svg class="circular center-horizontal" *ngIf="isVerifying">
                  <circle class="path" cx="15" cy="15" r="10" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10"></circle>
                </svg>
              </button>
            </div>
          </form>
        </div>
        <!-- Tabs Form Form Set Password User  -->
    </div>
  </div>
</div>
