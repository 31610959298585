import { Action, createReducer, on } from "@ngrx/store";
import { userActions } from "./user.action";
import { IUserStateData } from "./user.model";
import { IStoreProduct } from '../../../../mocks/store/store.interface'

export const InitialUserState: IStoreProduct<IUserStateData> = {
  isLoading: false,
  stateData: {
    mobile: null,
    emailAddress: null,
    isAuthenticated: null,
    isBrowser: null
  },
  error: null
}

export const userReducer = createReducer(
  InitialUserState,
  on(userActions.createInitial, (state, { mobile, emailAddress, isAuthenticated, isBrowser }) => ({ // the same as update
    ...state,
    stateData: {
      mobile: mobile,
      emailAddress: emailAddress,
      isAuthenticated: isAuthenticated,
      isBrowser: isBrowser
    }
  })),
  on(userActions.updateAll, (state, { mobile, emailAddress, isAuthenticated, isBrowser }) => ({ // the same as update
    ...state,
    stateData: {
      mobile: mobile,
      emailAddress: emailAddress,
      isAuthenticated: isAuthenticated,
      isBrowser: isBrowser
    }
  })),
  on(userActions.updateAuthenticated, (state, {isAuthenticated}) => {
    let stateDataTemp = { ...state.stateData };
    stateDataTemp.isAuthenticated = isAuthenticated
    return {
      ...state,
      stateData: stateDataTemp
    }
  }),
  on(userActions.updateEmailAddress, (state, { emailAddress }) => { // the same as update
    let stateDataTemp = { ...state.stateData };
    stateDataTemp.emailAddress = emailAddress
    return {
      ...state,
      stateData: stateDataTemp
    }
  }),
  on(userActions.updateMobile, (state, { mobile }) => { // the same as update
    let stateDataTemp = { ...state.stateData };
    stateDataTemp.mobile = mobile
    return {
      ...state,
      stateData: stateDataTemp
    }
  }),
)

export function allUserReducer(state: IStoreProduct<IUserStateData> | undefined, action: Action): any {
  return userReducer(state, action);
}
