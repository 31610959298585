import { Injectable, Injector } from '@angular/core';

import { AppSessionService } from './app-session.service';
import { HUserprofileService } from '../../../../shared/signalGo/services/huserprofileService';
import { PurchaseType } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.DataTypes/PurchaseType';
import { PlacescityService } from '../../../../shared/signalGo/services/placescityService';
import { PlacescountryService } from '../../../../shared/signalGo/services/placescountryService';
import { FilterBaseRequestContract } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.Contracts.Requests.Filters/FilterBaseRequestContract';
import { PurchasepaymentService } from '../../../../shared/signalGo/services/purchasepaymentService';
import { CityFilterRequestContract } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.Contracts.Requests.Filters.Places/CityFilterRequestContract';
import { AuthenticationService } from '../../../../shared/signalGo/services/authenticationService';
import { UserprofilepassengerService } from '../../../../shared/signalGo/services/userprofilepassengerService';
import { UserAuthenticateMobileRequestContract } from '../../../../shared/signalGo/services/Tkt724.Hub.IdentityServer.Contracts.Requests/UserAuthenticateMobileRequestContract';
import { DatatypeService } from '../../../../shared/signalGo/services/datatypeService';
import { PurchaseService } from '../../../../shared/signalGo/services/purchaseService';
import { FlightcancellationService } from '../../../../shared/signalGo/services/flightcancellationService';
import { TraincancellationService } from '../../../../shared/signalGo/services/traincancellationService';
import { type } from 'os';
import { Observable, Subject } from 'rxjs';
import { MessageContract } from '../../../../shared/signalGo/services/MahdBuraq.Utility.Models/MessageContract';
import { SetPasswordRequestContract } from '../../../../shared/signalGo/services/AuthenticationGenerationServices.Tkt724.Hub.Authentication.Contracts.Requests/SetPasswordRequestContract';
import { MessageType } from '../../../../shared/signalGo/services/AuthenticationGenerationServices.SMSGenerationServices.Tkt724.Hub.SMS.Common/MessageType';

@Injectable()
export class PublicAppSignalService {

    constructor(injector: Injector) {

        this.appSessionService = injector.get(AppSessionService);
        // this.transactionService = injector.get(TransactionService);
        this.authenticationService = injector.get(AuthenticationService);
        this.userprofileService=injector.get(HUserprofileService);
        this.flightCancellationService = injector.get(FlightcancellationService);
        this.trainCancellationService = injector.get(TraincancellationService);
        this.passengerserviceService = injector.get(UserprofilepassengerService);
        this.paymentService =injector.get(PurchasepaymentService);
        this.placesCityService = injector.get(PlacescityService);
        this.placesCountryService =  injector.get(PlacescountryService);
        this.datatypeService = injector.get(DatatypeService);
        this.purchaseService = injector.get(PurchaseService);

    }


    purchaseService: PurchaseService;
    userprofileService:HUserprofileService
    // transactionService : TransactionService;
    authenticationService: AuthenticationService;
    appSessionService: AppSessionService;
    flightCancellationService : FlightcancellationService;
    trainCancellationService : TraincancellationService;
    passengerserviceService : UserprofilepassengerService;
    paymentService:PurchasepaymentService;
    placesCityService:PlacescityService;
    placesCountryService : PlacescountryService
    datatypeService:DatatypeService;

    Passengerlist =[];
    Passengerlistloading =false;
    passengerCount: number = 21; // 20 first index + 1
    loadPassengers(payload?: any){
        this.Passengerlistloading=true;
        if(this.appSessionService.isAuthenticated )//&& this.Passengerlist.length==0
            this.searchProfilePassenger(payload).subscribe((o: any) => {
                if (o.success) {
                this.Passengerlist = o.result.data;
                } else {
                //this.danger('خطای سرور', o.error.message,2000);
                }
                this.Passengerlistloading=false;
            });
    }

    public checkerror(obs) {
        //check some common error ,for example 23 1..
        //complete later
        return obs;
    }

    // Passengers Section Start

    // deletePassenger(id, u_id){
    //     return this.passengerserviceService.deletePassenger(id , u_id).pipe(obs=>this.checkerror(obs));
    // }
    registerUserWithEmail() {
        // this.authenticationService.registerUserWithEmail({
        //     emailAddress: 'ghamshekan@gmail.com',
        //     firstName: 'javad',
        //     lastName: 'ghamshekan',
        //     mobile: '09153200767',
        //     password: '123456'
        // }
        // )
        //     .pipe(obs => this.checkerror(obs))
        //     .subscribe(o => {
        //         console.log(o);
        //     });
    }
    getCaptcha() {
        this.authenticationService.getCaptcha()
            .pipe(obs => this.checkerror(obs))
            .subscribe(o => {
                //console.log(o);
            });
    }
    loginSms(mobile,type:MessageType) {
        let mobileLoginRequest = new UserAuthenticateMobileRequestContract();
        mobileLoginRequest.mobile = mobile;
        mobileLoginRequest.countryDialingCode = '+98';
        mobileLoginRequest.messageType = type;

      return  this.authenticationService.authenticateUserWithMobile(mobileLoginRequest)
            .pipe(obs => this.checkerror(obs));

    }

    // Set Password
    setPasswordUser(password):Observable<MessageContract>{
      let passwordContract = new SetPasswordRequestContract();
      passwordContract.password = password;
      return this.authenticationService.setPasswordAsync(passwordContract);
    }
    // UserName And Password
    authenticateUserWithUsernameAndPassword(username, password):Observable<MessageContract>{
      return this.authenticationService.authenticateWithUsernamePassword({username:username,password:password})
    }

    getProfile() {
        return this.userprofileService.getSimpleProfile();
    }

    getProfileContactInfo() {
        return this.userprofileService.getCommunicationProfile();
    }

    saveCommunicationProfile(data) {
        return this.userprofileService.saveCommunicationProfile(data);
    }

    // saveProfilePassword(data) {
    //     return this.userprofileService.saveProfilePassword(data);
    // }

    setProfile(data) {
        return this.userprofileService.saveSimpleProfile(data);
    }

    // getProfileDetial() {
    //     return this.userprofileService.getOtherProfileDetail();
    // }

    setProfileDetail(data?: any) {
        let c = data ? data : undefined;
        return this.userprofileService.saveOtherDetailProfile(c);
    }

    deleteProfilePassenger(id) {
        return this.passengerserviceService.deletePassenger(id);
    }

    getProfilePassenger(p) {
        return this.passengerserviceService.getPassenger(p);
    }

    addProfilePassenger(p) {
        return this.passengerserviceService.addPassenger(p);
    }

    addEntryPassengers(data){
        return this.passengerserviceService.addPassengers(data)
    }

    // updateProfilePassenger(body: ProfilePassengerContract | undefined): Observable<Response> {
    updateProfilePassenger(p) {
        return this.passengerserviceService.updatePassenger(p);
    }
    searchProfilePassenger(p) {
        return this.passengerserviceService.filterPassengers(p);
    }
    addCredit(amount) {
        return this.paymentService.addCredit({amount:amount});
    }
    getInvoice(guid) {
        return this.paymentService.getInvoice({invoiceKey:guid});
    }
    //  this.authserv.registerUserWithMobile({
    //         email : 'ghamshekan@gmail.com',
    //         firstName: 'javad',
    //         lastName : 'ghamshekan',
    //         mobile : '09153200767',
    //         password : '123456'
    // }).subscribe(o=>{
    //                                 console.log(o);
    //  });
    // this.authserv.verifyMobileRegisteration('97126').subscribe(o=>{
    //     console.log(o);
    // });

    // Passengers Section End
    getEnumDataTypes(type) {
       // return this.datatypeService.getEnumDataTypes(type);
    }
    getDataTypes(type) {
        return this.datatypeService.getDataTypes({name:type});
    }
    getCountries(data?: any) {
        let c = data ? data : {};
        let filter =new FilterBaseRequestContract();
        return this.placesCountryService.filterCountries(filter);
    }
    countries=[];
    loadCountries(){
        if(!this.countries.length)
        this.getCountries().subscribe(r => {
            if (r.success) {

              this.countries = r.result.data;
              this.countries = [];
              r.result.data.forEach(c => {
                if (c.dialingCode) {
                  this.countries.push(c);
                }
              })
            }
          });
    }
    getCities(c_id) {
        let filter =new CityFilterRequestContract();
        filter.countryId =c_id;
        return this.placesCityService.filterCities(filter);
    }
    purchaseFromCreditByInvoiceKey(guid){
        // return this.purchaseService.purchaseFromCreditByInvoiceKey({recordKey:guid,type:PurchaseType.Flight});
    }
    purchaseFromCreditByBasketId(data){
        return this.purchaseService.purchaseFromCreditByBasketId(data)
    }
}
