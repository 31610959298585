import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) { }

    @Output()
    public clickOutside = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement:any,$event) {
        let clickedInside = this._elementRef.nativeElement.contains(targetElement);
        // if (!clickedInside && targetElement.attributes.length)
        //     clickedInside = targetElement.attributes[0].name == "data-mds-persian-datetimepicker";
        if (!clickedInside)
            this.clickOutside.emit(null);
    }
}
