import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppInitCallGuard } from '../../../app/auth-routing';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      {
        path: 'passengers',
        loadChildren: () => import('./passengers/passengers.module').then(m => m.PassengersModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
      {
        path: 'wallet/payment-response/Mobile',
        loadChildren: () => import('./payment-response/payment-response.module').then(m => m.PaymentResponseModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
      {
        path: 'preview',
        loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
      {
        path: 'preview:type',
        loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
      {
        path: 'wallet',
        loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
      {
        path: 'wallet/payment-response/:type',
        loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletModule),
        canActivate: [
          AppInitCallGuard
        ]
      },

      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
        canActivate: [
          AppInitCallGuard
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
