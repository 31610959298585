import { Component, OnInit } from '@angular/core';
//import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-pending',
  templateUrl: './pending-pages-component.html',
  styleUrls: ['./pending-pages-component.scss']
})
export class PendingPagesComponent implements OnInit {

  constructor() { }


  // lottieOptionsTkt724: AnimationOptions = {
  //   path: '../../assets2/json/lottieTkt724Logo.json',
  //   loop: true,
  // };
  ngOnInit(): void {
  }

}
