import { AlertType } from '../alert/alert.enum';

export class Alert {
  id?: string;
  type?: AlertType;
  message?: string;
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
  fade?: boolean;
  timeOut? :number;

  constructor(init?:Partial<Alert>) {
      Object.assign(this, init);
  }
}
export class AlertOptions {
  id?: string;
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
  timeOut?:number;
}

export interface IAlertConfig {
  alertType: AlertType,
  message: string,
  options?: AlertOptions
}