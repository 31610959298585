import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotfoundComponent} from './layout/notfound/notfound.component';
import {RouterComponent} from './layout/notfound/RouterComponent';
import {AppInitCallGuard} from '../app/auth-routing';
import {CustomPreloadingStrategyService} from './core/services/app/preload-strategies/preload-strategies.service';

const routes: Routes = [
  // { path: 'searchBus/:fromto', loadChildren: () => import('src/app/Bus/bus-main.module').then(mod => mod.BusMainModule), data: { isInside: true } },
  {path: '404', component: NotfoundComponent},
  {path: '**', component: RouterComponent, canActivate: [AppInitCallGuard]},//redirectTo: '/404'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking', // 'disabled' | 'enabledBlocking' | 'enabledNonBlocking'
    preloadingStrategy: CustomPreloadingStrategyService
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
