import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ld'
})
export class LocalizeDigitPipe implements PipeTransform {

  ld(str: string | number): string {
      if (str === null || str === '' || str === undefined)
          return '';
      const ide = { '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9' };
      const idp = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

      return str.toString().replace(/[0-9]/g, function (w) {
          return idp[+w];
      });


     //** */  if (abp.localization.currentLanguage.name == 'fa')
          return str.toString().replace(/[0-9]/g, function (w) {
              return idp[+w]
          });
      return str.toString().replace(/[^0-9.]/g, function (w) {
          return ide[w] || w;
      });
  }
  transform(key: string | number, ...args: any[]): string {
      return this.ld(key);
  }
}
