<main >
    <section class="profile">
        <div class="row-master profile-master container">
            <aside class="row-master_sidbar">
                <div class="fild-sidbar profile-info">
                    <div class="user-info">
                        <figure class="user-info_container">
                            <img src="https://dl.tkt724.com/images/hero/background-profile.jpg" alt="#" width="243" height="92" />
                            <figcaption class="d-flex flex-direction justify-content-center align-items-center">
                                <div class="icons">
                                    <div class="icons-circle d-flex align-items-center justify-content-center">
                                        <i class="icon-user"></i>
                                    </div>
                                    <div class="add-avatar d-flex align-items-center justify-content-center">
                                        <i class="icon-camera"></i>
                                    </div>
                                </div>
                                <strong class="fw_b text-center">{{userInfo}}</strong>
                                <span class="text-center dir-ltr">{{userMobile}}</span>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="general-info d-flex justify-content-between align-items-start">
                        <div class="item">
                            <span class="caption fz-normal">مقدار اعتبار</span>
                            <span class="value fz-normal fw_b text-center">{{userCredit |ld |tom |c3}} تومان </span>
                        </div>
                        <div class="item">
                            <span class="caption fz-normal">رتبه</span>
                            <span class="value fz-normal fw_b">-</span>
                        </div>
                        <div class="item">
                            <span class="caption fz-normal">سفرهای من</span>
                            <span class="value fz-normal fw_b">-</span>
                            <!-- {{successedOrdersCount}} -->
                        </div>
                    </div>
                </div>
                <nav class="fild-sidbar profile-navigation">
                    <ul class="profile-navigation-list">
                        <li class="item" routerLinkActive="is-active">
                            <a routerLink="/profile/preview" id="preview">
                                <i class="icon-user"></i>
                                <span>اطلاعات کاربری</span>
                            </a>
                        </li>
                        <li class="item" routerLinkActive="is-active">
                            <a routerLink="/profile/passengers">
                                <i class="icon-user-info"></i>
                                <span>لیست مسافران</span>
                            </a>
                        </li>
                        <li class="item" routerLinkActive="is-active">
                            <a routerLink="/profile/orders">
                                <i class="icon-paper"></i>
                                <span>سوابق خرید و استرداد</span>
                            </a>
                        </li>
                        <li class="item">
                            <a (click)="comingsoon()">
                                <i class="icon-offer"></i>
                                <span>کد تخفیف های من</span>
                            </a>
                        </li>
                        <li class="item" routerLinkActive="is-active">
                            <a routerLink="/profile/wallet">
                                <i class="icon-wallet"></i>
                                <span>کیف پول</span>
                            </a>
                        </li>
                        <li class="item">
                            <a routerLink="/profile/transactions">
                                <i class="icon-final-payment"></i>
                                <span>تراکنش‌ها و اعتبارات</span>
                            </a>
                        </li>
                        <li class="item">
                            <a (click)="comingsoon()">
                                <i class="icon-setting"></i>
                                <span>تنظیمات پروفایل</span>
                            </a>
                        </li>
                        <li class="item">
                            <a (click)="logout()">
                                <i class="icon-exit"></i>
                                <span>خروج از حساب کاربری</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </aside>
            <div class="column__content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </section>
</main>
