import {  Directive, ElementRef, Input, Output ,EventEmitter} from '@angular/core';


@Directive({
    selector: '[selectOnfocus]',
    host: {
        '(focus)': 'SelectAll($event)',
    },
    
})
export class SelectOnfocusDirective  {

    constructor(
        private _element: ElementRef
    ) {
    }

    SelectAll(ev:any): void {
        this._element.nativeElement.select();
        // ev.target.select();
    }
}

@Directive({
    selector: '[closeOnEscape]',
    host: {
        '(keydown)': 'keyDown($event)',
    }
})
export class CloseOnEscapeDirective  {

    constructor(      private _element: ElementRef ) {
        this.onEscape =new EventEmitter<any>();
    }
// callbackclose=()=>{};
// @Input() set closeOnEscape(cb){
//     console.log(cb);
//     if(cb)
//         this.callbackclose=  cb;
// };

@Output()  onEscape =new EventEmitter<any>();
lasttarget=null;
firsttarget =true;
keyDown(event:any){
    //console.log(event);
    if (event.keyCode == 27) {
        if(event.target != this.lasttarget && !this.firsttarget) {
            //console.log('escape skip');
            this.lasttarget =event.target;
             return;
        }
        // console.log('escape');
    this.onEscape.emit(null);
    }
    else
        this.lasttarget =null;
    this.firsttarget=false;
}
}
