// signalGo
import {
  HotelPurchaseOrderDetailResponseContract
} from "../../../../../../shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Responses/HotelPurchaseOrderDetailResponseContract";

export class HotelPurchaseOrderDetailContract extends HotelPurchaseOrderDetailResponseContract {
  Name: string | null = null;
  Star: number | null = null;
  Address: string | null = null;

}
