import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Injector } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { CacheService } from './core/services/app/clearCache/clear-cache.service';

// #docregion sw-update
@Injectable({ providedIn: 'root' })
export class LogUpdateService extends CacheService{
  constructor(injector: Injector, updates: SwUpdate) {
    super();
    if (
      environment.production &&
      isPlatformBrowser(injector.get(PLATFORM_ID))
    ) {
      updates.versionUpdates.subscribe((evt) => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(
              `New app version ready for use: ${evt.latestVersion.hash}`
            );
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(
              `Failed to install app version '${evt.version.hash}': ${evt.error}`
            );
            break;
        }
      });
    }
  }
}
// #enddocregion sw-update
