<!-- Footer Beginning -->
<footer>
  <!-- Footer Wrapper Beginning -->
  <div class="footer-wrapper container">
    <!-- Footer Wrapper Details Beginning -->
    <div class="footer-wrapper__top">
      <div class="footer-wrapper-content">
        <!-- Footer Wrapper About Beginning -->
        <div class="footer-about">
          <div class="footer-about__context">
            <p>
              ما در تیکت 724 با هدف ارائه عالی‌ترین خدمات سفر، در خدمت شما عزیزان هستیم تا تجربه ناب سفری امن را به شما هدیه دهیم. در دنیای امروز که سفری آسان و سریع در مسیرهای هوایی داخلی و خارجی دغدغه همه‌ماست، ما توانسته‌ایم با ارائه سیستم یکپارچه خرید و استرداد آنلاین بلیط هواپیما، روند خرید بلیط را تا حد امکان برای شما عزیزان ساده کنیم.
              مسافر سرمایه ماست.
            </p>
          </div>
        </div>
        <!-- Footer Wrapper About End -->
        <!-- Footer Wrapper Contact Beginning -->
        <div class="footer-contact">
          <span>
            تلفن پشتیبانی:
            <a href="tel:09398633170">09398633170</a>
          </span>
          <!--
          <address>آدرس تهران : استان: تهران، شهرستان : تهران، بخش : مرکزی، شهر: تهران، محله: بیمه، کوچه سهیلی، بلوار شهید عبدالرحمان نفیسی ، پلاک: -91.0، آریو اکباتان، طبقه: 5، واحد: 501</address>
          <address>آدرس یزد : بلوار تهران، نبش کوچه رهاوی، پلاک 3، طبقه همکف</address>
          -->
        </div>
        <!-- Footer Wrapper Contact End -->
      </div>
      <div class="footer-wrapper-business">
        <ul class="footer-list-namad">
          <li class="item">
              <a href="https://caa.gov.ir/paxrights" target="_blank" rel="noopener noreferrer">
                  <img src="https://dl.tkt724.com/images/namad-1.png"  loading="lazy" alt="#" width="70" height="71" />
              </a>
          </li>
          <li class="item">
              <a href="https://caa.gov.ir/" target="_blank" rel="noopener noreferrer">
                  <img src="https://dl.tkt724.com/images/namad-2.png"  loading="lazy" alt="#" width="70" height="71" />
              </a>
          </li>
          <li class="item">
              <a href="http://www.aira.ir/" target="_blank" rel="noopener noreferrer">
                  <img src="https://dl.tkt724.com/images/namad-3.png"  loading="lazy" alt="#" width="70" height="71" />
              </a>
          </li>
          <!--<li class="item" *ngIf="tkt724com">
              <div>
                  <img referrerpolicy='origin' width="70" height="71" loading="lazy" id = 'nbqejzpenbqewlaofukzrgvj' style = 'cursor:pointer' onclick = 'window.open("https://logo.samandehi.ir/Verify.aspx?id=272463&p=uiwkjyoeuiwkaodsgvkaxlao", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt = 'logo-samandehi' src = 'https://dl.tkt724.com/images/namad-5.png' />
              </div>
          </li>
          <li class="item" *ngIf="tkt724ir">
              <div >
                  <img referrerpolicy='origin' width="70" height="71"  loading="lazy" id = 'nbqejzpenbqewlaooeukfukz' style = 'cursor:pointer' onclick = 'window.open("https://logo.samandehi.ir/Verify.aspx?id=272486&p=uiwkjyoeuiwkaodsmcsigvka", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt = 'logo-samandehi' src = 'https://dl.tkt724.com/images/namad-5.png' />
              </div>
          </li>
          <li class="item" *ngIf="tkt724org">
              <div >
                  <img referrerpolicy='origin' width="70" height="71" loading="lazy" id = 'nbqejzpenbqefukzrgvjrgvj' style = 'cursor:pointer' onclick = 'window.open("https://logo.samandehi.ir/Verify.aspx?id=272633&p=uiwkjyoeuiwkgvkaxlaoxlao", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt = 'logo-samandehi' src = 'https://dl.tkt724.com/images/namad-5.png' />
              </div>
          </li>

          <li class="item" *ngIf="matacoir">
              <div >
                <img referrerpolicy="origin"  width="70" height="71" loading="lazy" id="rgvjwlaosizprgvjapfujzpe" style="cursor:pointer" onclick='window.open("https://logo.samandehi.ir/Verify.aspx?id=349357&amp;p=xlaoaodspfvlxlaodshwjyoe&quot;, &quot;Popup&quot;,&quot;toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt='logo-samandehi' src = 'https://dl.tkt724.com/images/namad-5.png' />
              </div>
          </li>-->

          <li class="item" *ngIf="matacoir">
             <a href="https://farasa.cao.ir/sysworkflow/fa/modern/3810212626028ab03488017019616799/6464336316028ab04e3c618028352200.php" target="_blank" >
              <img src="https://dl.tkt724.com/images/namad-8.png"  alt="سامانه‌ي حقوق مسافر" title="حقوق مسافر" style="width: 70px; height: 70px;"></a>
          </li>
           <!--<li class="item" *ngIf="matacoir">
               <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=353606&amp;Code=197ZRX3WvYwViZ1rwq3x">
                   <img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=353606&amp;Code=197ZRX3WvYwViZ1rwq3x" alt="ای‌نماد" style="cursor:pointer" id="197ZRX3WvYwViZ1rwq3x"></a>
          </li>
          <li class="item" *ngIf="tkt724ir">
              <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=228293&amp;Code=zRIsQ6oKvcizQvkPFRKe">
                  <img referrerpolicy="origin"  loading="lazy" src="https://Trustseal.eNamad.ir/logo.aspx?id=228293&amp;Code=zRIsQ6oKvcizQvkPFRKe" alt="ای‌نماد"  width="70" height="71"
                   style="cursor:pointer" id="zRIsQ6oKvcizQvkPFRKe"></a>
          </li>
          <li class="item" *ngIf="tkt724com">
              <a  referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=228351&amp;Code=sPj7I71OjVn9DfMGDUK7">
                  <img referrerpolicy="origin" loading="lazy" src="https://Trustseal.eNamad.ir/logo.aspx?id=228351&amp;Code=sPj7I71OjVn9DfMGDUK7" alt="ای‌نماد"  width="70" height="71" style="cursor:pointer" id="sPj7I71OjVn9DfMGDUK7">
              </a>
          </li>
          <li class="item" *ngIf="tkt724org">
              <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=228500&amp;Code=Z9JoASRXV56h0ckT9Xn5">
                  <img referrerpolicy="origin" loading="lazy" src="https://Trustseal.eNamad.ir/logo.aspx?id=228500&amp;Code=Z9JoASRXV56h0ckT9Xn5" alt="ای‌نماد" width="70" height="71"style="cursor:pointer" id="Z9JoASRXV56h0ckT9Xn5"></a>
          </li>-->
          <li class="item">
            <a href="/profile/orders" title="سامانه کنسلی آنلاین" target="_blank">
                <img src="https://dl.tkt724.com/images/namad-6.png" loading="lazy" alt="#" width="70" height="71" />
            </a>
          </li>
          <li class="item">
              <a href="https://tollpayment.sadadpsp.ir" target="_blank" ><img src="https://dl.tkt724.com/images/namad-9.png" alt="سامانه پرداخت عوارض خروج از کشور" title="سامانه پرداخت عوارض خروج از کشور" style="width: 70px; height: 71px;"></a>
          </li>
         <!-- <li class="item" *ngIf="tkt724ir">
              <div onclick="window.open('https://ecunion.ir/verify/tkt724.ir?token=234120661f7e80a39eee', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')" title="عضو اتحادیه" target="_blank">
                  <img src="https://dl.tkt724.com/images/namad-7.png" loading="lazy" alt="#" width="70" height="71" />
              </div>
          </li>
          <li class="item" *ngIf="tkt724com">
              <div onclick="window.open('https://ecunion.ir/verify/tkt724.com?token=5613624625d2f6a5750d', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')" title="عضو اتحادیه" target="_blank">
                  <img src="https://dl.tkt724.com/images/namad-7.png" loading="lazy" alt="#" width="70" height="71" />
              </div>
          </li>
          <li class="item" *ngIf="tkt724org">
              <div onclick="window.open('https://ecunion.ir/verify/tkt724.org?token=7496002626cf07e25439', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')" title="عضو اتحادیه" target="_blank">
                  <img src="https://dl.tkt724.com/images/namad-7.png" loading="lazy" alt="#" width="70" height="71" />
              </div>
          </li>-->
          <li class="item">
            <div>
              <img src="https://dl.tkt724.com/images/iata-logo.svg" alt="iata" width="52" height="52" loading="lazy" />
            </div>
          </li>
        </ul>
        <!--<div class="footer-list-social">
            <a href="https://www.instagram.com/tkt724/" aria-label="instagram" target="_blank" rel="noopener noreferrer" class="item">
                <i class="icon-social-instagram"></i>
            </a>
        </div>-->
      </div>
    </div>
    <!-- Footer Wrapper Details End -->
    <!-- Footer Wrapper CopyRight Beginning -->
    <div class="footer-wrapper__bottom">
      <div class="footer-text-fa">
        کلیه حقوق این سایت محفوظ و متعلق به شرکت سرزمین میوه های شادمان آروند است. استفاده از مطالب سایت برای مقاصد غیر تجاری و با ذکر منبع بلامانع است.
      </div>
    </div>
    <!-- Footer Wrapper CopyRight End -->
  </div>
  <!-- Footer Wrapper End -->
</footer>
<!-- Footer End -->
