import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  sentry: {
    dsn: 'https://e1a55280692a4930961830703061b695@sentry.tkt724.com/2',
    tracePropagationTargets: [
      /^https:\/\/(authentication|sale|flight|hotel|train)-api-v1.tkt724\.com/,
      /^https:\/\/(payment|l|cdn|umag).tkt724\.com/,
    ]
  },
  environmentServerUrl: 'domain',
  agentHostRegex: 'agent(.*).tkt724.com',
  preconnect:['https://tkt724.com/', 'https://dl.tkt724.com/','https://cdn.tkt724.com/','https://api.tkt724.com/'],
  defaultRobots : "noindex, nofollow",
  webpackPublicPath : "",
  onlyUseDefaultRobots:true
};
