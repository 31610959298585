<app-user-notify *ngIf="notes.length > 0" [notes]="notes"></app-user-notify>


<div class="modal-dialog return_refund" [ngClass]="{'active': activeRefund}">
  <div class="modal-dialog_content" style="--max-w:500px">
    <div class="modal-dialog_content_body">
      <div class="modal-dialog_content_body">
        <div class="modal-alert return_refund d-flex flex-direction align-items-center">
          <i class="icon-refund text-primary"></i>
          <strong class="fw_b">پیگیری و استرداد</strong>
          <!-- <span class="fz-normal text-center">لطفا جهت استرداد بلیط با بخش پشتیبانی تیکت 724 به شماره <a href="tel:09398633170"
              class="fw_b fz-big dir-ltr">09398633170</a> تماس بگیرید</span> -->
              <span class="fz-normal text-center">لطفا جهت استرداد بلیط با بخش پشتیبانی تیکت 724 تماس بگیرید</span>
          <button class="btn btn-modal-alert" (click)="activeRefund = false"> بستن پیغام </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-login *ngIf="sessionService.login_registerIsOpen" closeOnEscape (onEscape)="sessionService.login_registerIsOpen = false"></app-login>

<header class="header" [ngClass]="{'header--inside': isInside}">
  <div class="header__container container">
    <div class="header__right">
      <!-- Logo -->
      <a routerLink="/" title="تیکت 724" class="header__right__logo">
        <img  [src]="'../assets2/images/' + (isInside ? 'logo-tkt724-white.svg' : 'logo-tkt724.svg')" alt="تیکت 724"
          width="141" height="40" loading="lazy" />

      </a>
      <!-- Logo -->
      <!-- Navigation -->
      <nav *ngIf="hasMenu" class="header__right__navigation">
        <ul class="navigation-list">
          <li class="navigation-list__item"><a routerLink="/">پرواز</a></li>
          <!-- <li class="navigation-list__item"><a routerLink="/train">قطار</a></li>
          <li class="navigation-list__item"><a routerLink="/hotel">هتل </a></li> -->
        </ul>
      </nav>
      <!-- Navigation -->
    </div>
    <div class="header__left" (clickOutside)="isAuthenticated ? (UserDropdown = false) : (UserDropdown = false)">
      <!-- Tel Support -->
      <!-- <div class="header__left__item header__left__item--tel">
        <a href="/contactus">
          <span> تماس با ما</span>
          <span>09398633170</span>
        </a>
      </div> -->
      <!-- Tel Support -->
      <!-- Follow Refund Ticket -->
      <div class="header__left__item header__left__item--follow-refund">
        <button type="button" (click)="navIfLogin('/profile/orders')">پیگیری خرید</button>
      </div>
      <!-- Follow Refund Ticket -->
      <!-- Auth User -->
      <ng-container *ngIf="!isBrowser || !isAppInit">
        <div class="header__left__item header__left__item--auth-user">
          <div class="header-auth-user__list" (click)="showLoginForm()">
            <span>....</span>
            <span>....</span>
          </div>
          <div class="header-auth-user__icon" (click)="showLoginForm()">
            <i class="icon-user"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isBrowser && isAppInit && !isAuthenticated">
        <div class="header__left__item header__left__item--auth-user">
          <div class="header-auth-user__list" (click)="showLoginForm()">
            <span>ورود</span>
            <span>ثبت نام</span>
          </div>
          <div class="header-auth-user__icon" (click)="showLoginForm()">
            <i class="icon-user"></i>
          </div>
        </div>
      </ng-container>
      <!-- Auth User -->
      <!-- Auth User Login -->
      <ng-container *ngIf="isBrowser && isAuthenticated">
        <div class="header__left__item header__left__item--auth-login">
          <div class="header-auth-user__list" (click)="UserDropdown=!UserDropdown">
            <i class="{{!UserDropdown?'icon-arrow-down-circle':'icon-arrow-up-circle'}}"></i>
            <span dir="ltr">{{userInfo}}</span>
          </div>
          <div class="header-auth-user__icon" (click)="UserDropdown=!UserDropdown">
            <i class="icon-user"></i>
          </div>
          <!-- List -->
          <div class="user-dropdown is-active" *ngIf="UserDropdown">
            <div class="user-dropdown__right">
              <div class="user-info">
                <figure class="user-info_container">
                  <img src="https://dl.tkt724.com/images/hero/background-profile.jpg" alt="#" width="243" height="92" />
                  <figcaption>
                    <div class="icons">
                      <div class="icons-circle">
                        <i class="icon-user"></i>
                      </div>
                    </div>
                    <strong dir="ltr">{{userInfo}}</strong>
                    <span dir="ltr">{{userMobile}}</span>
                  </figcaption>
                </figure>
              </div>
              <div class="general-info">
                <div class="item">
                  <span class="caption">مقدار اعتبار</span>
                  <span class="value text-center">{{userCredit |ld |tom |c3}} تومان </span>
                </div>
                <div class="item">
                  <span class="caption">رتبه</span>
                  <span class="value">-</span>
                </div>
                <div class="item">
                  <span class="caption">سفرهای من</span>
                  <span class="value">-</span>
                </div>
              </div>
              <!-- <a href="tel:09398633170" class="support-tel">تماس با پشتیبانی : 09398633170</a> -->
            </div>
            <div class="user-dropdown__left">
              <nav class="user-dropdown__left__navigation">
                <a (click)="UserDropdown=false;" routerLink="/profile/preview">
                  <i class="icon-user"></i>
                  <span>اطلاعات کاربری</span>
                </a>
                <a (click)="UserDropdown=false;" routerLink="/profile/passengers">
                  <i class="icon-user-info"></i>
                  <span>لیست مسافران</span>
                </a>
                <a (click)="UserDropdown=false;" routerLink="/profile/orders">
                  <i class="icon-paper"></i>
                  <span>سوابق خرید و استرداد</span>
                </a>
                <a (click)="UserDropdown=false;comingsoon();81480">
                  <i class="icon-offer"></i>
                  <span>کد تخفیف‌های من</span>
                </a>
                <a (click)="UserDropdown=false;" routerLink="/profile/wallet">
                  <i class="icon-wallet"></i>
                  <span>کیف پول</span>
                </a>
                <a (click)="UserDropdown=false;" routerLink="/profile/transactions">
                  <i class="icon-final-payment"></i>
                  <span>تراکنش‌ها و اعتبارات</span>
                </a>
                <a (click)="UserDropdown=false;comingsoon();">
                  <i class="icon-setting"></i>
                  <span>تنظیمات پروفایل</span>
                </a>
                <a (click)="UserDropdown=false;logout();">
                  <i class="icon-exit"></i>
                  <span>خروج از حساب کاربری</span>
                </a>
              </nav>
            </div>
          </div>
          <!-- List -->
        </div>
      </ng-container>
      <!-- Auth User Login -->
    </div>
  </div>
</header>

<ng-content></ng-content>
