import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdsAngularPersianDateTimePickerCoreComponent, NgCellTemplateDirective } from './core/mds-angular-persian-date-time-picker-core.component';
import { MdsAngularPersianDateTimePickerComponent, NgPdCellTemplateDirective } from './mds-angular-persian-date-time-picker.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PersianNumberPipe } from './pipes/persian-number.pipe';
import { ThreeColDateTimeComponent } from './three-col-date-time/three-col-date-time.component';
import { UtilsModule } from 'src/shared/utils/utils.module';
import { Tkt724DatepickerComponent } from './tkt724-datepicker.component';

@NgModule({
  imports: [
    CommonModule, UtilsModule,
    FormsModule, ReactiveFormsModule
  ],
  declarations: [
    MdsAngularPersianDateTimePickerCoreComponent,
    NgCellTemplateDirective,
    NgPdCellTemplateDirective,
    Tkt724DatepickerComponent,
    ThreeColDateTimeComponent,
    MdsAngularPersianDateTimePickerComponent,
    SafeHtmlPipe,
    PersianNumberPipe
  ],
  exports: [
    MdsAngularPersianDateTimePickerComponent,
    NgPdCellTemplateDirective,
    Tkt724DatepickerComponent,
    ThreeColDateTimeComponent
  ]
})
export class MdsAngularPersianDateTimePickerModule { }
