import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
// import * as Sentry from "@sentry/angular-ivy";
import { FailedReasonType } from "../../../../shared/signalGo/services/MahdBuraq.Utility.Models/FailedReasonType";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse && response.body && response.body.FailedReason === FailedReasonType.TooManyRequest) {
          this._router.navigateByUrl('/too-many-requests');
        }

        if (
          response instanceof HttpResponse && response.body &&
          (response.body.FailedReason > 0 || (response.body.success && response.body.success === false))
        ) {

          const headers: Record<string, string> = {};
          request.headers.keys().forEach(key => {
            headers[key] = request.headers.get(key) || '';
          });

          const cookieHeader: string | null = request.headers.get('Cookie');
          const cookies: { [key: string]: string } = {};

          if (cookieHeader) {
            cookieHeader.split(';').forEach(cookie => {
              const [key, value] = cookie.split('=');
              cookies[key.trim()] = decodeURIComponent(value);
            });
          }

          // Sentry.captureEvent({
          //   request: {
          //     data: request.body,
          //     url: request.url,
          //     method: request.method,
          //     headers: headers,
          //     cookies: cookies,
          //     query_string: request.params.toString()
          //   },
          //   message: `Api Error: failed reason ${response.body.FailedReason}`,
          //   level: 'error',
          //   extra: {
          //     response: response
          //   }
          // });
        }
      })
    )
  }
}
