import { NgModule } from '@angular/core';
import { ProfileRoutingModule } from './profile-routing.module'
import { UtilityModule } from "../../shared/utility.module";
import { CommonModule } from '@angular/common';
// Providers
import { AppInitCallGuard } from '../../../app/auth-routing';
import { ProfileComponent } from './profile.component';


@NgModule({
  imports: [
    UtilityModule,
    CommonModule,
    ProfileRoutingModule,
  ],
  declarations: [
    ProfileComponent
  ],
  exports: [],
  providers: [
    AppInitCallGuard
  ],
})
export class ProfileModule { }
