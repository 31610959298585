import { Component, Input,  OnInit } from '@angular/core';


@Component({
  selector: 'app-user-notify',
  templateUrl: './user-notify.component.html'
})
export class UserNotifyComponent implements OnInit {

  @Input() notes: any;
  
  constructor() { }

  close(note){
    this.notes[note].hidestart =true;
    setTimeout(() => {
      this.notes.splice(note,1);
    }, 500);
    
  }
  ngOnInit() {    

  }

}
