import { Component, Injector, OnInit, Input } from '@angular/core';
import { AppSessionService } from 'src/app/core/services/app/app-session.service';
import { AppComponentBase } from '../../app-component-base';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends AppComponentBase implements OnInit {

  @Input('hasMenu') hasMenu = false;
  @Input('isInside') isInside = false;

  UserDropdown: boolean = false;
  activeRefund: boolean = false;
  sessionService: any;
  constructor(
    injector: Injector,
    public appSessionService: AppSessionService
  ) {
    super(injector);
    this.sessionService = this.appSessionService

  }

  ngOnInit(): void {
  }
}
