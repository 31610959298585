import { HotelOrderHeader } from './../../mocks/hotel/class/hotel-purchase/hotel-order-header.class';
import { HotelOrderCheckListDetail } from './../../mocks/hotel/class/hotel-purchase/hotel-order-checklist-detail.class';
import { HotelOrderRoomPassengerContract } from './../../mocks/hotel/class/hotel-purchase/hotel-order-room-passenger.class';
import { HotelOrderCheckList } from './../../mocks/hotel/class/hotel-purchase/hotel-order-checklist.class';
import { HotelFacilityTransfer } from './../../mocks/hotel/class/hotel-purchase/hotel-facility.class';
import { HotelPurchaseOrderDetailContract } from './../../mocks/hotel/class/hotel-purchase/hotel-purchase-order-detail-contract.class';
import { HotelOrderContract } from './../../mocks/hotel/class/hotel-purchase/hotel-order-contract.class';
import { HotelTransferDataModel } from './../../mocks/hotel/class/hotel-purchase/hotel-transfer-data.class';
// Angular
import { Injectable } from '@angular/core';

// Types
import { TagContract, TagType } from "../../mocks/hotel/class/tag.contract.class";
import { RoomContract } from "../../mocks/hotel/class/room.contract.class";


// Shared
var _ = require('lodash');
import { passenger } from "./../../mocks/hotel/class/hotelClass/pasenger.class";
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../store/app/appstate.interface';
import { hotelPurchaseActions } from '../../store/hotel/detail/detail.action';

type checkProps = any


@Injectable({
  providedIn: 'root'
})
export class HotelPurchaseService {
  private _HotelTransferData: HotelTransferDataModel = new HotelTransferDataModel();
  public get HotelTransferData(): HotelTransferDataModel {
    return this._HotelTransferData;
  }
  public set HotelTransferData(value: HotelTransferDataModel) {
    this._HotelTransferData = value;
    this.store.dispatch(hotelPurchaseActions.hotelTransfer({
      hotelTransfer: value
    }))
  }

  private _HotelOrder: HotelOrderContract = new HotelOrderContract();
  public get HotelOrder(): HotelOrderContract {
    return this._HotelOrder;
  }
  public set HotelOrder(value: HotelOrderContract) {
    this._HotelOrder = value;
    this.store.dispatch(hotelPurchaseActions.hotelOrder({
      hotelOrder: this._HotelOrder
    }))
  }

  private _HotelPurchaseOrderDetail: HotelPurchaseOrderDetailContract = new HotelPurchaseOrderDetailContract();
  public get HotelPurchaseOrderDetail(): HotelPurchaseOrderDetailContract {
    return this._HotelPurchaseOrderDetail;
  }
  public set HotelPurchaseOrderDetail(value: HotelPurchaseOrderDetailContract) {
    this._HotelPurchaseOrderDetail = value;
    this.store.dispatch(hotelPurchaseActions.hotelPurchaseOrder({
      hotelPurchaseOrder: this._HotelPurchaseOrderDetail
    }))
  }

  private _HotelFacilityTransfer: HotelFacilityTransfer = new HotelFacilityTransfer();
  public get HotelFacilityTransfer(): HotelFacilityTransfer {
    return this._HotelFacilityTransfer;
  }
  public set HotelFacilityTransfer(value: HotelFacilityTransfer) {
    this._HotelFacilityTransfer = value;
    this.store.dispatch(hotelPurchaseActions.hotelFacility({
      hotelFacility: this._HotelFacilityTransfer
    }))
  }

  constructor(
    private store: Store<AppStateInterface>
  ) {
  }

  getCheckList(hoteOrderProp: HotelOrderContract = null): HotelOrderCheckList[] {
    if (!hoteOrderProp)
      hoteOrderProp = Object.assign({}, this._HotelOrder);

    let hoteOrder = Object.assign({}, hoteOrderProp);

    let check: HotelOrderCheckList[] = new Array<HotelOrderCheckList>();
    hoteOrder.Rooms?.forEach(room => {
      let res = {
        Title: room.Title,
        ShortTitle: room.ShortTitle,
        TotalCountRoom: room.RoomCount,
        TotalPriceRoom: room.TotalPrice,
        TotalExteraPersonAdultCount: this.getSum(room.RoomPassengers.map(dr => dr.ExteraPersonAdultCount ?? null)),
        TotalExteraPersonAdultPrice: this.getTotalExteraPersonAdultPrice(room.RoomPassengers),
        TotalExteraPersonChildCount: this.getSum(room.RoomPassengers.map(dr => dr.ExteraPersonChildCount ?? null)),
        TotalExteraPersonChildPrice: this.getTotalExteraPersonChildPrice(room.RoomPassengers),
      } as HotelOrderCheckList

      res.TotalPayablePrice = res.TotalPriceRoom + res.TotalExteraPersonAdultPrice + res.TotalExteraPersonChildPrice;

      check.push(res);
    });

    hoteOrder.TotalPayablePrice = 0;
    check.forEach(item => {
      hoteOrder.TotalPayablePrice += item.TotalPayablePrice;
    });
    this.HotelOrder = {...hoteOrder}
    return check;
  }

  getTotalExteraPersonAdultPrice(RoomPassengers: HotelOrderRoomPassengerContract[]) {
    let sum: number = null;
    RoomPassengers?.forEach(passenger => {
      if (passenger.ExteraPersonAdultPrice != null && passenger.ExteraPersonAdultCount > 0) {
        if (passenger.ExteraPersonAdultPrice) {
          sum += passenger.ExteraPersonAdultPrice * passenger.ExteraPersonAdultCount;
        }

      }
    });
    return sum;
  }

  getTotalExteraPersonChildPrice(RoomPassengers: HotelOrderRoomPassengerContract[]) {
    let sum: number = null;
    RoomPassengers?.forEach(passenger => {
      if (passenger.ExteraPersonChildCount != null && passenger.ExteraPersonChildCount > 0) {
        if (passenger.ExteraPersonChildPrice) {
          sum += passenger.ExteraPersonChildPrice * passenger.ExteraPersonChildCount;
        }
      }
    });
    return sum;
  }

  getSum(data: number[]): number {
    let sum: number = null;
    data.forEach(item => {
      if (item != null)
        sum += item;
    })
    return sum;
  }

  getCheckListDetail(hoteOrder: HotelOrderContract = null): HotelOrderCheckListDetail {
    if (!hoteOrder)
      hoteOrder = Object.assign({}, this.HotelOrder);

    let detail: HotelOrderCheckListDetail = new HotelOrderCheckListDetail();
    detail = {
      ChekInDate: hoteOrder.CheckInDate,
      CheckOutDate: hoteOrder.CheckOutDate,
      CheckOutTime: hoteOrder.CheckOutTime,
      ChekInTime: hoteOrder.CheckInTime,
      CheckInpPrsianDate: hoteOrder.CheckInPersianDate,
      CheckOutPersianDate: hoteOrder.CheckOutPersianDate,
      NightCout: hoteOrder.NightCount,
      TotalRoomCount: hoteOrder.TotalRoomCount
    } as HotelOrderCheckListDetail;
    this.HotelOrder = { ...hoteOrder };
    return detail;
  }

  getHeader(hoteOrder: HotelOrderContract = null): HotelOrderHeader {
    if (!hoteOrder)
      hoteOrder = Object.assign({}, this.HotelOrder);
    if (hoteOrder) {
      let hotel: HotelOrderHeader = new HotelOrderHeader();
      hotel.Title = hoteOrder.Name;
      hotel.TotalPayablePrice = hoteOrder.TotalPayablePrice;
      hotel.Image = hoteOrder.Image;
      hotel.Images = hoteOrder.Images;
      hotel.DefaultImage = hoteOrder.DefaultImage;
      hotel.Address = hoteOrder.Address;
      hotel.BodyTag = this.getBodyTags(hoteOrder.Tags);
      hotel.PriceTag = this.getPriceTag(hoteOrder.Tags);
      hotel.ImageTag = this.getPictureTag(hoteOrder.Tags);
      hotel.StartPrice = hoteOrder.MinPrice;
      hotel.StarCount = hoteOrder.StartCount;
      hotel.NightCount = hoteOrder.NightCount;
      hotel.Checkin = hoteOrder.CheckInDate;
      hotel.Checkout = hoteOrder.CheckOutDate;
      let checklist = this.getCheckList(hoteOrder);
      hotel.HotelOrderRoomList = checklist;
      let totalprice = hoteOrder.TotalPayablePrice;
      if (totalprice == 0 || totalprice == null) {
        hotel.TotalPayablePrice = null;
      } else {
        let showerror = "";

        let isfind = false;

        let find = checklist.find(dr => dr.TotalPriceRoom == 0 || dr.TotalPriceRoom == null)
        if (find) {
          isfind = true;
        }

        find = checklist.find(dr => (dr.TotalExteraPersonAdultCount > 0 && dr.TotalExteraPersonAdultPrice == null) || (dr.TotalExteraPersonChildCount > 0 && dr.TotalExteraPersonChildPrice == null))

        if (find) {
          isfind = true;
        }
        if (isfind) {
          hotel.TotalPayablePrice = null;
        }
      }

      this.HotelOrder = { ...hoteOrder }
      return hotel;
    }

    this.HotelOrder = { ...hoteOrder }
    return null;
  }

  getBodyTags(tags: TagContract[]): TagContract[] {
    if (tags?.find(dr => dr.TagType == TagType.SpecialTag)) {
      return tags?.filter(dr => dr.TagType == TagType.SpecialTag || dr.TagType == TagType.OneTag || dr.TagType == TagType.TwoTag || dr.TagType == TagType.Three).sort((a, b) => {
        return a.TagType - b.TagType;
      });
    } else {
      return tags?.filter(dr => dr.TagType == TagType.OneTag || dr.TagType == TagType.TwoTag || dr.TagType == TagType.Three || dr.TagType == TagType.Five).sort((a, b) => {
        return a.TagType - b.TagType;
      });
    }
  }

  getPriceTag(tags: TagContract[]): TagContract {
    if (tags?.find(dr => dr.TagType == TagType.PriceTag)) {
      return tags?.find(dr => dr.TagType == TagType.PriceTag);
    } else {
      return null;
    }
  }

  getPictureTag(tags: TagContract[]): TagContract {
    if (tags?.find(dr => dr.TagType == TagType.PictureTag)) {
      return tags?.find(dr => dr.TagType == TagType.PictureTag);
    } else {
      return null;
    }
  }

  getSelectDetailRoom(): RoomContract[] {
    let Rooms = new Array<RoomContract>();
    let hotelOrder = { ...this.HotelOrder };
    hotelOrder.Rooms.forEach(room => {

      room.RoomPassengers.forEach(passenger => {
        Rooms.push({
          Title: room.Title,
          RoomKey: passenger.Roomkey,
          RoomType: room.RoomType,
          RoomId: room.RoomId,
          PersianLastName: passenger.PersianLastName,
          PersianFirstName: passenger.PersianFirstName,
          PassportNumber: passenger.PassportNumber,
          NationalCode: passenger.NationalCode,
          Gender: passenger.Gender,
          EnglishLastName: passenger.EnglishLastName,
          EnglishFirstName: passenger.EnglishFirstName,
          ExteraPersonChildPrice: passenger.ExteraPersonChildPrice,
          ExteraPersonAdultPrice: passenger.ExteraPersonAdultPrice,
          ExteraPersonChildCount: passenger.ExteraPersonChildCount,
          ExteraPersonAdultCount: passenger.ExteraPersonAdultCount,
          ExteraPersonCapacity: passenger.ExteraPersonCapacity,
          IsExteraPerson: passenger.IsExteraPerson,
          IsForeigner: passenger.IsForeigner,
          NationalityType: passenger.NationalType
        } as RoomContract);
      })
    })
    this.HotelOrder = { ...hotelOrder };
    return Rooms;
  }

  getInitDetailPurchase(hoteOrder: HotelPurchaseOrderDetailContract = null) {

    if (!hoteOrder)
      hoteOrder = Object.assign({}, this.HotelPurchaseOrderDetail);
    hoteOrder.Name = hoteOrder.bookHotelOrderDetails[0].hotelPackage.title;
    hoteOrder.Address = hoteOrder.bookHotelOrderDetails[0].hotelPackage.address ?? '-';
    hoteOrder.Star = hoteOrder.bookHotelOrderDetails[0].hotelPackage.star ?? null;

    this.HotelPurchaseOrderDetail = { ...hoteOrder }
  }

  getCheckListDetailPurchase(hoteOrder: HotelPurchaseOrderDetailContract = null): HotelOrderCheckListDetail {
    if (!hoteOrder)
      hoteOrder = Object.assign({}, this.HotelPurchaseOrderDetail);
    let detail: HotelOrderCheckListDetail = new HotelOrderCheckListDetail();
    let nightCount: number = this.getNightCount(hoteOrder.bookHotelOrderDetails[0].checkin, hoteOrder.bookHotelOrderDetails[0].checkout);
    detail = {
      ChekInDate: hoteOrder.bookHotelOrderDetails[0].checkin,
      CheckOutDate: hoteOrder.bookHotelOrderDetails[0].checkout,
      ChekInTime: hoteOrder.bookHotelOrderDetails[0].hotelPackage.hotelCheckin,
      CheckOutTime: hoteOrder.bookHotelOrderDetails[0].hotelPackage.hotelCheckout,
      CheckInpPrsianDate: null,
      CheckOutPersianDate: null,
      NightCout: nightCount,
      TotalRoomCount: hoteOrder.bookHotelOrderDetails[0].hotelPackage.roomPackages.length
    } as HotelOrderCheckListDetail;
    this.HotelPurchaseOrderDetail = { ...hoteOrder };
    return detail;
  }

  getCheckListPurchase(hoteOrder: HotelPurchaseOrderDetailContract = null): HotelOrderCheckList[] {
    if (!hoteOrder)
      hoteOrder = Object.assign({}, this.HotelPurchaseOrderDetail);

    let checks: HotelOrderCheckList[] = new Array<HotelOrderCheckList>();

    hoteOrder.bookHotelOrderDetails.forEach(bookHotelOrderDetail => {
      bookHotelOrderDetail.hotelPackage.roomPackages.forEach(room => {
        let check = checks.find(dr => dr.RoomId && dr.RoomId == room.roomId && dr.RoomType == room.type);
        if (check) {
          check.TotalPayablePrice += Number(room.roomTotalPrice);
          if (room.additionalCapacityChildPrice && room.additionalCapacityChild) {
            check.TotalExteraPersonChildPrice += room.additionalCapacityChildPrice * room.additionalCapacityChild;
          } else {
            check.TotalExteraPersonChildPrice = null;
          }
          check.TotalExteraPersonChildCount += room.additionalCapacityChild;
          if (room.additionalCapacityAdultPrice && room.additionalCapacityAdult) {
            check.TotalExteraPersonAdultPrice += room.additionalCapacityAdultPrice * room.additionalCapacityAdult;
          } else {
            check.TotalExteraPersonAdultPrice = null;
          }

          check.TotalExteraPersonAdultCount += room.additionalCapacityAdult;
          check.TotalPriceRoom += Number(room.roomTotalPrice);
          check.TotalCountRoom += 1;
        } else {
          let check = new HotelOrderCheckList();
          check.RoomId = room.roomId;
          check.RoomType = room.type;
          check.Title = room.name;
          check.TotalPayablePrice = Number(room.roomTotalPrice);
          check.ShortTitle = room.name;
          if (room.additionalCapacityChildPrice && room.additionalCapacityChild) {
            check.TotalExteraPersonChildPrice = room.additionalCapacityChildPrice * room.additionalCapacityChild;
          } else {
            check.TotalExteraPersonChildPrice = null;
          }

          check.TotalExteraPersonChildCount = room.additionalCapacityChild;

          if (room.additionalCapacityAdultPrice && room.additionalCapacityAdult) {
            check.TotalExteraPersonAdultPrice = room.additionalCapacityAdultPrice * room.additionalCapacityAdult;
          } else {
            check.TotalExteraPersonAdultPrice = null;
          }

          check.TotalExteraPersonAdultCount = room.additionalCapacityAdult;
          check.TotalPriceRoom = Number(room.roomTotalPrice);
          check.TotalCountRoom = 1;
          checks.push(check);
        }
      });
    });
    this.HotelPurchaseOrderDetail = { ...hoteOrder };
    return checks;
  }

  getNightCount(dt1: Date, dt2: Date) {

    if (!(dt1 instanceof Date)) {
      dt1 = new Date(dt1);
    }
    if (!(dt2 instanceof Date)) {
      dt2 = new Date(dt2);
    }


    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }

  getRoomType(roomType: number): string {

    switch (roomType) {
      case 0:
        return 'نامشخص';
      case 1:
        return 'اتاق یک نفره';
      case 2:
        return 'اتاق دونفره';
      case 3:
        return 'اتاق سه نفره';
      case 4:
        return 'اتاق چهار نفره';
      case 5:
        return 'سوئت دونفره';
      case 6:
        return 'آپارتمان یک خوابه';
      case 7:
        return 'آپارتمان دوخوابه';
      case 8:
        return 'آپارتمان سه خوابه';
      case 9:
        return ' استدیو';
      case 10:
        return 'اتاق کانکت';
      case 11:
        return 'سوئت دوپلکس';
      case 12:
        return ' اتاق کابانا';
      case 13:
        return ' فیستا سوئت';
      case 14:
        return 'سوئت سه نفره';
      case 15:
        return 'سوئیت چهار نفره';
      case 16:
        return 'آپارتمان چهار خوابه';
    }

  }

  addOrGetHistoryUrl(pageName: string, url: string = null) {
    let hotelOrder = { ...this.HotelOrder }
    if (hotelOrder.HistoryUrl.length > 0) {
      let find = hotelOrder.HistoryUrl.find(dr => dr.name == pageName);
      if (find) {
        if (url) {
          find.url = url
        }
        this.HotelOrder = { ...hotelOrder }
        return find.url;
      }
    }

    if (pageName && url) {
      let newArr = [...hotelOrder.HistoryUrl];
      newArr.push({ name: pageName, url: url });
      this.HotelOrder = { ...hotelOrder, HistoryUrl: newArr }
      return url;
    }
    return null;
  }

}
