export class HotelTransferDataModel {
  TotalPrice: number | null = null;
  Rooms: any[] = [];
  NightCount: number | null = null;
  CheckinDate: any = null;
  CheckoutDate: any = null;
  CheckInTime: string | null = null;
  CheckOutTime: string | null = null;
  hotel: any = null;
  RoomCount: number | null = null;
}
