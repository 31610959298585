// #docplaster
import { Injectable, PLATFORM_ID, Injector } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { CacheService } from './core/services/app/clearCache/clear-cache.service';

function promptUser(event: VersionReadyEvent): boolean {
  return true;
}

// #docregion sw-version-ready
@Injectable({ providedIn: 'root' })
export class PromptUpdateService extends CacheService{
  constructor(injector: Injector, swUpdate: SwUpdate) {
    super();
    if (
      environment.production &&
      isPlatformBrowser(injector.get(PLATFORM_ID))
    ) {
      swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe((evt) => {
          if (promptUser(evt)) {
            // Reload the page to update to the latest version.
          console.log('Reload the page to update to the latest version.' );

            document.location.reload();
          }
        });
      // #enddocregion sw-version-ready
      // ...
      const updatesAvailable = swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      ).subscribe(res => {
        console.log('UPDATE_AVAILABLE.' + res );
        this.clearCache();
        window.location.reload();
      }) ;
      // #docregion sw-version-ready
    }
  }
}
// #enddocregion sw-version-ready
