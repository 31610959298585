import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { BusyIfDirective } from './busy-if.directive';
import { ButtonBusyDirective } from './button-busy.directive';

import { LocalStorageService } from './local-storage.service';
import { MomentFormatPipe } from './moment-format.pipe';
import { MomentFromNowPipe } from './moment-from-now.pipe';
import { ValidationMessagesComponent } from './validation-messages.component';
import { EqualValidator } from './validation/equal-validator.directive';
import { PasswordComplexityValidator } from './validation/password-complexity-validator.directive';
import { NullDefaultValueDirective } from './null-value.directive';
import { CharOnlyDirective, EnglighTextOnlyDirective } from './char-only.directive';
import { PersianCharOnlyDirective } from './persian-char-only.directive';
import { CharNumberOnlyDirective } from './char-num-only.directive';
import { NumOnlyDirective } from './num-only.directive';
import { ScriptLoaderService } from './script-loader.service';
import { StyleLoaderService } from './style-loader.service';

import { TreeDataHelperService } from './tree-data-helper.service';
import { ClickOutsideDirective } from './outsideclick';
import { FarsiTextOnlyDirective } from './farsittextonly.directive';
import { RunAfterInitDirective, RunAfterInitTsDirective } from '../directives/runAfterInitDirective.directive';
import { AutoActiveClass } from '../directives/autoActiveDirective.directive';
import { ClickStopPropagation } from '../directives/clickStopPropagation.directive';
import { ShowMoreDirective } from './mor-info';
import { CloseOnEscapeDirective, SelectOnfocusDirective } from './selectOnfocus';
import { AirLinePersianNamePipe, Comma3Pipe, EnumToText, JalaliPipe, JalaliPipeP, LocalizeDigitPipe, MinuteSecondsPipe, RialPricePipe, TimeDurationPipe, TomanPipe, TomanPricePipe } from '../pipes/localize.pipe';
import { JSONLdComponent } from './jsonld';
import { UTCDatePipe } from 'src/shared/pipes/UTCDate.pipe'

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        // FileDownloadService,
        LocalStorageService,
        ScriptLoaderService,
        StyleLoaderService,
        TreeDataHelperService
    ],
    declarations: [
        RunAfterInitDirective,
        RunAfterInitTsDirective,
        AutoActiveClass,
        ClickStopPropagation,
        EqualValidator,
        PasswordComplexityValidator,
        ButtonBusyDirective,
        ClickOutsideDirective,
        AutoFocusDirective,
        BusyIfDirective,
        MomentFormatPipe,
        MomentFromNowPipe,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        CharOnlyDirective,
        ShowMoreDirective,
        PersianCharOnlyDirective,
        EnglighTextOnlyDirective,
        FarsiTextOnlyDirective,
        SelectOnfocusDirective,
        JSONLdComponent,
        CloseOnEscapeDirective,
        CharNumberOnlyDirective,
        NumOnlyDirective,
        //LocalizePipe,
        UTCDatePipe,
        LocalizeDigitPipe,
        Comma3Pipe,
        TomanPipe,
        TomanPricePipe,
        RialPricePipe,
        AirLinePersianNamePipe,
        TimeDurationPipe,
        JalaliPipe,
        JalaliPipeP,
        EnumToText,
        //PermissionPipe,
        MinuteSecondsPipe
    ],
    exports: [
        RunAfterInitDirective,
        RunAfterInitTsDirective,
        AutoActiveClass,
        ClickStopPropagation,
        EqualValidator,
        PasswordComplexityValidator,
        ButtonBusyDirective,
        ClickOutsideDirective,
        AutoFocusDirective,
        BusyIfDirective,
        MomentFormatPipe,
        MomentFromNowPipe,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        CharOnlyDirective,
        ShowMoreDirective,
        PersianCharOnlyDirective,
        FarsiTextOnlyDirective,
        SelectOnfocusDirective,
        JSONLdComponent,
        CloseOnEscapeDirective,
        CharNumberOnlyDirective,
        EnglighTextOnlyDirective,
        NumOnlyDirective,
        //LocalizePipe,
        UTCDatePipe,
        LocalizeDigitPipe,
        Comma3Pipe,
        TomanPipe,
        TomanPricePipe,
        RialPricePipe,
        AirLinePersianNamePipe,
        TimeDurationPipe,
        JalaliPipe,
        JalaliPipeP,
        EnumToText,
        //PermissionPipe,
        MinuteSecondsPipe
    ]
})
export class UtilsModule { }
