import { UtilityModule } from './../../shared/utility.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelRoutingModule } from './hotel-routing.module';
import { HotelDetailContextService } from 'src/app/core/services/hotel/hotel-detail-content.service';
import { AppInitCallGuard } from 'src/app/auth-routing';
import { HotelRouterServices } from '../../core/services/hotel/hotel-router.service';


@NgModule({
  imports: [
    CommonModule,
    // UtilityModule,
    HotelRoutingModule
  ],
  declarations: [],
  providers: [
    HotelDetailContextService,
    AppInitCallGuard,
    HotelRouterServices,
  ]
})
export class HotelModule { }
