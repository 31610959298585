import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../store/app/appstate.interface';
import { selectSearchTrain } from '../../store/train/search/search.selector';
import { filter, tap, map, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { IStoreProduct } from 'src/app/core/mocks/store/store.interface';
import { ISearchTrainRequired } from 'src/app/core/store/train/search/search.model';
@Injectable({
  providedIn: 'root'
})
export class QueryAndStoreGuard implements CanActivate {
  storeIsFilled: boolean = false;
  constructor(
    private store: Store<AppStateInterface>,
    private location: Location
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return this.store.pipe(select(selectSearchTrain)).pipe(
      map((data: IStoreProduct<ISearchTrainRequired>) => {
        let isAllNull = Object.values(data.stateData).every((value: any) => {
          if (value === null || value === undefined || value === false) return true
          else return false
        });
        return !isAllNull
      }),
      tap(loaded => {
        if (!loaded) { // checking if we need to dispatch the action.
          this.location.back();
        }
      }),
      filter(loaded => !!loaded), // waiting until the tasks have been loaded.
      take(1),
    );
  }

}
