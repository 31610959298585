import { viewItemGoogleAnalyticDto } from './model/viewItemDto';
import { viewItemListGoogleAnalyticDto } from './model/viewItemListDto';
import { purchaseGoogleAnalyticDto } from './model/purchaseDto';
import { addPaymentInfoGoogleAnalyticDto } from './model/addPaymentInfoDto';
import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  constructor() { }
  public viewItem(viewitemModel: viewItemGoogleAnalyticDto) {
    gtag('event', 'view_item', viewitemModel)
  }

  public viewItemList(viewItemListModel: viewItemListGoogleAnalyticDto) {
    gtag('event', "view_item_list", viewItemListModel)
  }

  public purchase(purchaseModel: purchaseGoogleAnalyticDto) {
    gtag('event', "purchase", purchaseModel)
  }

  public paymentinfo(paymentInfoModel: addPaymentInfoGoogleAnalyticDto) {
    gtag('event', "add_payment_info", paymentInfoModel)
  }
}
