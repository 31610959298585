import { EventEmitter, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationBehaviorOptions, Router, RouterStateSnapshot } from '@angular/router';
import { HotelavailabilityseoService } from 'src/shared/signalGo/services/hotelavailabilityseoService';
import { HotelAvailabilitySeoResponse } from 'src/shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Responses/HotelAvailabilitySeoResponse';
import { HotelavailabilityService } from 'src/shared/signalGo/services/hotelavailabilityService';
import { HotelLandingAvailableRequestContract } from 'src/shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Requests.LandingPage/HotelLandingAvailableRequestContract';
import { FilterContract } from 'src/shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Common/FilterContract';
import { HotelLandingAvailableResponseContract } from 'src/shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Responses.LandingPage/HotelLandingAvailableResponseContract';

@Injectable()
export class HotelDetailContextService implements CanActivate {

  _hotelAvailabilitySeoServices: HotelavailabilityseoService;
  _hotelAvailabilityServices: HotelavailabilityService;
  platformId: any;
  _router: Router;
  //all content where user wants to see
  loadedContents: Array<HotelContentDto> = [];

  HotelAvailabilitySeo: HotelAvailabilitySeoResponse = new HotelAvailabilitySeoResponse();
  HotelAvailabilitySeoEmitter: EventEmitter<HotelAvailabilitySeoResponse> = new EventEmitter<HotelAvailabilitySeoResponse>();

  constructor(public injector: Injector,
    hotelAvailabilitySeoServices: HotelavailabilityseoService,
  ) {
    this.platformId = injector.get(PLATFORM_ID);
    this._hotelAvailabilitySeoServices = hotelAvailabilitySeoServices;
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let prom = new Promise<any>((resolve, reject) => {
      let name = route.paramMap.get('name');

        try {

        }
        catch
        {
          return resolve(false);
        }
      // }
    });
    return prom;
  }
}

//content dto
export class HotelContentDto {
  key: string;
  content: HotelLandingAvailableResponseContract;
  status: 'InProgress' | 'Completed';
  //for request where are their status is 'InProgress'
  promise: Promise<any>;
}
