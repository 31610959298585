import { Component, Injector, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppComponentBase,
  PhoneNmuberValidator,
  toEnglishNumber,
} from '../../app-component-base';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Md5 } from 'ts-md5/dist/md5';
import { FailedReasonType } from '../../../shared/signalGo/services/MahdBuraq.Utility.Models/FailedReasonType';
import { VerifyCaptchaRequestContract } from '../../../shared/signalGo/services/Tkt724.Hub.IdentityServer.Contracts.Requests/VerifyCaptchaRequestContract';
import { MessageType } from 'src/shared/signalGo/services/AuthenticationGenerationServices.SMSGenerationServices.Tkt724.Hub.SMS.Common/MessageType';
import { AppSessionService } from 'src/app/core/services/app/app-session.service';
import { Store } from '@ngrx/store';
import { userActions } from 'src/app/core/store/app/user/controllers/user.action';

import { AlertService } from 'src/app/core/services/app/alert/alert.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  extends AppComponentBase
  implements OnInit, AfterViewInit
{
  mobileLoginForm: FormGroup;
  mobileLoginCodeForm: FormGroup;
  captchaForm: FormGroup;
  authWithUsernameAndPasswordFormGroup: FormGroup;
  formPassword: FormGroup;
  isSaving: boolean = false;
  mobileNumber: string | undefined;
  waitForSendSms: boolean = false;
  captchaImage: string;
  captchaId: string;
  captchaNeeded: boolean = false;
  mobileNumberInput: string = '';
  invalidVerifyCode: boolean = false;
  remaining: number = 0;
  isVerifying: boolean = false;
  isVerifyingCaptcha: boolean = false;
  routeNumber: string = '';
  steplogin: number = 1;
  isLoginSuccess: boolean = false;
  isFromForgetPassword: boolean = false;
  setPasswords: boolean = false;
  userPasswords: boolean = false;
  submitted: boolean = false;
  showactive: boolean = false;
  activatedRoute: any;
  store: Store;
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private appSessionService: AppSessionService,
    private alertService: AlertService,
  ) {
    super(injector);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.store = injector.get(Store);
  }
  updateAuthentication() {
    this.store.dispatch(
      userActions.updateAuthenticated({
        isAuthenticated: true,
      })
    );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.showactive = true;
    }, 50);
  }
  closeModal() {
    // For Click User In Close Modal And Does Not Want To Set A Password
    if (this.steplogin == 5) {
      this.updateAuthentication();
      this.alertService.success('خوش آمدید',{timeOut:2000});

      this.publicAppSignalService.appSessionService
        .AppInitObs()
        .subscribe((appInitResult) => {
          this.appSessionService.login_registerIsOpen = false;
          setTimeout(() => {
            if (this.isLoginSuccess) this.appSessionService.loginCallback();
          }, 300);
          // this.closeLoginForm(this.isLoginSuccess);
        });
    }

    this.steplogin = 1;
    this.appSessionService.login_registerIsOpen = false;
    setTimeout(() => {
      if (this.isLoginSuccess) this.appSessionService.loginCallback();
    }, 300);
    // this.login_registerIsOpen = false;
    // this.closeLoginForm(this.isLoginSuccess);
  }
  switchTab(routeNumber) {
    this.submitted = false;
    this.steplogin = routeNumber;
    if (this.steplogin == 3) {
      this.mobileLoginForm.reset();
    }
  }
  ngOnInit(): void {
    this.createEntryForms();
  }
  formFocus(event: { srcElement?: any }) {
    if (event.srcElement) {
      if (!event.srcElement.focus()) {
        const telInputElement = document.querySelector('.focusinput') as HTMLInputElement;
        telInputElement?.focus();
      }
    } else {
      const telInputElement = document.querySelector('.focusinput') as HTMLInputElement;
      telInputElement?.focus();
    }
  }
  createEntryForms() {
    this.steplogin = 1;
    this.captchaNeeded = false;
    this.isVerifying = false;
    this.isVerifyingCaptcha = false;
    this.waitForSendSms = false;
    this.createMobileLoginForm();
    this.createMobileLoginCodeForm();
    this.createCaptchaForm();
    // Set & Forget Password
    this.formBuildPassword();
    // Auth With Username Password
    this.formBuildAuthWithUsernamePassword();
  }
  createMobileLoginCodeForm() {
    this.invalidVerifyCode = false;
    this.mobileLoginCodeForm = new FormGroup({
      Number_One: new FormControl('', [
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9۰-۹]{1}$'),
        ]),
      ]),
      Number_Two: new FormControl('', [
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9۰-۹]{1}$'),
        ]),
      ]),
      Number_Three: new FormControl('', [
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9۰-۹]{1}$'),
        ]),
      ]),
      Number_Four: new FormControl('', [
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9۰-۹]{1}$'),
        ]),
      ]),
      Number_Five: new FormControl('', [
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9۰-۹]{1}$'),
        ]),
      ]),
    });
  }

  createMobileLoginForm() {
    this.mobileLoginForm = new FormGroup({
      mobileNumber: new FormControl(this.login_registerNumber, [
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9۰-۹]{10,11}$'),
          PhoneNmuberValidator,
        ]),
      ]),
    });
    if (this.mobileLoginForm.valid) {
      this.mobileLogin();
    }
    this.login_registerNumber = '';
  }
  createCaptchaForm() {
    this.captchaForm = new FormGroup({
      Capcha_Code: new FormControl('', [Validators.required]),
    });
  }
  charLimit = 1;
  selectall(e) {
    e.srcElement.select();
  }
  verify_code_input_keydown(e, p, n) {
    var keys = [
      8, 9, /*16, 17, 18,*/ 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46,
      144, 145,
    ];
    if (e.which == 13 && this.mobileLoginCodeForm.valid) {
      if (!this.captchaNeeded) this.mobileLogin();
    }
    if (e.which == 8 && e.srcElement.value.length == 0) {
      p.focus();
    } else if (keys.indexOf(e.which) != -1) {
      return true;
    } else if (
      e.srcElement.value.length >= 1 &&
      ((e.key >= '0' && e.key <= '9') || (e.key >= '۰' && e.key <= '۹'))
    ) {
      e.srcElement.value = ''
    }
    if (
      e.shiftKey ||
      (!(e.key >= '0' && e.key <= '9') && !(e.key >= '۰' && e.key <= '۹'))
    ) {
      return false;
    }
  }
  verify_code_input_keyup(e, n, isLastDigit) {
    if (isLastDigit && this.mobileLoginCodeForm.valid) {
      if (!this.captchaNeeded) this.mobileLogin();
    }
    if (e.srcElement.value.length >= 1 && e.which != 8) {
      n.focus();
      return false;
    }
    if (e.srcElement.value.length >= 1 && e.which == 8) {
      e.srcElement.value = ''
      return false;
    }
  }

  get getResnedTimeout() {
    var m = Math.floor(this.remaining / 60);
    var s = this.remaining % 60;
    return m + (s < 10 ? ':0' : ':') + s;
  }

  resnedTimeoutInterval: any;
  setResnedTimeout(val: number) {
    clearInterval(this.resnedTimeoutInterval);
    this.remaining = val;
    this.resnedTimeoutInterval = setInterval(() => {
      this.remaining -= 1;
      if (this.remaining <= 0) clearInterval(this.resnedTimeoutInterval);
    }, 1000);
  }

  sendSms(type: MessageType) {
    this.waitForSendSms = true;
    this.setResnedTimeout(120);
    this.userClaimMobile =
      '+98' +
      this.getTenDigitMobileNumber(
        toEnglishNumber(this.mobileLoginForm.value.mobileNumber)
      );
    // INFO The verification code notification type is determined by the 'type' value.
    this.publicAppSignalService.loginSms(this.userClaimMobile, type).subscribe(
      (o: any) => {
        if (o.success) {
          // The message will be sent to click on the "Get Confirmation Phone Code" button
          if (type == MessageType.Voice) {
            this.alertService.success(
              'کد ورود، به صورت تماس تلفنی از پیش شماره 94220-98+ به شما اعلام می گردد',
              {timeOut:5000}
            );
          }
          if (this.isFromForgetPassword) {
            this.alertService.success('کد یکبار مصرف به شماره شما ارسال شد.',{timeOut:2000});
          }
          this.waitForSendSms = false;
          this.steplogin = 2;
          let $this = this;
          if (this.captchaNeeded && this.steplogin == 2) {
            setTimeout(() => {
              $this.formFocus({});
            }, 100);
          }
          this.createMobileLoginCodeForm();
        } else {
          this.waitForSendSms = false;
          if (o.failedReason == 25)
            this.alertService.error(
              'به دلیل ارسال بیش از حد اس ام اس 2 دقیقه بعد مجددا تلاش کنید',
              {timeOut:4000}
            );
          if (o.failedReason == FailedReasonType.CaptchaError) {
            this.refreshCaptcha();
            this.alertService.error(
              'به دلیل مسایل امنیتی ابتدا باید ربات نبودن شما بررسی شود',
              {timeOut:4000}
            );
            this.isVerifyingCaptcha = false;
            this.captchaNeeded = true;
          }
        }
      },
      (error) => {
        this.waitForSendSms = false;
        this.alertService.error('خطا در ارسال اس ام اس',{timeOut: 2000});
      }
    );
  }

  onPaste(e, paste) {
    e.preventDefault();
    if (paste)
      try {
        let str =
          e.clipboardData.getData('text/plain')?.replace(/\D/g, '') ?? '';
        str[0] && this.mobileLoginCodeForm.controls.Number_Five.reset(str[0]);
        str[1] && this.mobileLoginCodeForm.controls.Number_Four.reset(str[1]);
        str[2] && this.mobileLoginCodeForm.controls.Number_Three.reset(str[2]);
        str[3] && this.mobileLoginCodeForm.controls.Number_Two.reset(str[3]);
        str[4] && this.mobileLoginCodeForm.controls.Number_One.reset(str[4]);
      } catch (error) {}
  }

  mobileLogin() {
    if (!this.isSaving) {
      this.isSaving = true;
      if (this.captchaNeeded) {
        this.verifyCaptcha();
      } else if (this.steplogin == 1) {
        this.isLoginSuccess = true;
        this.invalidVerifyCode = false;
        if (this.mobileLoginForm.valid) {
          // Captcha is not needed anymore
          this.mobileNumberInput = this.mobileLoginForm.value.mobileNumber;
          this.sendSms(MessageType.Sms);
        } else {
          this.alertService.error('لطفا اطلاعات فرم ورود را کامل کنید.', {timeOut:2000});
        }
      } else if (this.steplogin == 2) {
        if (this.mobileLoginCodeForm.valid) {
          let code =
            this.mobileLoginCodeForm.value.Number_Five +
            this.mobileLoginCodeForm.value.Number_Four +
            this.mobileLoginCodeForm.value.Number_Three +
            this.mobileLoginCodeForm.value.Number_Two +
            this.mobileLoginCodeForm.value.Number_One;
          this.verifyMobile(code);
        } else {
          this.alertService.error('لطفا اطلاعات فرم ورود را کامل کنید.', {timeOut:2000});
        }
      }
      this.isSaving = false;
    }
  }
  verifyCaptcha() {
    {
      const md5 = new Md5();
      if (this.captchaForm.controls.Capcha_Code.valid) {
        let loginCaptchaRequest: VerifyCaptchaRequestContract =
          new VerifyCaptchaRequestContract();
        loginCaptchaRequest.captchaId = this.captchaId;
        loginCaptchaRequest.token = md5
          .appendStr(
            toEnglishNumber(this.captchaForm.value.Capcha_Code.toLowerCase())
          )
          .end()
          .toString();
        this.isVerifyingCaptcha = true;
        this.publicAppSignalService.authenticationService
          .verifyCaptcha(loginCaptchaRequest)
          .subscribe(
            (res: any) => {
              this.isVerifyingCaptcha = false;
              if (res.success) {
                this.captchaNeeded = false;
                this.isVerifying = false;
                this.isVerifyingCaptcha = false;
                if (this.steplogin == 1 && this.mobileLoginForm.valid) {
                  this.mobileLogin();
                } else if (this.steplogin == 2) {
                  this.sendSms(MessageType.Sms);
                  this.createMobileLoginCodeForm();
                }

                if (this.isFromForgetPassword) {
                  if (this.steplogin == 4 && this.mobileLoginForm.valid) {
                    this.forgetPassword();
                  } else if (this.steplogin == 2) {
                    this.sendSms(MessageType.Sms);
                    this.createMobileLoginCodeForm();
                  }
                }
              } else {
                this.refreshCaptcha();
                this.alertService.error('کد کپچا را بصورت صحیح وارد کنید.',{timeOut:2000});
              }
            },
            (err) => {
              this.refreshCaptcha();
              this.isVerifyingCaptcha = false;
              this.alertService.error('خطا در ارسال اعتبارسنجی', {timeOut:2000});
              //console.log(err);
            }
          );
      } else {
        this.alertService.error('کد کپچا را بصورت صحیح وارد کنید.', {timeOut:2000});
        this.isVerifyingCaptcha = false;
      }
    }
  }
  verifyMobile(code: string) {
    this.isVerifying = true;
    this.publicAppSignalService.authenticationService
      .verifyMobileCode(code)
      .subscribe(
        (res: any) => {
          this.captchaNeeded = false;
          if (res.success) {
            this.isVerifying = false;
            // The user has entered the site but must set a password
            // and check whether this work has already been done or not
            if (!res.result.isVerified || this.isFromForgetPassword) {
              this.steplogin = 5;
            } else {
              this.updateAuthentication();
              this.alertService.success('خوش آمدید', {timeOut:2000});
              this.publicAppSignalService.appSessionService
                .AppInitObs()
                .subscribe((o) => {
                  this.appSessionService.login_registerIsOpen = false;
                  setTimeout(() => {
                    if (this.isLoginSuccess)
                      this.appSessionService.loginCallback();
                  }, 300);
                  // this.closeLoginForm(this.isLoginSuccess);
                });
            }
          } else {
            if (res.failedReason == FailedReasonType.CaptchaError) {
              this.captchaNeeded = true;
              this.isVerifyingCaptcha = false;
              this.refreshCaptcha();
              this.invalidVerifyCode = true;
            }
            this.alertService.error('کد وارد شده صحیح نمی‌باشد.', {timeOut:2000});
            this.isVerifying = false;
          }
        },
        (error) => {
          this.alertService.error('خطا در ارسال اعتبارسنجی', {timeOut:2000});
          this.isVerifying = false;
        },
        () => (this.isVerifying = false)
      );
  }
  // refresh Captcha
  refreshCaptcha() {
    this.createCaptchaForm();
    this.captchaImage = '';
    this.publicAppSignalService.authenticationService
      .getCaptcha()
      .subscribe((res: any) => {
        if (res.success) {
          this.captchaImage = res.result.fileUrl;
          this.captchaId = res.result.id;
        }
      });
  }
  // ========================== New Facilities Login  ========================== //
  // convenience getter for easy access to form fields
  get f() {
    return this.formPassword.controls;
  }
  get u() {
    return this.authWithUsernameAndPasswordFormGroup.controls;
  }
  // Form Builder SetPassword
  formBuildPassword() {
    this.formPassword = this.formBuilder.group({
      setPassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }
  // Form Builder Authenticate User With Username And Password
  formBuildAuthWithUsernamePassword() {
    this.authWithUsernameAndPasswordFormGroup = this.formBuilder.group({
      username: [
        '',
        [
          Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9۰-۹]{10,11}$'),
            PhoneNmuberValidator,
          ]),
        ],
      ],
      password: ['', [Validators.required]],
      // Validators.minLength(6)
    });
  }
  // Toggle Show Password Function
  // @param { string } toggleShowPassword(name).
  toggleShowPassword(showBoolean: string) {
    this[showBoolean] = !this[showBoolean];
  }
  // Set Password User
  formSetPasswordUser() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formPassword.invalid) {
      return;
    }
    this.isVerifying = true;
    this.publicAppSignalService
      .setPasswordUser(this.formPassword.get('setPassword').value)
      .subscribe(
        (res) => {
          if (res.success) {
            this.updateAuthentication();
            this.alertService.success('خوش آمدید', {timeOut:2000});
            this.publicAppSignalService.appSessionService
              .AppInitObs()
              .subscribe((appInitResult) => {
                this.appSessionService.login_registerIsOpen = false;
                setTimeout(() => {
                  if (this.isLoginSuccess)
                    this.appSessionService.loginCallback();
                }, 300);
                // this.closeLoginForm(this.isLoginSuccess);
              });
          } else {
            this.isVerifying = false;
            this.alertService.error('خطا در تعیین رمز عبور', {timeOut:2000});
          }
        },
        (error) => {
          this.isVerifying = false;
          this.alertService.error('خطا در ارسال تعیین رمز عبور', {timeOut:2000});
        }
      );
  }

  // Authenticate User With Username And Password
  get password(): string {
    let password: string =
      this.authWithUsernameAndPasswordFormGroup?.get('password')?.value;
    if (!password) return '';
    return password;
  }
  get username(): string {
    let countryDialingCode: string =
      this.authWithUsernameAndPasswordFormGroup?.get('username')?.value;
    if (!countryDialingCode) return '';
    countryDialingCode =
      '+98' + this.getTenDigitMobileNumber(toEnglishNumber(countryDialingCode));
    return countryDialingCode;
  }
  authenticateUserWithMobilePassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.authWithUsernameAndPasswordFormGroup.invalid) {
      return;
    }
    this.isVerifying = true;
    this.publicAppSignalService
      .authenticateUserWithUsernameAndPassword(this.username, this.password)
      .subscribe(
        (res) => {
          this.captchaNeeded = false;
          if (res.success) {
            this.updateAuthentication();
            this.alertService.success('خوش آمدید', {timeOut:2000});
            this.publicAppSignalService.appSessionService
              .AppInitObs()
              .subscribe((appInitResult) => {
                this.appSessionService.login_registerIsOpen = false;
                setTimeout(() => {
                  //if (this.isLoginSuccess)
                    this.appSessionService.loginCallback();
                }, 300);
                // this.closeLoginForm(this.isLoginSuccess);
              });
          } else {
            if (res.failedReason == FailedReasonType.CaptchaError) {
              this.captchaNeeded = true;
              this.isVerifyingCaptcha = false;
              this.refreshCaptcha();
              this.invalidVerifyCode = true;
            }
            this.isVerifying = false;
            this.alertService.error('خطا در نام کاربری و رمز عبور', {timeOut:2000});
          }
        },
        (error) => {
          this.isVerifying = false;
          this.alertService.error('خطا در ورود', {timeOut:2000});
        }
      );
  }
  // Forget Password or the Set Password
  forgetPassword() {
    this.isFromForgetPassword = true;
    if (!this.isSaving) {
      this.isSaving = true;
      if (this.captchaNeeded) {
        this.verifyCaptcha();
      } else if (this.steplogin == 4) {
        this.isLoginSuccess = true;
        this.invalidVerifyCode = false;
        if (this.mobileLoginForm.valid) {
          // Captcha is not needed anymore
          this.mobileNumberInput = this.mobileLoginForm.value.mobileNumber;
          this.sendSms(MessageType.Sms);
        } else {
          this.alertService.error('لطفا اطلاعات فرم ورود را کامل کنید.', {timeOut:2000});
        }
      } else if (this.steplogin == 2) {
        this.alertService.success('کد یکبار مصرف به شماره شما ارسال شد.', {timeOut:2000});
        if (this.mobileLoginCodeForm.valid) {
          let code =
            this.mobileLoginCodeForm.value.Number_Five +
            this.mobileLoginCodeForm.value.Number_Four +
            this.mobileLoginCodeForm.value.Number_Three +
            this.mobileLoginCodeForm.value.Number_Two +
            this.mobileLoginCodeForm.value.Number_One;
          this.verifyMobile(code);
        } else {
          this.alertService.error('لطفا اطلاعات فرم ورود را کامل کنید.', {timeOut:2000});
        }
      }
      this.isSaving = false;
    }
  }
}
