<!-- {{note.title}} -->

<!-- AlertBox -->
<div class="tkt724-alert" style="z-index: 999999;">
    <!-- For better display, the " tkt724-alert_content "  box should be added to the page each time -->
    <!-- INFO = For active Class " tkt724-alert_content " use in class "active" -->
    <div class="tkt724-alert_content {{note.type}}"  *ngFor="let note of notes;let i=index" [ngClass]="{'hiden':note.hidestart,'active':note.active}">
        <div class="tkt724-alert_content__icon">
            <svg viewBox="0 0 48 48.003">
                <path opacity="0" d="M0 0h48v48H0z"/>
                <path d="M44.505 44.798l1.165-1.165 2.333 2.333-1.165 1.165zm-6.991 1.153l2.333-2.333 1.165 1.165-2.333 2.333zm6.7-6.699l2.333-2.333 1.165 1.165-2.333 2.333zM18.95 37.487c0 2.73 2.213 4.944 4.944 4.944s4.944-2.213 4.944-4.943zM2.472 4.943A2.47 2.47 0 0 1 .723.723a2.47 2.47 0 0 1 4.22 1.749c-.002 1.364-1.107 2.469-2.471 2.471zm0-3.3c-.455 0-.824.369-.824.824s.369.824.824.824.824-.369.824-.824a.82.82 0 0 0-.824-.819z" fill="#f4504b"/><path d="M42.019 20.597h-2.472A17.6 17.6 0 0 0 34.36 8.072l1.748-1.748c3.796 3.778 5.925 8.917 5.911 14.273zm-33.779 0H5.768A20.05 20.05 0 0 1 11.68 6.324l1.748 1.748A17.59 17.59 0 0 0 8.24 20.597zm15.653 23.069a6.19 6.19 0 0 1-6.179-6.179h2.472a3.71 3.71 0 0 0 3.707 3.647 3.71 3.71 0 0 0 3.707-3.647h2.472a6.19 6.19 0 0 1-6.18 6.179z"/><path d="M37.487 38.723H10.299c-1.207 0-2.287-.752-2.705-1.885s-.087-2.405.83-3.19c2.496-2.099 3.936-5.195 3.935-8.456v-4.595c0-6.37 5.164-11.534 11.534-11.534s11.534 5.164 11.535 11.534v4.594a11.04 11.04 0 0 0 3.918 8.445c.926.781 1.265 2.056.849 3.194s-1.498 1.894-2.708 1.893zM23.893 11.535c-5.003.005-9.058 4.06-9.063 9.063v4.594a13.5 13.5 0 0 1-4.8 10.332.41.41 0 0 0-.148.316c.002.227.185.41.412.412h27.193c.227-.002.41-.185.412-.412.001-.121-.053-.235-.145-.313a13.51 13.51 0 0 1-4.8-10.335v-4.595c-.006-5.002-4.059-9.056-9.061-9.062z"/>
                <path d="M22.657 5.355h2.472v4.943h-2.472z"/>
            </svg>
        </div>
        <div class="tkt724-alert_content__text" [innerHtml]="note.message"></div>
        <div class="tkt724-alert_content__close" (click)="close(note)">
            <i class="icon-close"></i>
        </div>
    </div>
</div>
<!-- AlertBox Ends -->
