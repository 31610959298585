import {
  Injectable,
  Injector,
  ViewChild,
  isDevMode,
  Directive,
  EventEmitter,
  OnInit,
  Component,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../shared/signalGo/services/authenticationService';
import { UserInformationResponseContract } from '../../../../shared/signalGo/services/Tkt724.Sale.Core.Contracts.Responses.Users/UserInformationResponseContract';
import { PlatformType } from '../../../../shared/signalGo/services/MahdBuraq.Core.DataTypes/PlatformType';
import { AppInitRequestContract } from '../../../../shared/signalGo/services/Tkt724.Hub.IdentityServer.Contracts.Requests/AppInitRequestContract';
import { HUserprofileService } from '../../../../shared/signalGo/services/huserprofileService';
import { AppConsts } from '../../../../shared/AppConsts';
import { take } from 'rxjs/operators';
import { API_BASE_URL_SGO } from 'src/shared/signalGo/server-connection.service';
import { AppType } from 'src/shared/signalGo/services/MahdBuraq.Core.DataTypes/AppType';
import { DatePipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AppStateInterface } from '../../store/app/appstate.interface';
import { Store } from '@ngrx/store';
import { userActions } from '../../store/app/user/controllers/user.action';
import packageJson from '../../../../../package.json';
import { OperatingSystemType } from 'src/shared/signalGo/services/MahdBuraq.Core.DataTypes/OperatingSystemType';

declare var DeviceUUID;

@Injectable()
export class AppSessionService {
  //private _user: UserInformationResponseDto = null;

  private _tenant: any;
  private _application: any;
  private _theme: any;
  // private _userSearchHistoryDto: ResponseForUserSearchHistory;
  //private _profileService : ProfileServiceProxy;
  authserv: AuthenticationService;
  userserv: HUserprofileService;
  platformId: Object;

  constructor(injector: Injector, private store: Store<AppStateInterface>) {
    //this._profileService = injector.get(ProfileServiceProxy);
    this.authserv = injector.get(AuthenticationService);
    this.userserv = injector.get(HUserprofileService);
    this.platformId = injector.get(PLATFORM_ID);
  }
  _user: UserInformationResponseContract = null;
  private isAppInit = false;
  get application(): any {
    return this._application;
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get tenantKey() {
    // if (this.isBrowser) {
    //   if (location.href.toLowerCase().indexOf('tkt724.org') > -1)
    //     return '0FAD68D9-525C-4470-A5A0-A6DC5DF8FE56';
    //   else if (location.href.toLowerCase().indexOf('tkt724.ir') > -1)
    //     return 'B8BC88C8-021A-4CB6-8A60-2B8A12A31976';
    //   if (location.href.toLowerCase().indexOf('webtik.ir') > -1)
    //     return '735A3074-CA12-45A7-B303-EEC3C80E3A29';
    //   if (location.href.toLowerCase().indexOf('meraj.aero') > -1)
    //     return '2061078F-7A0C-4EA7-AE53-9AE23508B5B4';
    //   if (location.href.toLowerCase().indexOf('tkt724.com') > -1)
    //     return '0C72178B-4D44-4745-87C8-3C6A12EC3E68';
    // }
    return '0C72178B-4D44-4745-87C8-3C6A12EC3E68';
  }

  updateUserInformation() {
    this.userserv.getUserInformation().subscribe((o) => {
      if (o.success) {
        if (o.result) {
          this._isAuthenticated = true;
        }
        this.store.dispatch(
          userActions.updateAuthenticated({
            isAuthenticated: this._isAuthenticated,
          })
        );
        this.store.dispatch(
          userActions.updateMobile({ mobile: o.result.mobile })
        );
        this._user = o.result;
        // eval(`webengage.init('in~76aa330');`);
      } else {
        //this._user=null;
      }
      //set user value updateUserInformation
    });
  }
  login_registerIsOpen = false;
  login_registerNumber = '';
  loginCallback: any = () => {};
  logOutSubscription!: Subscription;
  logOutTimeout!: ReturnType<typeof setTimeout>;
  logout() {
    this._user = null;

    this._isAuthenticated = false;
    //** */  abp.utils.deleteCookie("_session");
    // this.isAppInit = false;
    this.store.dispatch(
      userActions.updateAuthenticated({
        isAuthenticated: this._isAuthenticated,
      })
    );
    try{
      if(this.logOutSubscription){
        this.logOutSubscription.unsubscribe();
        clearTimeout(this.logOutTimeout)
      }
        this.logOutSubscription = this.authserv.logout().subscribe((o) => {
        this.logOutTimeout = setTimeout(()=> {this.AppInit(); }, 5000);
      });
    }catch{
    }
  }

  setCookie(name, value, days) {
    if (!this.isBrowser) return;
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }
  AppInit() {
    this.AppInitObs()
      .pipe(take(1))
      .subscribe((o) => {
        this.store.dispatch(
          userActions.updateAuthenticated({
            isAuthenticated: this._isAuthenticated,
          })
        );
      });
  }
  AppInitInProgress = false;

  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  AppInitObs(forceRefresh = false): Observable<any> {
    if (this.AppInitInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.AppInitInProgress = true;
      let AppInitRes = new Subject<void>();
      if (forceRefresh) {
        this._user = null;
        this._isAuthenticated = false;
        this.isAppInit = false;
        // this.logout().toPromise();
      }
      if (!this.isBrowser) {
        //console.log('ignore app init');
        return of(false);
      }
      let appInitRequestDto = new AppInitRequestContract();
      appInitRequestDto.userRoleName = 'Tkt724SaleUser';
      appInitRequestDto.tenantKey = this.tenantKey;
      appInitRequestDto.platformType = PlatformType.Web;
      appInitRequestDto.appType = AppType.EndUser;
      appInitRequestDto.appVersion = Number(
        packageJson.version.replace('.', '').replace('.', '')
      );
      if (this.isBrowser) {
        appInitRequestDto.iMEIId = new DeviceUUID().get();
        let deviceDetail = new DeviceUUID().parse();
        appInitRequestDto.operationSystemInfo = window.navigator.userAgent; //JSON.stringify(deviceDetail) ;
        let strDetail = '';
        strDetail += deviceDetail.isAuthoritative ? 'Authoritative' : 'invalid';
        strDetail += deviceDetail.isBot ? ',Bot' : ',User';
        strDetail +=
          ',' +
          deviceDetail.browser +
          ',' +
          deviceDetail.version +
          ',' +
          deviceDetail.os +
          ',' +
          deviceDetail.platform +
          ',' +
          deviceDetail.language;
        appInitRequestDto.wifiUniqId = strDetail;
        if (deviceDetail.platform) {
          if (deviceDetail.platform.includes('Windows')) {
            appInitRequestDto.operationSystemType = OperatingSystemType.Windows;
          } else if (deviceDetail.platform.includes('Linux')) {
            appInitRequestDto.operationSystemType = OperatingSystemType.Linux;
          } else if (deviceDetail.platform.includes('Mac')) {
            appInitRequestDto.operationSystemType = OperatingSystemType.Mac;
          } else if (
            deviceDetail.platform.includes('Android') ||
            deviceDetail.platform.includes('Samsung')
          ) {
            appInitRequestDto.operationSystemType = OperatingSystemType.Android;
          } else if (
            deviceDetail.platform.includes('iPad') ||
            deviceDetail.platform.includes('iPhone')
          ) {
            appInitRequestDto.operationSystemType = OperatingSystemType.IOS;
          } else {
            appInitRequestDto.operationSystemType = OperatingSystemType.Other;
          }
        }
        appInitRequestDto.operationSystemVersion = deviceDetail.os;
      }
      // appInitRequestDto.iMEIId = Client
      //    appInitRequestDto.operationSystemApiVersion

      // console.log(window.navigator.userAgent.toLowerCase()); console.log(this.findver_browser()); console.log(this.findname_browser());
      appInitRequestDto.projectId =
        AppConsts.remoteServiceBaseUrlSGO == 'mahdburaq'
          ? 2
          : AppConsts.remoteServiceBaseUrlSGO == 'localhost'
          ? 1
          : 1;
      // debugger
      this.authserv.appInit(appInitRequestDto).subscribe(
        (o) => {
          if (o.success) {
            if (AppConsts.remoteServiceBaseUrlSGO == 'localhost') {
              this.setCookie('_session', o.result.sessionKey, 1);
            }
            this.isAppInit = true;
            this._isAuthenticated = o.result.isLogin;
            this.tokenRefreshedSource.next();
            AppInitRes.next();
            AppInitRes.complete();
            this.AppInitInProgress = false;
            if (this._isAuthenticated) this.updateUserInformation();
            // else  eval(`webengage.init('in~76aa330');`);
          } else {
            console.error('appinit fail1');
            this.isAppInit = true;
            this.AppInitInProgress = false;
            //** */         abp.utils.deleteCookie("_session");
            AppInitRes.next();
            AppInitRes.complete();
            this.logout();
          }
        },
        (err) => {
          console.error('appinit fail2');
          this.AppInitInProgress = false;
          this.isAppInit = true;
          AppInitRes.next(err);
          this.logout();
        }
      );
      return AppInitRes.asObservable();
    }
  }
  findname_browser() {
    if (this.isBrowser && window) {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edge') > -1:
          return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
          return 'chrome';
        case agent.indexOf('trident') > -1:
          return 'ie';
        case agent.indexOf('firefox') > -1:
          return 'firefox';
        case agent.indexOf('safari') > -1:
          return 'safari';
        default:
          return 'other';
      }
    }
  }

  findver_browser() {
    var userAgent = navigator.userAgent,
      tem,
      matchTest =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }

    matchTest = matchTest[2]
      ? [matchTest[1], matchTest[2]]
      : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
      matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
  set application(val: any) {
    this._application = val;
  }

  get user() {
    return this._user;
  }

  get userCredit() {
    if (this.user) return this.user.credit;
    return 0;
  }

  get successedOrdersCount() {
    if (this.user) return this.user.successedOrdersCount;
    return 0;
  }

  get userProfile(): any {
    return null; //this._userProfile;
  }

  _isAuthenticated = false;
  get isInit(): boolean {
    return this.isAppInit;
  }
  get isAuthenticated(): boolean {
    // while(this.AppInitInProgress)
    //      this.delay(100);
    return this._isAuthenticated;
  }
  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  get notAuthenticated(): boolean {
    return this._isAuthenticated;
  }
  // get getToken(): string {
  //     return abp.auth.getToken();
  // }

  // get tokenOption():any{
  //     let options_: any = {
  //         headers: new HttpHeaders({
  //           "Content-Type" : "application/json",
  //           'Authorization': 'Bearer ' + this.getToken

  //         })
  //     };
  //     return options_ ;
  // }

  get tenant(): any {
    return this._tenant;
  }

  get tenancyName(): string {
    return this._tenant ? this.tenant.tenancyName : '';
  }

  get tenantId(): number {
    return this.tenant ? this.tenant.id : null;
  }

  // getShownLoginName(): string {
  //     const userName = this._user.userName;
  //     if (!this._abpMultiTenancyService.isEnabled) {
  //         return userName;
  //     }

  //     return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
  // }

  get theme(): any {
    return this._theme;
  }

  set theme(val: any) {
    this._theme = val;
  }

  private isCurrentTenant(tenantId?: number) {
    let isTenant = tenantId > 0;

    if (!isTenant && !this.tenant) {
      // this is host
      return true;
    }

    if (!tenantId && this.tenant) {
      return false;
    } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
      return false;
    }

    return true;
  }
}
