import { NgModule } from '@angular/core';
import { FlightRoutingModule } from './flight-routing.module'
import { UtilityModule } from '../../shared/utility.module'
import { CommonModule } from '@angular/common';
import { FlightLocalServices } from 'src/app/core/services/flight/flight.service';
import { ContentService } from 'src/app/core/services/seo.service';
import { AppInitCallGuard } from '../../auth-routing';

@NgModule({
  imports: [
    UtilityModule,
    CommonModule,
    FlightRoutingModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    FlightLocalServices,
    ContentService,
    AppInitCallGuard
  ],
})
export class FlightModule { }
