import {Component, Injector, ElementRef, ViewEncapsulation} from '@angular/core';
import { AlertService } from 'src/app/core/services/app/alert/alert.service';
import { SetTitleDec } from 'src/app/core/services/app/setTitleDec.service';
import { AppComponentBase } from '../../../app/app-component-base';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ProfileComponent extends AppComponentBase {
  pageIndex: number = 1;
  noMore: boolean = false;
  pageLength: number = 10;
  isSaving: boolean = false;
  alertService: AlertService;
  seo: SetTitleDec;

  constructor(public injector: Injector, public el: ElementRef) {
    super(injector);
    this.alertService = injector.get(AlertService);
    this.seo = injector.get(SetTitleDec);
    // Seo
    this.seo.setTitleDec({pageTitle:'پروفایل', description:'', canonical:'', robots:this.seo.noIndex});
    // Seo
  }

  getData(i=1){}

  loadMore(){
    this.getData(this.pageIndex+1);
  }

  comingsoon() {
    this.alertService.info('این ویژگی بزودی در سایت افزوده می شود', {
      timeOut: 5000,
    });
  }

}
