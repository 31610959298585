import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TokenService } from 'src/shared/signalGo/services/Api/TokenService';
import { firstValueFrom } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CSRFFromService {
  /**
   *
   */
  _isBrowser = false;
  constructor(private injector: Injector) {
    this._isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
  }

  async _getFromToken(): Promise<string> {
    let csrfFromService;
    if (this._isBrowser) {
      let tokenService=this.injector.get(TokenService);
      const response = await firstValueFrom(tokenService.getToken());//.toPromise();
      csrfFromService = response.token ;
    } else if (!this._isBrowser) {
      return 'Server';
    }
    return csrfFromService;
  }
   get(): Promise<string> {
    return  this._getFromToken();
  }
}
