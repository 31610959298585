import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tom'
})
export class TomanPipe implements PipeTransform {
  transform(key: string, ...args: any[]): string {
      key = key || '';
      // const ret = key;
      const ret = key.slice(0, key.length - 1);
      if (ret === '')
          return '0';
      return ret;
  }
}
