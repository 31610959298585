import { Injector,Injectable,PLATFORM_ID } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppSessionService } from "./core/services/app/app-session.service";
import { AppComponentBase } from './app-component-base';

@Injectable()
export class LoginRequiredGuard extends AppComponentBase implements CanActivate {

    constructor(public injector: Injector) {
        super(injector);
    }
    canActivate():boolean | Observable<boolean>{
       // return true;
        if(!this.isBrowser)  return true;
        if (this.appSession.isAuthenticated){
            // console.log('gurd app isAuthenticated');
            return true;
        }
        return new Observable(observer => {
            this.appSession.AppInitObs().subscribe(
                () => 
                        {
                            if (this.appSession.isAuthenticated){
                                // console.log('gurd app init run success');
                                observer.next(true);
                                observer.complete();
                            }
                            else{
                                if (!this.isAppInit) {
                                    this.appSession.AppInitObs().subscribe(o => {
                                        if (!this.isAuthenticated)
                                            this.showLoginForm();
                                    });
                                }
                                else if (!this.isAuthenticated)
                                    this.showLoginForm();
                                observer.next(true);
                                observer.complete();
                                //this.router.navigate(['/']);
                            }
                        },
                (err)=>{          
                    observer.next(false);
                    observer.complete();
                    this.router.navigate(['/']);
                    }
            );
        });
        //     return true;
        // }
        // //Redirect the user before denying them access to this route
        // this.router.navigate(['/']);
        // return false;
    }
}
@Injectable()
export class AppInitRequiredGuard extends AppComponentBase implements CanActivate {

    constructor(public injector: Injector) {
        super(injector);
    }
    canActivate():boolean | Observable<boolean>{
        //return true;
        // if(!this.isBrowser)  return true;
        if (this.isAppInit){
            return true;
        }
        return new Observable(observer => {
            this.appSession.AppInitObs().subscribe(() => {
                    observer.next(true);
                    observer.complete();
            },err=>{          
                 observer.next(false);
                 observer.complete();
                 this.router.navigate(['/']);
                });
        });
    }
}
@Injectable()
export class AppInitCallGuard extends AppComponentBase implements CanActivate {

    constructor(public injector: Injector) {
        super(injector);
    }
    canActivate():boolean | Observable<boolean>{
        if(!this.isBrowser)  return true;
        if (!this.isAppInit){
            this.appSession.AppInitObs().subscribe(() => {});
        }
        return true;
    }
}