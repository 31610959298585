import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UUIDFromStorage } from '../../models/UuidGenerator';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TracingDeviceInterceptor implements HttpInterceptor {
  /**
   *
   */
  _isBrowser = false;
  constructor(private uuidFromStorage: UUIDFromStorage,injector: Injector) {
    this._isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this._isBrowser) return next.handle(req);
    let newReq: HttpRequest<any>;
    if (!req.headers.has('TraceId'))
      newReq = req.clone({ headers: req.headers.set('TraceId', this.uuidFromStorage.get()) });
    if (!newReq) newReq = req;
    return next.handle(newReq);
  }
}
