import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { ContentService } from './../../seo.service';
import { AppInitCallGuard } from '../../../app/auth-routing';
import { QueryAndStoreGuard } from './searchFlight/shared/guard/query-and-store.guard';
const routes: Routes = [
  {
    path: 'flight',
    children: [
      {
        path: 'refund-ticket',
        loadChildren: () => import('./refund/flightRefundPage.module').then(m => m.flightRefundPageModule),
        canActivate: [
          ContentService,
          AppInitCallGuard
        ]
      },
      {
        path: ':fromto',
        loadChildren: () => import('./fromto/flightPathComponent.module').then(m => m.flightPathModule),
        canActivate: [
          ContentService,
          AppInitCallGuard
        ],
        data: { contentType: 'flight/', activetab: 'flight' }
      },
    ]
  },
  {
    path: 'searchFlight/:fromto',
    loadChildren: () => import('./searchFlight/searchflight.module').then(m => m.SearchFlightModule),
    data: { isInside: true }
  },
  {
    path: 'searchFlight/passenger/:fromto',
    loadChildren: () => import('./searchFlight/passenger/passenger.module').then(m => m.PassengerModule),
    canActivate: [QueryAndStoreGuard],
    data: { isInside: true }
  },
  {
    path: 'searchFlight/confirm/:fromto',
    loadChildren: () => import('./searchFlight/confirm/confirm.module').then(m => m.ConfirmModule),
    canActivate: [QueryAndStoreGuard],
    data: { isInside: true }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlightRoutingModule { }
