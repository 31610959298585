import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HotelPurchaseOrderDetailContract } from 'src/app/core/mocks/hotel/class/hotel-purchase/hotel-purchase-order-detail-contract.class';
import { HotelTransferDataModel } from 'src/app/core/mocks/hotel/class/hotel-purchase/hotel-transfer-data.class';
import { HotelOrderContract } from 'src/app/core/mocks/hotel/class/hotel-purchase/hotel-order-contract.class';
import { HotelFacilityTransfer } from 'src/app/core/mocks/hotel/class/hotel-purchase/hotel-facility.class';

export const hotelPurchaseActions = createActionGroup({
    source: 'Hotel Purchase',
    events: {
        'Hotel Order': props<{
            hotelOrder: HotelOrderContract,
        }>(),
        'Hotel Transfer': props<{
            hotelTransfer: HotelTransferDataModel
        }>(),
        'Hotel Purchase Order': props<{
            hotelPurchaseOrder: Partial<HotelPurchaseOrderDetailContract>
        }>(),        
        'Hotel Facility': props<{
            hotelFacility: HotelFacilityTransfer
        }>(),        
        'Read All': emptyProps(),
        'Reset All': emptyProps(),
    },
});
