import { Injector, ViewChild, isDevMode, Directive, EventEmitter, OnInit, Component, PLATFORM_ID, OnDestroy } from "@angular/core";
import { AppConsts } from "../shared/AppConsts";
import { AppSessionService as appSession2 }  from "./core/services/app/app-session.service";
import { AppSessionService } from "./core/services/app/app-session.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe, DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Title, Meta } from '@angular/platform-browser';
import { FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, map } from "rxjs/operators";

import Swal from 'sweetalert2';

import { JalaliPipe } from '../shared/pipes/localize.pipe';

import { HotelavailabilityService } from "../shared/signalGo/services/hotelavailabilityService";
import { LocationSearchRequestContract } from "../shared/signalGo/services/Tkt724.Sale.HotelCore.Contracts.Requests/LocationSearchRequestContract";
import { FlightairportService } from "../shared/signalGo/services/flightairportService";
import { ContentService } from "../shared/utils/seo.service";
import { GenderType } from "../shared/signalGo/services/Tkt724.Sale.Core.DataTypes/GenderType";
import { HUserprofileService } from "../shared/signalGo/services/huserprofileService";
import { PublicAppService } from "./core/services/app/app.service";
import { PublicAppSignalService } from "./core/services/app/public-app-signal.service";
import { isDate, ServerConnectionService } from "src/shared/signalGo/server-connection.service";
import * as moment from 'moment';
import { WagonGenderType } from "src/shared/signalGo/services/Tkt724.Sale.TrainCore.DataTypes/WagonGenderType";
import { NavigationService } from "./core/services/app/navigation.service";
import { environment } from "src/environments/environment";
declare var abp: any;
declare var $: any;
export class Guid {
  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}

export function matchFullString(text: string, pattern: string): boolean {
  if (!text)
    return undefined;
  let match = text.match(pattern);
  if (!match || match == null || text != match['0'])
    return undefined;
  return true;
}

@Directive()
export abstract class AppComponentBase implements OnDestroy {
  jalaliPipe = new JalaliPipe();
  metaService: Meta;
  titleService: Title;
  userserv: HUserprofileService;
  ContentService: ContentService;
  navigationService: NavigationService;
  platformId: Object;
  server: ServerConnectionService;
  appSession2: appSession2
  constructor(
    public injector: Injector,
    ) {
    this.metaService = injector.get(Meta);
    this.titleService = injector.get(Title);
    this.server = injector.get(ServerConnectionService);
    this.appSession = injector.get(AppSessionService);
    this.flightAirportService = injector.get(FlightairportService);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.publicAppService = injector.get(PublicAppService);
    this.publicAppSignalService = injector.get(PublicAppSignalService);
    this.navigationService = injector.get(NavigationService);
    this.todayDate.setHours(0, 0, 0, 1);

    this.hotelServices = injector.get(HotelavailabilityService);
    this.dom = injector.get(DOCUMENT) as Document;
    this.ContentService = injector.get(ContentService)
    this.userserv = injector.get(HUserprofileService);
    this.appSession2 = injector.get(appSession2)
    // if (this._title != '') {
    //     this.titleService.setTitle(this._title);
    // }
    this.platformId = injector.get(PLATFORM_ID);
    this.navigationService.backClicked.subscribe(o=>{
      this.backClicked();
    });
  }

  get matacoir() {
    return this.sitename().toLowerCase().indexOf('mataco.ir') > -1;
  }
  get tkt724ir() {
    return this.sitename().toLowerCase().indexOf('tkt724.ir') > -1;
  }
  get tkt724com() {
    return this.sitename().toLowerCase().indexOf('tkt724.com') > -1;
  }
  get tkt724org() {
    return this.sitename().toLowerCase().indexOf('tkt724.org') > -1;
  }

  backClicked(){};
  getSearchURL(){
    if(this.isBrowser){
      return location.href;
    }
    return '';
  }
  ngOnDestroy(): void {
    if (this.logincheckinterval) {
      clearInterval(this.logincheckinterval);
    }
  }

  isEmpty(str) {
    return (!str || str.length === 0 );
  }

  dom: Document;
  defaultRobots = environment.defaultRobots;
  noIndex = "noindex, nofollow";

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }
  setTitleDec(pageTitle = 'سامانه تیکت 724', description = '', canonical = '', robots = '') {
    this.titleService.setTitle(pageTitle);
    this.metaService.removeTag(`property="og:title"`);
    this.metaService.removeTag(`name="twitter:title"`);
    this.metaService.addTag({ property: "og:title", content: pageTitle }, true);
    this.metaService.addTag({ name: "twitter:title", content: pageTitle }, true);

    this.metaService.removeTag(`name="description"`);
    this.metaService.removeTag(`property="og:description"`);
    this.metaService.removeTag(`name="twitter:description"`);
    this.metaService.addTag({ name: "description", content: description }, true);
    this.metaService.addTag({ property: "og:description", content: description }, true);
    this.metaService.addTag({ name: "twitter:description", content: description }, true);
    var canonicalUrl = "https://tkt724.com/" + canonical;
    if(canonicalUrl.endsWith('/'))
      canonicalUrl= canonicalUrl.substring(0,canonicalUrl.length-1);
    this.updateCanonicalUrl(canonicalUrl);
    this.metaService.updateTag({ content: canonicalUrl , property: "og:url" });

    // this.metaService.removeTag(`name="robots"`);
    // if (robots != '') {
    //   this.metaService.addTag({ name: "robots", content: robots }, true);
    // }
  }
  sitename() {
    if (this.isBrowser) {
      return location.hostname;
    }
    return 'yazdairlines.com'
  }
  comingsoon() {
    this.success('این ویژگی بزودی در سایت افزوده می شود', '', 5000);
  }
  navIfLogin(url) {
    this.runWithLogin(() => this.router.navigate([url]));
  }
  runWithLogin(funcToRun,login_registerNumber='') {
    // if (!this.isAppInit && this.appSession.AppInitInProgress)
    //     while (this.appSession.AppInitInProgress) {

    //     }
    if (this.isBrowser) {
      if (this.isAuthenticated) {
        funcToRun();
      } else {
        this.showLoginForm(funcToRun,login_registerNumber);
      }
    }

  }
  logincheckinterval = null;
  trytoAppInit = false;
  waitForLogin(funcToRun) {

    if (this.isBrowser) {
      if (!this.isAppInit && !this.trytoAppInit) {
        this.trytoAppInit = true;
        this.appSession.AppInitObs().subscribe(o => {
          if (!this.isAuthenticated)
            this.showLoginForm();
        });
      }
      else if (!this.isAuthenticated)
        this.showLoginForm();
      this.logincheckinterval = setInterval(() => {
        if (this.isAuthenticated) {
          clearInterval(this.logincheckinterval);
          funcToRun();
        }
      }, 1000);
    }
  }

  //استخراج کوئری پارامتر ها به دلیل اینکه کوئری پارامتر های انگولار بسیار کند است
  getQueryParams() {
    if (!location?.search)
      return undefined;
    let dictionaryResult = {};
    let seperate = location.search.split('?');
    if (seperate.length > 1) {
      let keyValues = seperate[1].split('&');
      keyValues.forEach(keyValue => {
        var seperatedKeyValue = keyValue.split('=');
        if (seperatedKeyValue.length == 2)
          dictionaryResult[seperatedKeyValue[0].toLowerCase()] = seperatedKeyValue[1];
      });

      return dictionaryResult;
    }
    else
      return undefined;
  }
  defualtCancelationRule=  ['با توجه به متغییر بودن نرخ جریمه استرداد،  پس از ثبت درخواست استرداد، شرایط و یا میزان جریمه کنسلی از سوی تیم پشتیبانی تیکت 724 به شما اعلام خواهد شد. در صورت نیاز به کسب اطلاعات بیشتر از طریق بخش "تماس با ما" با همکاران ما تماس بگیرید.'];
  get user_fullname() {
    if (this.appSession != null) {
      if (this.appSession.userProfile != null) {
        if (this.appSession.userProfile.firstName || this.appSession.userProfile.lastName) {
          return (this.appSession.userProfile.firstName + " " + this.appSession.userProfile.lastName);
        } else if (this.appSession.userProfile.englishFirstName || this.appSession.userProfile.englishLastName) {
          return (this.appSession.userProfile.englishFirstName + " " + this.appSession.userProfile.englishLastName)
        } else if (this.appSession.userProfile.emailAddress) {
          return (this.appSession.userProfile.emailAddress)
        } else {
          return ""
        }
      }
    }
    return "";
  }

  get user_imageSrc() {
    if (this.appSession != null) {
      if (this.appSession.userProfile != null) {
        if (this.appSession.userProfile.profileImage) {
          return this.appSession.userProfile.profileImage.url;
        }
      }
    }
    return '';
  }

  get usertype() {
    if (this.appSession != null) {
      if (this.appSession._user != null) {
        // return this.appSession.user.userType;
        return this.appSession._user.userName;
      }
    }
    return 0;
  }
  get isDev() {
    return isDevMode();
  }

  get userCredit() {
    return this.appSession.userCredit
  }

  get successedOrdersCount() {
    return this.appSession.successedOrdersCount
  }
  getGMTZeroTime(t) {
    if (!t)
      return t;
    let utc = null;
    // if (isDate(t))
      utc =new Date(t.toUTCString().substr(0, 25));
    // else
      // utc =new Date(t.substr(0, 25));
    return utc;
  }
  UpdateCredit() {
    if (this.isBrowser) {
      this.appSession.updateUserInformation();
    }
  }
  get Countries() {
    return this.publicAppSignalService.countries
  }
  countryName(c, shortName) {
    return c.find(o => o.shortName == shortName)?.name ?? shortName;
  }
  nationalityTypeOptions = [];
  loadnationalityTypeService() {
    // if (!this.nationalityTypeOptions.length)
    //   this.publicAppSignalService.getDataTypes('NationalityType').subscribe(c => {
    //     if (c.success) {
    //       this.nationalityTypeOptions = c.result.data.sort((a, b) => { return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0) });
    //     } else {
    //       this.danger(c.error.message, '', 2000);
    //     }
    //   })
  }
  nationalName(nationalId) {
    return this.nationalityTypeOptions.find(o => o.value == nationalId)?.name ?? nationalId;
  }
  log(l, txt?) {
    if (this.isDev) console.log(txt, l);
  }
  abs(x){
    return Math.abs(x);
  }
  Clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  assignObject(obj) {
    let result = this.Clone(obj);
    this.server.fixDateTimeProperties(result, []);
    return result;
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    if(this.isBrowser)
     document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  getCookie(name) {
    if(this.isBrowser) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

  }
  eraseCookie(name) {
    if(this.isBrowser)
     document.cookie = name + '=; Max-Age=-99999999;';
  }


  //add from home
  tenantId = 0;
  historyOrigin: string = '';
  historyOriginText: string = '';
  historyDestination: string = '';
  historyDestinationText: string = '';

  historyDepartstr: string = '';

  historyAdultCount: number = 1;
  historyChildCount: number = 0;
  historyInfantCount: number = 0;

  historySearchType: string = 'flight';
  historyflightSearchRequest1: any = [];
  historyHotelSearchRequest: any = [];

  hotelSearchRequestString: LocationSearchRequestContract;

  isFlightHistoryAvailable: boolean = false;
  isHotelHistoryAvailable: boolean = false;


  hotelOriginSubject = new Subject();

  loadingOriginHotel = false;


  isDestinationSelectedHotel = false;
  afterResponceDestinationHotel = false;

  //hotel
  isHotelDefaultDialog = true;
  _title = ' تیکت 724';
  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
    this.titleService.setTitle(this._title);
    this.metaService.updateTag({ content: value, name: 'twitter:title' });
    this.metaService.updateTag({ content: value, property: 'og:title' });
  }

  // set description(value) {
  //     this.metaService.updateTag({ content: value, name: 'description' });
  //     this.metaService.updateTag({ content: value, name: 'twitter:description' });
  //     this.metaService.updateTag({ content: value, property: 'og:description' });
  // }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isMobileSize() {
    if (this.isBrowser)
      if (window)
        return window.innerWidth < 1000;
    return false;
  }
  //مقایسه دو رشته با هم
  compareTwoString(value1: string, value2: string, isCaseSensitive: boolean) {
    if (isCaseSensitive)
      return value1 === value2;
    else
      return value1?.toUpperCase() === value2?.toUpperCase();
  }
  round1000(price) {
    return Math.floor(price / 1000);
  }
  round10000(price) {
    return Math.floor(price / 10000);
  }
  get isUtravs() {
    if (this.isBrowser) {
      if ((location.href.toLowerCase().indexOf('localhost:4200') > -1))
        return false;
      if ((location.href.toLowerCase().indexOf('beta.tkt724') > -1))
        return false;
      if ((location.href.toLowerCase().indexOf('mahdburaq') > -1))
        return false;
      if ((location.href.toLowerCase().indexOf('tkt724') > -1))
        return true;
    }

    return true;

  }
  //
  activatedRoute: ActivatedRoute;


  get isAuthenticated(): boolean {
    return this.appSession.isAuthenticated;
  }
  get notAuthenticated(): boolean {
    return this.appSession.notAuthenticated;
  }
  get isAppInit(): boolean {
    return this.appSession.isInit;
  }

  userClaimMobile=null;
  get userMobile() {
    if (this.appSession.isAuthenticated && this.appSession.user) {
      return this.appSession.user.mobile;
    } else {
      return this.userClaimMobile;
    }
  }

  get userEmailAddress() {
    if (this.appSession.isAuthenticated && this.appSession.user) {
      return this.appSession.user.emailAddress;
    } else {
      return null;
    }
  }

  get userInfo() {
    if (this.appSession.isAuthenticated && this.appSession.user) {
      if (this.appSession._user.firstName != '' || this.appSession._user.lastName != '')
        return this.appSession._user.firstName + ' ' + this.appSession._user.lastName;
      return this.appSession._user.userName;
    }
    return '......';
  }
  get userName() {
    if (this.appSession.isAuthenticated && this.appSession.user) {
      return this.appSession._user.userName;
    }
    return '......';
  }
  logout() {
    this.userClaimMobile = null;
    this.publicAppSignalService.Passengerlist = [];
    this.appSession.logout();
    //eval('webengage.user.logout();');
    if (this.router.url.toLowerCase().indexOf('/profile') > -1)
      this.router.navigate(['/']);
  }

  successLastMessage: string = '';
  infoLastMessage: string = '';
  warningLastMessage: string = '';
  dangerLastMessage: string = '';


  isTodayDateClicked: boolean = false;
  flightAirportService: FlightairportService;
  todayDate = new Date();
  rangeSelectorBase = false;
  localizationSourceName =
    AppConsts.localization.defaultLocalizationSourceName;
  picurl = AppConsts.hotelBedCdn;

  // permission: PermissionCheckerService;
  // feature: FeatureCheckerService;
  // notify: NotifyService;
  // setting: SettingService;
  // message: MessageService;
  // multiTenancy: AbpMultiTenancyService;
  appSession: AppSessionService;
  public router: Router;
  urlShare: string;
  emailstring;
  bufferSize: number = 10;
  hotelServices: HotelavailabilityService;
  publicAppService: PublicAppService;
  publicAppSignalService: PublicAppSignalService;
  // l(key: string, ...args: any[]): string {
  //     args.unshift(key);
  //     args.unshift(this.localizationSourceName);
  //     return this.ls.apply(this, args);
  // }
  regex: RegExp = new RegExp(/^[0-9}{÷$&+×؟،؛,:,;=?@#~`_|<>-^*()%!/ـ«»"]$/);
  public regexHotel: RegExp = new RegExp(/^[}{÷$&+×؟،؛,:,;=?@#~`_|<>-^*()%!/ـ«»"]$/);

  public regexEmail: RegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);


  // ls(sourcename: string, key: string, ...args: any[]): string {
  //     let localizedText = key;//this.localization.localize(key, sourcename);

  //     if (!localizedText) {
  //         localizedText = key;
  //     }

  //     if (!args || !args.length) {
  //         return localizedText;
  //     }

  //     args.unshift(localizedText);
  //     return abp.utils.formatString.apply(this, args);
  // }

  imgSrc(afile) {
    const file = afile ? afile.file : null;
    if (!file) {
      return (
        AppConsts.cdnBaseUrl +
        "/Storage/DownloadFile?fileId=1&password=123"
      );
    }
    return (
      AppConsts.cdnBaseUrl +
      "/Storage/DownloadFile?fileId=" +
      file.id.toString() +
      "&password=" +
      file.password
    );
  }

  logoImageSrc(afile) {
    const file = afile ? afile.logoImage : null;
    if (!file) {
      return (
        AppConsts.cdnBaseUrl +
        "/Storage/DownloadFile?fileId=1&password=123"
      );
    }
    return (
      AppConsts.cdnBaseUrl +
      "/Storage/DownloadFile?fileId=" +
      file.id.toString() +
      "&password=" +
      file.password
    );
  }

  getTenDigitMobileNumber(mb) {
    let mobile = '';
    if (mb) {
      mb = toEnglishNumber(mb);
      mobile = (mb + '').substr(mb.length - 10);
    }

    return mobile;
  }

  getMobileNumberDialingCode(mb) {
    let dialCode = '+98';
    if (mb && mb.length > 10) {
      mb = toEnglishNumber(mb);
      dialCode = (mb + '').substring(0, mb.length - 10)
    }
    return dialCode;
  }

  navigateToProfile() {
    this.router.navigate(['preview'])
  }

  smallLogoImageSrc(afile) {
    const file = afile ? afile.smallLogoImage : null;
    if (!file) {
      return (
        AppConsts.cdnBaseUrl +
        "/Storage/DownloadFile?fileId=1&password=123"
      );
    }
    return (
      AppConsts.cdnBaseUrl +
      "/Storage/DownloadFile?fileId=" +
      file.id.toString() +
      "&password=" +
      file.password
    );
  }

  ld(str: any): string {
    try {
      var ide = {
        "۰": "0",
        "۱": "1",
        "۲": "2",
        "۳": "3",
        "۴": "4",
        "۵": "5",
        "۶": "6",
        "۷": "7",
        "۸": "8",
        "۹": "9",
      };
      var idp = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
      if (abp.localization.currentLanguage.name == "fa") {
        return str.toString().replace(/[0-9]/g, function (w) {
          return idp[+w];
        });
      }
      return str.toString().replace(/[^0-9.]/g, function (w) {
        return ide[w] || w;
      });
    } catch (error) {
      //console.log(error);
    }
  }
  // isGranted(permissionName: string): boolean {
  //     return this.permission.isGranted(permissionName);
  // }

  // isGrantedAny(...permissions: string[]): boolean {
  //     if (!permissions) {
  //         return false;
  //     }
  //     for (const permission of permissions) {
  //         if (this.isGranted(permission)) {
  //             return true;
  //         }
  //     }

  //     return false;
  // }

  s(key: string): string {
    return abp.setting.get(key);
  }


  LoadOrNeed(varb, key: string) {
    const lsdata = localStorage.getItem(key) || "";
    if (lsdata !== "") {
      varb = JSON.parse(lsdata);
      return false;
    }
    return true;
  }
  get notes() {
    return this.publicAppService.notes;
  }
  confirm(message, title, isHtml, callback, opts) {

    opts = opts || {};
    opts.title = title ? title : abp.localization.abpWeb('AreYouSure');
    opts.type = 'warning';

    opts.confirmButtonText = opts.confirmButtonText || abp.localization.abpWeb('Yes');
    opts.cancelButtonText = opts.cancelButtonText || abp.localization.abpWeb('Cancel');
    opts.showCancelButton = true;

    if (isHtml) {
      opts.html = message;
    } else {
      opts.text = message;
    }


    return Swal.fire(opts).then(function (result) {
      callback && callback(result.value);
    });
  }
  unotify(type, message, title, timeout = 2000) {
    if (this.publicAppService.notes.findIndex(o => o.title == title && o.message == message && o.type == type) > -1)
      return;
    let guid = Guid.newGuid();
    this.publicAppService.notes.push({
      type: type,
      message: message,
      title: title,
      id: guid,
      active: true,
      hidestart: false,
    });
    setTimeout(() => {
      let isIs = this.publicAppService.notes.findIndex(
        (o) => o.id === guid
      );
      if (isIs >= 0) {
        this.publicAppService.notes[isIs].active = false;
        this.publicAppService.notes[isIs].hidestart = true;
        setTimeout(() => {
          this.publicAppService.notes.splice(isIs, 1);
        }, 500);

      }
    }, timeout);
  }

  getGenderType(g) {
    switch (g) {
      case GenderType.None:
        return '';
      case GenderType.Male:
        return 'آقا';
      case GenderType.Female:
        return 'خانم';
      case GenderType.Other:
        return 'غیره';
      default:
        return '';
    }
  }


  success(message, title, timeout) {
    if (this.successLastMessage != message) {
      this.unotify("success", message, title, timeout);
      this.successLastMessage = message;
      setTimeout(() => { this.successLastMessage = '' }, timeout);
    }
  }
  info(message, title, timeout) {
    if (this.infoLastMessage != message) {
      this.unotify("info", message, title, timeout);
      this.infoLastMessage = message;
      setTimeout(() => { this.infoLastMessage = '' }, timeout);
    }
  }
  warning(message, title, timeout) {
    if (this.warningLastMessage != message) {
      this.unotify("warning", message, title, timeout);
      this.warningLastMessage = message;
      setTimeout(() => { this.warningLastMessage = '' }, timeout);
    }
  }
  danger(message, title, timeout) {
    if (this.dangerLastMessage != message) {
      this.unotify("danger", message, title, timeout);
      this.dangerLastMessage = message;
      setTimeout(() => { this.dangerLastMessage = '' }, timeout);
    }
  }
  loginState: string = 'regularLogin';
  registerState: string = 'regularRegister';
  Captcha: any;
  // loginModel: LoginModel = {
  //     userNameOrEmailAddress: "",
  //     password: "",
  //     captchaId: "",
  //     captchaResponse: "",
  // };
  get getTenant() { return this.appSession ? this.appSession.tenantId : 0; }

  get login_registerIsOpen() {
    return this.appSession.login_registerIsOpen;
  }
  set login_registerIsOpen(value) {
    this.appSession.login_registerIsOpen = value;
  }
   get login_registerNumber() {
    return this.appSession.login_registerNumber;
  }
  set login_registerNumber(value) {
    this.appSession.login_registerNumber = value;
  }
  showLoginForm(loginCallback: any = () => { },login_registerNumber='') {
    console.log(loginCallback)
    this.login_registerNumber=login_registerNumber;
    this.login_registerIsOpen = true;
    this.appSession.loginCallback = loginCallback;

    this.appSession2.login_registerNumber = login_registerNumber
    this.appSession2.login_registerIsOpen = true
    this.appSession2.loginCallback = loginCallback
  }
  closeLoginForm(success = false) {
    this.login_registerIsOpen = false;
    setTimeout(() => {
      if (success)
        this.appSession.loginCallback();
    }, 300);
  }
  getMyCredit() {
    // return localStorage.getItem('Credit');
    if (this.appSession) {
      if (this.appSession._user) {
        return (this.appSession._user.credit);
      }
    }
    return 0;
  }
  addDate(date, days) {
    return moment(date).add(days, 'days');
  }
  wrongDateSelected(err) {
    this.danger(err, '', 3000);
  }

  removemodalbackdrop(name) {
    const elm = document.querySelector("div." + name + "");
    if (elm) {
      document.querySelector("div." + name + "").classList.remove('fade');
      document.querySelector("div." + name + "").classList.remove('show');
      document.querySelector("div." + name + "").classList.remove(name);
    }
  }

  flightClassName(c) {
    if (c === "Economy") {
      return "اکونومی";
    } else if (c === "Business") {
      return "بیزینس";
    } else if (c === "First Class" || c === "FirstClass") {
      return "فرست کلاس";
    }
    else if (c !== "") {
      return c;
    }
    return "نامشخص";
  }
  roomCount = [
    { code: 0, name: "اول" },
    { code: 1, name: "دوم" },
    { code: 2, name: "سوم" },
    { code: 3, name: "چهارم" },
    { code: 4, name: "پنجم" },
    { code: 5, name: "ششم" },
    { code: 6, name: "هفتم" },
    { code: 7, name: "هشتم" },
    { code: 8, name: "نهم" },
    { code: 9, name: "دهم" },
  ]
  hotelSearchRequestSelectDate: any;
  nowDate = new Date();
  nextDate = new Date(new Date().setDate(new Date().getDate() + 1));
  _today = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate());

  get kidAgeOptions() {
    return ["y", "a", "A", "l", "i", "M", "a", "d", "a", "d", "i", "i"]
  }
  isFull: boolean = false;

  @ViewChild("flightfromcity", { static: false }) flightfromcity: any;
  @ViewChild("flighttocity", { static: false }) flighttocity: any;
  @ViewChild("flightdate", { static: false }) flightdate: any;
  @ViewChild("hoteldatefromto", { static: false }) hoteldatefromto: any;

  hotelinput$ = new Subject<any>();
  flightFrominput$ = new Subject<any>();
  flightToinput$ = new Subject<any>();

  get isTkt724() {
    if (this.isBrowser) {
      if ((location.href.toLowerCase().indexOf('localhost:4200') > -1))
        return false;
      if ((location.href.toLowerCase().indexOf('beta.tkt724') > -1))
        return false;
      if ((location.href.toLowerCase().indexOf('mahdburaq') > -1))
        return false;
      if ((location.href.toLowerCase().indexOf('tkt724') > -1))
        return true;
    }

    return true;

  }

  isCalendarPersian: boolean = true;

  //بررسی اینکه یک مقدار نال یا خالی است
  isNullOrEmpty(value) {
    return (!value || value.length === 0);
  }

  //دریافت یک مقدار پیشفرض در صورتی که مقدار اول نال یا خالی باشد
  getDefaultIfEmpty(value, newValue) {
    if (this.isNullOrEmpty(value))
      return newValue;
    return value;
  }

  //در صورتی که مقدار اول نال یا خالی باشد مقدار دوم را بر می گرداند
  getOneOfValues(value1, value2) {
    if (this.isNullOrEmpty(value1))
      return value2;
    return value1;
  }


  groupByFn = (item) => item.group;
  groupValueFn = (_: string, children: any[]) => ({
    name: children[0].group ? "شهرهای پر تردد" : "سایر شهرها",
    total: children.length,
  });

  _activeDialogue1 = "";
  get activeDialogue1() {
    return this._activeDialogue1;
  }
  set activeDialogue1(VAL) {
    this._activeDialogue1 = VAL;
    // ;
  }
  //keepBoxActive=null;
  setActiveBox(ad, timeout = 200) {
    if(!this.isBrowser) return;
    //console.log(ad);
    let body = document.getElementsByTagName('body')[0];
    if (this.activeDialogue1 != ad) {
      body.classList.add("disable-scroll");
      setTimeout(() => {
        this.activeDialogue1 = ad;
        if (!!document.querySelector('#' + ad + 'ActiveControlId')) {
          const htmlElement = document.querySelector('#' + ad + 'ActiveControlId') as HTMLElement;
          htmlElement?.focus();

        }
      }, timeout);
      //let activeElement=#(document.activeElement);

      // clearInterval(this.keepBoxActive);
      // this.keepBoxActive =
      // setInterval(()=>{

      //     console.log(#(document.activeElement));
      //     // if(!activeElement.focused)
      //     //     activeElement.focus();
      //   },2000);


    }
    else {
      // console.log(document.activeElement);
    }
    if (!ad) {
      body.classList.remove("disable-scroll");
    }
  }



  // showItemValueForm(item) { //#useless
  //   if(!this.isBrowser) return;
  //   #(".item-value-form").hide();
  //   setTimeout(() => {
  //     #(".item-value-form" + item).show(100);
  //     #(".item-value-form" + item).focus();
  //     if(this.isBrowser && window)
  //                 if (window.innerHeight < 930) {
  //                   // #('.item-value-form' + item).offset().top + #('.item-value-form' + item).height()
  //                   #([document.documentElement, document.body]).animate(
  //                     {
  //                       scrollTop: #(".item-value-form" + item).offset().top,
  //                     },
  //                     1000
  //                   );
  //                 }
  //   }, 50);
  // }


  // scrollEnd() {
  //     let index = this.hotelSearchRequest.rooms.length - 1;
  //     document.getElementById('hotelPassengerCount' + index).scrollIntoView({ behavior: 'smooth', block: 'end' });
  // }

  // decRoom(index: number) {
  //     if (this.hotelSearchRequest.roomCount > 1) {
  //         this.hotelSearchRequest.roomCount--;
  //         this.hotelSearchRequest.adultCount = this.hotelSearchRequest.adultCount - this.hotelSearchRequest.rooms[index].adultCount;
  //         this.hotelSearchRequest.kidCount = this.hotelSearchRequest.kidCount - this.hotelSearchRequest.rooms[index].kidCount;
  //         this.hotelSearchRequest.rooms.splice(index, 1);
  //     } else {
  //         return false;
  //     }
  // }
  // incAdult(index: number) {
  //     if (this.hotelSearchRequest.adultCount > 8) {
  //         this.warning("ظرفیت رزرو حداکثر برای 9 مهمان ممکن است", null, 5000);
  //         return false
  //     } else {
  //         this.hotelSearchRequest.adultCount++;
  //         // this.hotelSearchRequest.rooms[index].adultCount++;
  //     }
  // }
  // decAdult(index: number) {
  //     if (this.hotelSearchRequest.adultCount < 1) {
  //         return false
  //     } else {
  //         this.hotelSearchRequest.adultCount--;
  //         // this.hotelSearchRequest.rooms[index].adultCount--;
  //     }
  // }
  // incKid(index: number) {
  //     if (this.hotelSearchRequest.rooms[index].kidCount > 5) {
  //         this.warning("ظرفیت رزرو هر اتاق حداکثر برای 6 مسافر کودک ممکن است", null, 5000);
  //         return false
  //     } else {
  //         this.hotelSearchRequest.kidCount++;
  //         this.hotelSearchRequest.rooms[index].kidCount++;
  //     }
  // }
  // decKid(index: number) {
  //     if (this.hotelSearchRequest.rooms[index].kidCount < 0) {
  //         return false
  //     } else {
  //         this.hotelSearchRequest.kidCount--;
  //         this.hotelSearchRequest.rooms[index].kidCount--;
  //     }
  // }

  // chkCountPeopleHotel(): boolean {
  //     let c = this.hotelSearchRequest.adultCount + this.hotelSearchRequest.kidCount;
  //     if (c < 10) {
  //         return true;
  //     } else {
  //         this.warning("تعداد مسافران  بیشتر از 10 نفر نمی تواند باشد.", null, 2000);
  //         return false
  //     }
  // }

  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalPassengers: number = 0;
  totalPages: number = 0;

  debounce(func, wait, immediate?) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  }

  hasMoreData(currentPage, itemsPerPage, totalOrders) {
    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    return totalOrders == 0 || startIndex < totalOrders;
  }

  initializeActivateLazyLoad(diveName: string, tryLoad: any) {
    if (!this.isBrowser)
      return;
    let thisPointer = this;
    var returnedFunction = this.debounce(function () {
      let el = document.getElementById(diveName);
      if (el) {
        let elBottom = el.offsetTop + el.offsetHeight;
        let scrollBottom = window.pageYOffset + window.innerHeight;

        if ((scrollBottom >= elBottom) && thisPointer.hasMoreData(thisPointer.currentPage, thisPointer.itemsPerPage, thisPointer.totalPassengers)) {
          tryLoad();
        }
      }
    }, 1000);

    window.addEventListener('scroll', returnedFunction);
  }





  addremoveClass(t, clas, timout = 1000) {
    t.classList.add(clas)
    setTimeout(() => {
      t.classList.remove(clas);
    }, timout);
  }
  // shareURL() {//#useless
  //   if(!this.isBrowser) return;
  //   #("#social-modal").addClass('active');
  //   #("#copy_share").attr('data-copy', document.URL);
  // }

  // activeShare(input) { //#useless
  //   if(!this.isBrowser) return;
  //   #("#txt_share").attr('placeholder', input === 'mobile' ? '98123456780+' : 'example@gmail.com');
  //   if (input == 'mobile' && this.isBrowser) {
  //     #("#share_email").removeClass('js-active');
  //     #("#share_mobile").addClass('js-active');
  //     #("#share_whatsapp").removeClass('js-active');
  //     #("#share_telegram").removeClass('js-active');
  //   }
  //   if (input == 'email' && this.isBrowser) {
  //     #("#share_email").addClass('js-active');
  //     #("#share_mobile").removeClass('js-active');
  //     #("#share_whatsapp").removeClass('js-active');
  //     #("#share_telegram").removeClass('js-active');
  //     // this.emailstring= "mailto:"+this.urlShare;
  //   }
  //   if (input == 'whatsapp' && this.isBrowser) {
  //     #("#share_email").removeClass('js-active');
  //     #("#share_mobile").removeClass('js-active');
  //     #("#share_whatsapp").addClass('js-active');
  //     #("#share_telegram").removeClass('js-active');
  //     window.open("https://wa.me/?text=" + document.URL, "_blank");
  //   }
  //   if (input == 'telegram' && this.isBrowser) {
  //     #("#share_email").removeClass('js-active');
  //     #("#share_mobile").removeClass('js-active');
  //     #("#share_whatsapp").removeClass('js-active');
  //     #("#share_telegram").addClass('js-active');
  //     window.open("https://telegram.me/share/url?url=" + document.URL, "_blank");
  //   }
  // }
  copyLinkToClipboard() {
    if(!this.isBrowser) return;
    var dummy = document.createElement('input');
    if(this.isBrowser)
    {
      var text = window.location.href;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.success("آدرس صفحه کپی شد.", "", 1500);
    }
  }
  // shareData(input: string) { //#useless
  //   if(!this.isBrowser) return;
  //   // let s = new ShareInput();
  //   // s.title = "Tkt724"
  //   // if (#("#share_email").hasClass('js-active')) {
  //   //     s.email = input;
  //   //     s.url = document.URL;
  //   // }
  //   // if (#("#share_mobile").hasClass('js-active')) {
  //   //     s.mobile = input;
  //   //     s.url = document.URL;
  //   // }
  //   if (this.isAuthenticated) {
  //     // this.publicAppService.flightService.shareLink(s).subscribe(o => {
  //     //     let out = o as any;
  //     //     this.success('ارسال با موفقیت انجام شد', 'اشتراک گذاری ');
  //     //     #("#social-modal").removeClass('active');
  //     // }, (err) => {
  //     //     this.danger('ارسال انجام نشد', 'اشتراک گذاری ',2000);
  //     #("#social-modal").removeClass('active');
  //     // });
  //   } else {
  //     #("#social-modal").removeClass('active');
  //     this.showLoginForm();
  //   }
  // }

}
export function toEnglishNumber(strNum) {
  var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var an = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  var cache = strNum;
  //0946۸07۴۴2
  for (var i = 0; i < 10; i++) {
    var regex_fa = new RegExp(pn[i], 'g');
    var regex_ar = new RegExp(an[i], 'g');
    cache = cache.replace(regex_fa, en[i]);
    cache = cache.replace(regex_ar, en[i]);
  }
  return cache;
}
export const CodeMelliValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null => {
  let code = control.value;
  // console.log(code);
  if (code != undefined && code != null) {
    code = toEnglishNumber(code);
    // console.log(code);
    const L = code.length;
    if (L < 8 || parseInt(code, 10) == 0) {
      return null;
    }
    code = ("0000" + code).substr(L + 4 - 10);
    if (parseInt(code.substr(3, 6), 10) == 0) {
      return null;
    }
    const c = parseInt(code.substr(9, 1), 10);
    let s = 0;
    for (let i = 0; i < 9; i++) {
      s += parseInt(code.substr(i, 1), 10) * (10 - i);
    }
    s = s % 11;
    return (s < 2 && c == s) || (s >= 2 && c == 11 - s)
      ? null
      : { codemelliwrong: { value: control.value } };
  }
  return null;
};

export const CodeMelliValidator1: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null => {
  let code = control.value;
  if (code != undefined && code != null) {
    if (isNaN(code)) {
      return ["error"];
    } else if (code == "") {
      return ["error"];
    } else if (code.length < 10) {
      return ["error"];
    }
    else if (code == '1111111111' ||
          code == '0000000000' ||
          code == '2222222222' ||
          code == '3333333333' ||
          code == '4444444444' ||
          code == '5555555555' ||
          code == '6666666666' ||
          code == '7777777777' ||
          code == '8888888888' ||
          code == '9999999999') {
          return ["error"];
        }
     else {
      var yy = 0;
      var yv = parseInt(code);
      for (let i = 0; i < code.length - 1; i++) {
        yv = code[i] * (code.length - i);
        yy += yv;
      }
      var x = yy % 11;
      let c = parseInt(code.charAt(9));
      if ((x == 0 || x==1) && (x==c)) {
        return null;
      }
      else if((11-x)==c){
        return null;
      }
      else {
        return ["error"];
      }
      yy = 0;
    }
  }
  return ["error"];
};
export function PassExpDateValidator(param: any = {}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
  let code = undefined;
  if (control.value) code = control.value;
  if (code !== undefined && code !== null) {
    if(param?.skipExpire)
      return null;
    let date = new Date();
    let dateF = date.setMonth(date.getMonth() + 6);
    let datee = new Date(dateF);
    //   let dateExp = code.getMonth();
    if (code < datee) {
      return { expDatelow: { value: control.value } };
    }
  }
  return null;
}
};
export const PassportNumberValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null => {
  let code = undefined;
  if (control.value)
    code = control.value;
  if (code !== undefined && code !== null) {
    if (code.length == 0)
      return null;
    code = toEnglishNumber(code);
    let countryPass = control.parent.controls['passportCountryId']?.value ?? 0;
    if (countryPass == 0) {
      let countryPassShort = control.parent.controls['passportCountry']?.value ?? 'IR';
      countryPass = countryPassShort == 'IR' ? 2068 : 110;
    }
    if (countryPass == 2068) {
      //const persianRegex = new RegExp('[a-zA-Z][0-9]{7,8}');
      if (!matchFullString(code, '[a-zA-Z]{1,2}[0-9]{8,9}'))
        return { 'pattern': control.value };
      if (code.length > 10) {
        return { 'maxlength': control.value };
      }
    }
    else {
      if (!matchFullString(code, '[a-zA-Z0-9]{0,15}'))
        return { 'pattern': control.value };
    }
    if (code.length < 2) {
      return { 'minlength': control.value };
    }
  }
  return null;
};

export const BankCardNumberValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null => {

  let code = undefined;
  if (control.value)
    code = control.value;
  if (code !== undefined && code !== null) {
    var L = code.length;
    code = toEnglishNumber(code);
    if (L < 16 || parseInt(code.substr(1, 10), 10) == 0 || parseInt(code.substr(10, 6), 10) == 0)
      return { 'bankCardNumber': control.value };
    var c = parseInt(code.substr(15, 1), 10);
    var s = 0;
    var k, d;
    for (var i = 0; i < 16; i++) {
      k = (i % 2 == 0) ? 2 : 1;
      d = parseInt(code.substr(i, 1), 10) * k;
      s += (d > 9) ? d - 9 : d;
    }

    if (!((s % 10) == 0)) {
      return { 'bankCardNumber': control.value };
    }
  }
  return null;
};

export function AgeValidator(param: any = {}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let val: Date = control.value;

    if (val) {
      const time = val.getTime();
      if (!isNaN(param.min)) {
        if (time > param.min.getTime())
          return { "invalidAge": param.text };
      }
      if (!isNaN(param.max)) {
        if (time < param.max.getTime())
          return { "invalidAge": param.text };
      }
    }
    return null;
  }
}
export function genderParadoxicalValidator(param: any = {}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
        const passenger1 = control.parent;
        let val: Date = passenger1.value.birthDate;
        if (val) {
            const time = val.getTime();
            if (!isNaN(param.gender6)) {
                if (((control.value == GenderType.Male && param.coopeGender == WagonGenderType.Women) || (control.value == GenderType.Female && param.coopeGender == WagonGenderType.Men))&& time < param.gender6.getTime() )
                    return { "genderParadoxical": param.sixagetext };
            }
        }
    }
    if (control.value) {
      //debugger
      if (param.genderParadox) {
        if (((control.value == GenderType.Male && param.coopeGender == WagonGenderType.Women) || (control.value == GenderType.Female && param.coopeGender == WagonGenderType.Men)))
          return { "genderParadoxical": param.sixagetext };
      }

    }
    return null;
  }
}


export const SameNationalCodeValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {

  let val: any[] = control.value;

  val.forEach((passenger1, i) => {
    for (let index = i + 1; index < val.length; index++) {
      const passenger2 = val[index];
      // code=toEnglishNumber(code);
      if (!passenger1.withPassport && !passenger2.withPassport && toEnglishNumber(passenger2.nationalityCode) == toEnglishNumber(passenger1.nationalityCode) && passenger2.nationalityCode != '') {
        control.setErrors({ 'sameNationalCode': passenger1.nationalityCode });
        return { sameNationalCode: passenger1.nationalityCode };
      }
    }
  });
  return null;
}

export function BirthDateValidator(type, dateTicket?): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let sdate = new Date();
    let sdateReturn = new Date();
    let date = new Date();
    let dateReturn = new Date();
    if (dateTicket.selectedRangeDates) {
      date = new Date(dateTicket.selectedRangeDates.startDate);
      sdate = new Date(dateTicket.selectedRangeDates.startDate);
      dateReturn = new Date(dateTicket.selectedRangeDates.endDate);
      sdateReturn = new Date(dateTicket.selectedRangeDates.endDate);
    } else if (dateTicket.selectedDate) {
      date = new Date(dateTicket.selectedDate);
      sdate = new Date(dateTicket.selectedDate);
    }
    if (control.value) {
      switch (type) {
        case 1:
          date.setFullYear(date.getFullYear() - 12);
          dateReturn.setFullYear(dateReturn.getFullYear() - 12);
          if (control.value > date) {
            return { AdultDateLow: true };
          } else if (control.value > dateReturn) {
            return { AdultDateLow: true };
          }
          break;
        case 2:
          date.setFullYear(date.getFullYear() - 2);
          sdate.setFullYear(sdate.getFullYear() - 12);
          dateReturn.setFullYear(dateReturn.getFullYear() - 2);
          sdateReturn.setFullYear(sdateReturn.getFullYear() - 12);
          if (sdate > control.value || control.value > date) {
            return { ChildDateLow: true };
          } else if (sdateReturn > control.value || control.value > dateReturn) {
            return { ChildDateLow: true };
          }
          break;
        case 3:
          date.setDate(date.getDate() - 10);
          sdate.setFullYear(sdate.getFullYear() - 2);
          dateReturn.setDate(dateReturn.getDate() - 10);
          sdateReturn.setFullYear(sdateReturn.getFullYear() - 2);
          if (sdate > control.value || control.value > date) {
            return { InfantDatelow: true };
          } else if (sdateReturn > control.value || control.value > dateReturn) {
            return { InfantDatelow: true };
          }
          break;
        default:
          return null;
      }
    }
    // let code = undefined;
    // if (control.value) {
    //     code = control.value;
    //     let date = new Date();
    //     date.setDate(date.getDate() - 10);
    //     if (code > date) {
    //         return { InfantDatelow: { value: control.value } };
    //     }
    // }
  };
}
export const PersinTextValidators: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  const farsichar = [32, 1662, 1670, 1580, 1581, 1582, 1607, 1593, 1594, 1601, 1602, 1579, 1589, 1590, 1711, 1705, 1605, 1606, 1578, 1575, 1604, 1576, 1740, 1587, 1588, 1608, 1574, 1583, 1584, 1585, 1586, 1591, 1592, 1688, 1570, 1569, 8204];
  let found = false;
  if (control.value && control.value.length) {
    try {
      const str = control.value || '';

      for (let index = 0; index < str.length; index++) {
        const char = str[index].charCodeAt(0);
        if (farsichar.indexOf(char) == -1) {
          found = true;
          return { nofarsi: { value: true } };
        }
      }
    } catch (err) {

    }
  }
  if (found)
    return { nofarsi: { value: true } };
  return null;
}
export const PhoneNmuberValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  let code = undefined;
  if (control.value && control.value.length) {
    code = control.value;
    code = toEnglishNumber(code);
    if (code[0] != '0' && code[0] != '9')
      return { ShouldStartByZero: { value: control.value } };
    if (code[0] == '0') {
      if (code.length != 11)
        return { Maxlength: { value: control.value } };
      if (code.length > 1 && code[1] != '9')
        return { ChertNumber: { value: control.value } };

    }
    if (code[0] == '9' && code.length != 10) {
      return { Maxlength: { value: control.value } };
    }
  }
  return null;
}

export const PhoneNmuberValidator2: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  if (control.value && control.value.length > 0) {
    let code = control.value;
    code = toEnglishNumber(code);
    if (!matchFullString(code, '([\\+][1-9][0-9]{11})|([0]{2}[1-9][0-9]{11})|([0][1-9][0-9]{9})|([1-9][0-9]{9,11})')) {
      return { phoneInvalid: { value: control.value } };
    }
  }
  return null;
}

export const RequiredEnum: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  try {
    if (Number(control.value) <= 0) {
      return { nonZero: true };
    } else {
      return null;
    }
  } catch (err) {

  }
  return null;
}




export function isTimeBefore(timeStr: string): ValidatorFn {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (c.value !== undefined && (isNaN(c.value)) || c.value > timeStr || c.value == timeStr) {
      return {
        'isTimeBefore': true
      }
    }
    return null;
  }
}

export function isTimeAfter(timeStr: string): ValidatorFn {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (c.value !== undefined && (isNaN(c.value)) && (c.value < timeStr || c.value == timeStr)) {
      return {
        'isTimeAfter': true
      }
    }
    return null;
  }
}





// export class TimeValidators extends Validators{
//   static isTimeBefore(timeStr : string): ValidatorFn{
//     return(c: AbstractControl): {[key:string]: boolean} | null => {
//       if(c.value!==undefined && (isNaN(c.value)) || c.value > timeStr || c.value== timeStr){
//         return {
//           'isTimeBefore':true
//         }
//       }
//       return null;
//     }
//   }
//   static isTimeAfter(timeStr : string): ValidatorFn{
//     return(c: AbstractControl): {[key:string]: boolean} | null => {
//       if(c.value!==undefined && (isNaN(c.value)) && (c.value < timeStr || c.value == timeStr)){
//         return {
//           'isTimeAfter':true
//         }
//       }
//       return null;
//     }
//   }
// }
