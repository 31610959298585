import { HotelOrderRoomContract } from './hotel-order-room.class';
import { FacilityRequiredContract } from './facility-required-contract.class';
import { TagContract } from './../tag.contract.class';

export class HotelOrderContract {
  Id: number | null = null;
  PersianCityName: string | null = null;
  EnglishCityName: string | null = null;
  CityId: number | null = null;
  CityName: string | null = null;
  Name: string | null = null;
  EnglishName: string | null = null;
  CheckInDate: Date | null = null;
  CheckOutDate: Date | null = null;
  CheckInTime: string | null = null;
  CheckOutTime: string | null = null;
  CheckInPersianDate: string | null = null;
  CheckOutPersianDate: string | null = null;
  TotalRoomCount: number | null = null;
  NightCount: number | null = null;
  MinPrice: number | null = null;
  TotalPayablePrice: number | null = null;
  StartCount: number | null = null;
  Address: string | null = null;
  Tags: TagContract[] = [];
  BuyerMobile: string | null = null;
  BuyerEmail: string | null = null;
  Description: string | null = null;
  FacilitiesRequired: FacilityRequiredContract[] = [];
  Image: string | null = null;
  Images: string[] = []
  DefaultImage: string | null = null;
  Point: number | null = null;
  CommentCount: number;
  Rooms: HotelOrderRoomContract[] = [];
  HotelInquiryId: number | null = null;
  HotelOrderId: number | null = null;
  HotelBasketId: number | null = null;
  IsOnline: boolean = false;
  HistoryUrl: any[] = [];
  ServiceType: number;
}
