import { Injectable, Injector } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from "@angular/common";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class SetTitleDec {
  metaService: Meta;
  titleService: Title;
  dom: Document;
  defaultRobots = environment.defaultRobots;
  noIndex = 'noindex, nofollow';

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
  constructor(public injector: Injector) {
    this.metaService = injector.get(Meta);
    this.titleService = injector.get(Title);
    this.dom = injector.get(DOCUMENT) as Document;
  }

  setTitleDec({pageTitle = 'سامانه تیکت 724',description = '', canonical = '', robots = ''}) {
    this.titleService.setTitle(pageTitle);
    this.metaService.removeTag(`property="og:title"`);
    this.metaService.removeTag(`name="twitter:title"`);
    this.metaService.addTag({ property: 'og:title', content: pageTitle }, true);
    this.metaService.addTag( { name: 'twitter:title', content: pageTitle },true);
    this.metaService.removeTag(`name="description"`);
    this.metaService.removeTag(`property="og:description"`);
    this.metaService.removeTag(`name="twitter:description"`);
    this.metaService.addTag({ name: 'description', content: description }, true);
    this.metaService.addTag( { property: 'og:description', content: description }, true );
    this.metaService.addTag( { name: 'twitter:description', content: description }, true);
    var canonicalUrl = 'https://tkt724.com/' + canonical;
    if (canonicalUrl.endsWith('/'))
      canonicalUrl = canonicalUrl.substring(0, canonicalUrl.length - 1);
    this.updateCanonicalUrl(canonicalUrl);
    this.metaService.updateTag({ content: canonicalUrl, property: 'og:url' });

    // this.metaService.removeTag(`name="robots"`);
    // if (robots != '') {
    //   this.metaService.addTag({ name: 'robots', content: robots }, true);
    // }
  }
}
