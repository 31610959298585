import { Injector } from '@angular/core';
import { PaxcontentService } from '../../../shared/signalGo/services/paxcontentService';
import { LeadService } from '../../../shared/signalGo/services/leadService';
import { SectionType } from '../../../shared/signalGo/services/ContentManagerGenerationServices.Tkt724.Hub.ContentManager.DataTypes/SectionType';
import { Subject } from 'rxjs';
import { ContentService } from '../../seo.service';
import { Contents } from './defualtContetnts';
import { AppComponentBase } from '../../app-component-base';
import { LeadContract } from 'src/shared/signalGo/services/Tkt724.Sale.Core.Contracts.Common.Lead/LeadContract';
import { ProductServiceType } from 'src/shared/signalGo/services/MahdBuraq.Utility.Helpers/ProductServiceType';
import { ContentResponseContract } from 'src/shared/signalGo/services/Tkt724.Sale.Contracts.Responses/ContentResponseContract';
import { FlightflightdynamiclandingService } from 'src/shared/signalGo/services/flightflightdynamiclandingService';
import { PlacescityService } from 'src/shared/signalGo/services/placescityService';
import { FlightCheapestTicketContract } from 'src/shared/signalGo/services/Tkt724.Sale.Contracts.Common/FlightCheapestTicketContract';
import { AirlineFlightsPlanContract } from 'src/shared/signalGo/services/Tkt724.Sale.Contracts.Common/AirlineFlightsPlanContract';
import { FlightDelayAndCancellationContract } from 'src/shared/signalGo/services/Tkt724.Sale.Contracts.Common/FlightDelayAndCancellationContract';
import { CityFilterRequestContract } from 'src/shared/signalGo/services/Tkt724.Sale.Core.Contracts.Requests.Filters.Places/CityFilterRequestContract';
import { ChartConfiguration, ChartType, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { RegisterCheapestFlightType } from 'src/shared/signalGo/services/MahdBuraq.Services.Crm.Subscriptions.DataTypes/RegisterCheapestFlightType';

export abstract class ContentComponent extends AppComponentBase {
  //  router: Router;
  paxcontentService: PaxcontentService;
  //  titleService:Title;
  //  activatedRoute :ActivatedRoute;
  //  metaService:Meta;
  contentService: ContentService;
  leadService: LeadService;

  //   route: ActivatedRouteSnapshot;
  //     state: RouterStateSnapshot;
  fromTo = '';
  fromtos = [];
  fromCity = '';
  toCity = '';
  activetab = 'flight';
  allContent: any;
  showLeadModal = {
    isTrain: false,
    shouldShow: false
  };
  hotelSearchUrl: string;
  subscribeService: FlightflightdynamiclandingService;
  filterCitys: PlacescityService;
  availableticket: FlightCheapestTicketContract[];
  airlineflightsinweek: AirlineFlightsPlanContract[];
  priceChartCaptionText: string = '';
  delayAndCancellation: FlightDelayAndCancellationContract[];
  airLinesLabels: Array<string> = [];
  delayList: Array<any> = [];
  cancellationList: Array<any> = [];
  flightsListCount: Array<any> = [];
  cancellationListCount: Array<any> = [];
  delayListCount: Array<any> = [];
  priceChartLabels: Array<any> = [];
  priceChartList: Array<any> = [];
  priceChartFullDataList: Array<any> = [];
  activatedRoute: any;
  ModalDialogActiveMobileSize = false;
  iranCities: Array<any> = [];
  AirlineCounter = 0;
  IsAirlineModalShow = false;
  AirlineModalData: AirlineFlightsPlanContract = null;
  originSelectedLevel;
  destinationSelectedLevel;
  optionsSelectedLevel: RegisterCheapestFlightType;
  isRequest: boolean = false;
  originCity: string;
  destinationCity: string;
  delay: boolean = false;
  priceChartGridLineStep: number;
  minDelayInfo: FlightDelayAndCancellationContract;
  minCancellationInfo: FlightDelayAndCancellationContract;

  // platformId:any;
  // @ViewChild(SearchboxComponent, { static: true }) searchboxcom: SearchboxComponent;
  //   @ViewChild('flighttocity', { static: false }) flighttocity: any;
  constructor(
    public injector: Injector //@Optional() @Inject(RESPONSE) private response1: Response
  ) {
    super(injector);
    this.paxcontentService = injector.get(PaxcontentService);

    this.contentService = injector.get(ContentService);
    this.leadService = injector.get(LeadService);

    this.fromTo = this.activatedRoute.snapshot.paramMap['params'].fromto ?? '';
    const contenttype =
      this.activatedRoute.snapshot.data['contentType']?.toString() ?? '';

    if (this.activatedRoute.snapshot.data['activetab']) {
      // console.log('activetab',this.activatedRoute.snapshot.data['activetab'])
      this.activetab = this.activatedRoute.snapshot.data['activetab'];
    }
    if (this.fromTo != '') {
      this.fromtos = this.fromTo.split('-to-');
      if (this.fromtos.length == 2) {
        this.fromCity = this.mapDestinations(this.fromtos[0]);
        this.toCity = this.mapDestinations(this.fromtos[1]);
      }
      {
        this.contentService
          .getContents(contenttype + this.fromTo)
          .then((data) => {
            this.allContent = data.content;
            if (contenttype == 'flight/') {
              this.fillFlightData(data.content);
            }
            this.allContent.name = contenttype + this.fromTo;
            // console.log(this.activatedRoute.snapshot.url.join('/'));
            if (contenttype + this.fromTo != data.content.key || this.activatedRoute.snapshot.url.join('/').indexOf(contenttype + this.fromTo) == -1) {
              //console.log('redirect to canonical url',data.key);
              this.router.navigate(['/' + data.content.key]);
            }
            if (this.isBrowser && window.location.href.lastIndexOf('/') == window.location.href.length - 1) {
              this.router.navigate(['/' + data.content.key]);
            }
            //this.setTitleDec(data.pageTitle,data.description,data.canonical,data.robots??this.defaultRobots);
            this.setTitleDec(
              data.content.pageTitle,
              data.content.description,
              data.content.canonical,
              data.content.robots ?? this.defaultRobots
            );
          });
      }
    } else {
      if (this.activatedRoute.snapshot.data['pagename']) {
        const data = Contents[this.activatedRoute.snapshot.data['pagename']];

        if (data) {
          this.allContent = data;
          this.allContent.name = this.activatedRoute.snapshot.data['pagename'];
          this.setTitleDec(
            data.pageTitle,
            data.description,
            contenttype + data.canonical,
            data.robots ?? this.defaultRobots
          );
        }
      }
    }
    if (this.isBrowser && contenttype == 'train/') {
      this.showLeadModal.isTrain = true;
    }
  }
  response: any;
  // get isBrowser() {
  //     return isPlatformBrowser(this.platformId);
  // }

  mappedDestinations = [
    { f: 'ahwaz', to: 'ahvaz' },
    { f: 'bandarabbas', to: 'bandar abbas' },
    // {f:'ahvaz',to:'awz'},
    // {f:'ahvaz',to:'awz'},
  ];
  mapDestinations(cityName) {
    const cl = cityName.toLowerCase();
    var find = this.mappedDestinations.find((o) => o.f == cl);
    // debugger
    if (find) {
      return find.to;
    }
    return cityName;
  }
  isinit = false;
  loadContentSubject = new Subject<any>();
  mapdata(section) {
    //   console.log(`${section.map.latitude};${section.map.longitude};${section.map.zoom}`);
    return `${section.map.latitude};${section.map.longitude};${section.map.zoom}`;
  }
  get popularTarget() {
    return Contents['popularTarget'];
  }
  contents(contentname) {
    return Contents[contentname];
  }
  firstHalf(arr) {
    var newarr = [];
    var len = arr.length;
    if (len <= 1) {
      return arr;
    }
    for (let index = 0; index < Math.ceil(len / 2); index++) {
      newarr.push(arr[index]);
    }
    return newarr;
  }
  secondHalf(arr) {
    var newarr2 = [];
    var len = arr.length;
    if (len <= 1) {
      return [];
    }
    for (let index = Math.ceil(len / 2); index < len; index++) {
      newarr2.push(arr[index]);
    }
    return newarr2;
  }
  init_map(section, si) {
    setTimeout(() => {
      //  init_map_target(section.map.latitude,section.map.longitude, section.map.zoom,'');
    }, 100);
  }
  // None = 0,
  // Other = 1,
  // All = 2,
  ParagraphType = SectionType.Paragraph;
  FrequentlyAskedQuestionsType = SectionType.FrequentlyAskedQuestions;
  MapType = SectionType.Map;
  WeatherType = SectionType.Weather;
  GalleryType = SectionType.Gallery;
  ImageParagraphType = SectionType.ImageParagraph;
  DestinationsType = SectionType.Destinations;
  HorizontalImageType = SectionType.HorizontalImage;
  CheapestFlightTicketsType = SectionType.CheapestFlightTickets;
  SubscribeType = SectionType.Subscribe;
  AdvertisingType = SectionType.Advertising;
  AirlineWeeklyFlightsType = SectionType.AirlineWeeklyFlights;
  FlightDelayAndCancellationChartType =
    SectionType.FlightDelayAndCancellationChart;

  // Attachment = 8,
  // KeyValue = 9,
  galleries = [];
  lastInformationLink(p) {
    switch (p) {
      case 'train':
        return 'https://tkt724.com/umag/Information/Train-Rules-and-Limits';
      case 'flight':
        return 'https://tkt724.com/umag/Information/Travel-Limits';
      case 'hotel':
        return 'https://tkt724.com/umag/Information/Hotel-Rules-and-Limits';
    }
    return 'https://tkt724.com/umag/Information/Travel-Limits';
  }
  get baner333() {
    return (this.allContent?.name ?? '').toLowerCase() != 'train/fadak';
  }
  get banerFadak() {
    return (this.allContent?.name ?? '').toLowerCase() == 'train/fadak';
  }
  addNewLead() {
    setTimeout(() => {
      this.leadService
        .addNewLead({
          mobile: this.userName,
          productServiceType: ProductServiceType.Train,
          campaignKey: 'train-khabarname',
        } as LeadContract)
        .subscribe((o) => {
          if (o.success) {
            this.success(
              'عضویت شما در خبرنامه قطار تیکت 724 با موفقیت انجام شد.',
              '',
              5000
            );
          }else if(o.failedReason == 4) {
            this.warning('شما قبلاْ عضو خبرنامه تیکت 724 شده‌اید', '', 5000);
          }
          this.showLeadModal.shouldShow = false;
        });
    }, 2000);
  }
  sendMeNotif() {
    this.runWithLogin(() => this.addNewLead());
  }

  // ============= Function only Flight Dynamic Landing  ============= \\
  fillFlightData(input: ContentResponseContract) { }
  // ============= Chart Delaycancellation  ============= \\
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: '#EAEDEF',
        },
        ticks: {
          font: {
            weight: '700',
            family: 'Yekan',
            size: 14,
          },
          color: '#2B475A',
        },
      },
      y: {
        min: 0,
        max: 200,
        grid: {
          tickColor: '#fff',
          borderColor: '#fff',
          color: '#EAEDEF',
        },
        ticks: {
          font: {
            weight: '700',
            family: 'Yekan',
            size: 14,
          },
          color: '#A4AEB5',
          stepSize: 25,
          // callback: function (value, index, values) {
          //   return value + '%';
          // },
        },
      },
    },
    plugins: {
      legend: {
        align: 'start',
        rtl: true,
        display: true,
        labels: {
          usePointStyle: true,
          color: '#2B475A',
          boxWidth: 20,
          boxHeight: 20,
          font: {
            weight: '700',
            family: 'Yekan',
            size: 14,
          },
        },
      },
      title: {
        display: true,
        color: '#2B475A',
        text: 'سه ماه قبل',
        font: {
          weight: '500',
          family: 'Yekan',
          size: 14,
        },
      },
      tooltip: {
        displayColors: false,
        enabled: true,
        intersect: false,
        boxPadding: 10,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#EAEDEF',
        cornerRadius: 10,
        titleAlign: 'right',
        titleColor: '#2B475A',
        titleFont: {
          weight: '700',
          family: 'Yekan',
          size: 14,
        },
        bodyAlign: 'right',
        bodyColor: '#2B475A',
        bodyFont: {
          weight: '700',
          family: 'Yekan',
          size: 14,
        },
        callbacks: {
          beforeLabel: function (context) {
            return (
              'کل پرواز ها : ' +
              (context.dataset as any).flightCount[context.dataIndex]
            );
          },
          label: function (context) {
            return (
              'پرواز های تاخیردار : ' +
              (context.dataset as any).tooltipDataDelay[context.dataIndex]
            );
          },
          afterLabel: function (context) {
            return (
              'پرواز ها کنسلی : ' +
              (context.dataset as any).tooltipDataCancellation[
              context.dataIndex
              ]
            );
          },
        },
      },
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'top',
        textAlign: 'center',
        color: '#2B475A',
        font: {
          family: 'Yekan',
          size: 14,
        },
        formatter: function (value) {
          return value + '%';
        },
      },
    },
    animation: {
      onComplete: () => {
        this.delay = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delay) {
          delay = context.dataIndex * 500 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];
  public barChartData: ChartData<'bar'>;
  // ============= Chart Delaycancellation  ============= \\
  // ============= Modal Airline  ============= \\
  closeModal() {
    this.IsAirlineModalShow = false;
  }
  outSideModal() {
    if (this.AirlineCounter > 0) {
      this.IsAirlineModalShow = false;
      this.AirlineModalData = null;
    }
    this.AirlineCounter = this.AirlineCounter + 1;
  }
  AirlineModal(airlinflightweek: AirlineFlightsPlanContract) {
    if (this.IsAirlineModalShow == false) {
      this.AirlineCounter = 0;
      this.IsAirlineModalShow = true;
      this.AirlineModalData = airlinflightweek;
    }
  }
  // ============= Modal Airline  ============= \\
  // ============= Subscribe Service ============= \\
  subscribe() {
    this.runWithLogin(() => {
      this.subscribeCity();
    });
  }
  subscribeCity() {
    this.isRequest = true;
    this.subscribeService
      .registerCheapestFlight({
        originId: this.originSelectedLevel,
        destinationId: this.destinationSelectedLevel,
        departureDate: new Date(),
        type: this.optionsSelectedLevel,
      })
      .subscribe((res) => {
        if (res.success) {
          this.success(
            'اطلاع رسانی بهترین پیشنهادها برای این مسیر برای شما همسفر تیکت 724 فعال شد.',
            'خبرنامه',
            5000
          );
          this.isRequest = false;
        } else {
          let error = res.error.details;
          this.warning(error, 'خبرنامه', 5000);
          this.isRequest = false;
        }
      });
  }
  filterCitye(): void {
    // Iran Code: 2068
    const request = new CityFilterRequestContract();
    request.countryId = 2068;
    request.sortColumnName = 'Name';
    this.filterCitys.filterCities(request).subscribe((result) => {
      if (result.success) {
        this.iranCities = result.result.data;
        this.originSelectedLevel = result.result.data.find(
          (Items) => Items.code?.toLowerCase() == this.fromtos[0]?.toLowerCase()
        )?.code;
        console.log(this.originSelectedLevel)
        this.destinationSelectedLevel = result.result.data.find(
          (Items) => Items.code?.toLowerCase() == this.fromtos[1]?.toLowerCase()
        )?.code;
      }
    });
  }
  // ============= Function only Flight Dynamic Landing  ============= \\
}
