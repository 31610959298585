import {
  AfterViewInit,
  ApplicationRef,
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import {
  SwUpdate,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import {
  isPlatformBrowser,
  Location,
  LocationStrategy,
  PopStateEvent,
} from '@angular/common';
import { NavigationService } from './core/services/app/navigation.service';

// Service
import { CacheService } from 'src/app/core/services/app/clearCache/clear-cache.service';
import { environment } from '../environments/environment';
import { CheckForUpdateService } from './check-for-update.service';
import { HandleUnrecoverableStateService } from './handle-unrecoverable-state.service';
import { LogUpdateService } from './log-update.service';
import { PromptUpdateService } from './prompt-update.service';
import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  classList: any;

  loading = true;
  isOnline: boolean = true;
  modalPwaEvent: any;
  modalPwaPlatform: string | undefined;
  lastname: string;
  timeout: string | number | NodeJS.Timeout;
  loader: boolean;

  get isInside() {
    if (this.router.url.toLowerCase().indexOf('/searchflight/') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/flight/landing/') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/searchbus/') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/searchtrain/') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/searchhotel') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/detail') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/reserve') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/confirm') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/disagreement') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/payment') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/paymentedit') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('purchase/payment-response') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('profile') > -1) return true;
  }
  get hasMenu() {
    if (this.router.url.toLowerCase().indexOf('/searchflight/') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/flight/landing/') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/searchbus/') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/searchtrain/') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/searchhotel') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/detail') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/reserve') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/confirm') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/disagreement') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/payment') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/hotel/paymentedit') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('purchase/payment-response') > -1)
      return true;
    if (this.router.url.toLowerCase().indexOf('/contactus') > -1) return true;
    // if (this.router.url.toLowerCase().indexOf('/hotel') > -1)
    //   return true;
    if (this.router.url.toLowerCase().indexOf('/aboutus') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/app') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/arbaeen') > -1) return true;
    if (this.router.url.toLowerCase().indexOf('/404') > -1) return true;
    return false;
  }
  get covidLink() {
    if (
      this.router.url.toLowerCase().indexOf('/searchhotel/') > -1 ||
      this.router.url.toLowerCase().indexOf('/hotel') == 0
    )
      return 'https://tkt724.com/flight/refund-ticket';
    else if (
      this.router.url.toLowerCase().indexOf('/searchtrain/') > -1 ||
      this.router.url.toLowerCase().indexOf('/train') == 0
    )
      return 'https://tkt724.com/flight/refund-ticket'; //'https://umag.tkt724.com/Information/Train-Rules-and-Limits';

    return 'https://tkt724.com/flight/refund-ticket';
  }
  get rawHtml() {
    //  if(
    //       this.router.url.toLowerCase().indexOf('/searchtrain/') > -1 ||
    //       this.router.url.toLowerCase().indexOf('/train') == 0
    //     )
    //     return 'trainpishforoosh';

    return '';
  }
  get showCovid() {
    // if (
    //   this.router.url.toLowerCase().indexOf('/searchtrain/') > -1 ||
    //   this.router.url.toLowerCase().indexOf('/train') == 0
    // )
    //   return true;
    if (this.router.url.toLowerCase().indexOf('/campaigns/') > -1) return false;
    if (this.router.url.toLowerCase().indexOf('/flight/landing/') > -1)
      return false;
    if (this.router.url.toLowerCase().indexOf('/app') > -1) return false;
    if (this.router.url.toLowerCase().indexOf('/arbaeen') > -1) return false;
    return true;
  }
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  backclickSubscriber = null;
  platformId: any;
  constructor(
    injector: Injector,
    private router: Router,
    private location: Location,
    private location2: LocationStrategy,
    private navigationService: NavigationService,
    private platform: Platform,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    // private cacheService: CacheService // # service worker services
  ) // # service worker services
  {
    this.platformId = injector.get(PLATFORM_ID);
    this.isOnline = true;
    //, private gtmService: GoogleTagManagerService
    if (environment.production && isPlatformBrowser(this.platformId)) {
      const checkForUpdate: CheckForUpdateService = inject(
        CheckForUpdateService
      );
      const handleUnrecoverableStateService: HandleUnrecoverableStateService =
        inject(HandleUnrecoverableStateService);
      const logUpdateService: LogUpdateService = inject(LogUpdateService);
      const promptUpdateService: PromptUpdateService =
        inject(PromptUpdateService);

      this.updateCheck();

      history.pushState(null, null, window.location.href);
      this.backclickSubscriber = this.location2.onPopState(() => {
        history.pushState(null, null, window.location.href);
        this.navigationService.backClicked.next();
      });
    }

    // Loading DOM
    this.router.events.subscribe((event: any) => {
      this.navigationInterceptor(event);
    });
    // Loading DOM

    // navEndEvents.subscribe((event: NavigationEnd) => {
    //   this.gtmService.pushTag({
    //     event: 'pageview',
    //     data: {
    //       page_name: event.urlAfterRedirects
    //     }
    //   });
    // });
  }

  // Loading DOM Envet Function
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
  // Loading DOM Envet Function Sample Two Add Timout

  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     this.loading = true;
  //   }
  //   if (event instanceof NavigationEnd) {
  //     // Hide loading indicator
  //     this.timeout = setTimeout(() => {
  //       clearTimeout(this.timeout);
  //       this.loading = false;
  //     }, 1000);
  //   }
  //   // if (event instanceof NavigationCancel) {
  //   //   this.loading = false;
  //   // }
  //   // if (event instanceof NavigationError) {
  //   //    console.log(event);
  //   //   this.loading = false;
  //   // }
  // }

  // Loading DOM Envet Function

  ngOnDestroy(): void {
    if (this.backclickSubscriber) this.backclickSubscriber.unsubscribe();
  }

  ngOnInit() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    if (isPlatformBrowser(this.platformId) && window) {
      this.router.events.subscribe((ev: any) => {
        if (ev instanceof NavigationStart) {
          if (ev.url != this.lastPoppedUrl)
            this.yScrollStack.push(window.scrollY);
        } else if (ev instanceof NavigationEnd) {
          gtag('config', 'GTM-WKK282R', { page_path: ev.urlAfterRedirects });
          if (ev.url == this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined;
            window.scrollTo(0, this.yScrollStack.pop());
          } else window.scrollTo(0, 0);
        }
      });
      // PWA
      this.updateOnlineStatus();

      window.addEventListener('online', this.updateOnlineStatus.bind(this));
      window.addEventListener('offline', this.updateOnlineStatus.bind(this));
      this.loadModalPwa();
    }
    // PWA
  }

  updateCheck(): void {
    this.swUpdate
      .checkForUpdate()
      .then(() => console.log('resolved'))
      .catch((err) => console.log(`rejected: ${err.message}`));
  }

  // PWA
  removeNetworkModal() {
    this.isOnline = true;
  }
  private updateOnlineStatus(): void {
    if (isPlatformBrowser(this.platformId)) {
      //this.isOnline = window.navigator.onLine;
      this.isOnline = true;
      //console.info(`isOnline=[${this.isOnline}]`);
    }
  }

  private loadModalPwa(): void {
    if (localStorage.getItem('Pwa')) {
      console.log(`SetKey`);
    } else {
      if (this.platform.ANDROID) {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.modalPwaEvent = event;
          this.modalPwaPlatform = 'ANDROID';
        });
      }
      if (this.platform.IOS && this.platform.SAFARI) {
        const isInStandaloneMode =
          'standalone' in window.navigator &&
          (<any>window.navigator)['standalone'];
        if (!isInStandaloneMode) {
          this.modalPwaPlatform = 'IOS';
        }
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
    localStorage.setItem('Pwa', 'Set');
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
    localStorage.setItem('Pwa', 'Set');
  }

  // PWA
  ngAfterViewInit() {
    //window.scroll(0, 0);
    // init_map_target(36.287478, 59.615919);
  }

  // Disable Scroll For Available Ticket Datail (Mobile)
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        let body = document.getElementsByTagName('body')[0];
        let modal = document.getElementsByClassName(
          'available-columns_datail active'
        )[0];
        if (modal) {
          if (window.innerWidth < 700) {
            body.classList.add('disable-scroll');
          } else {
            body.classList.remove('disable-scroll');
          }
        } else {
          body.classList.remove('disable-scroll');
        }
      }, 500);
    }
    // Disable Scroll For Available Ticket Datail (Mobile)
  }
}
